<template>
  <message-bubble :isMine="isMine" :message="message">
    <template v-slot:default="slotProps">
      <div class="file-element-wrapper">
        <div class="file_box">
          <span class="file_detail">
            <span class="file_name">{{ fileName }}</span>
            <span class="file_size">{{ size ? size : "未知大小" }}</span>
          </span>
          <span class="file_icon" :class="[extname]">
            <template v-if="extname == 'doc' || extname == 'docx'">
              <svg-icon icon-class="word"></svg-icon>
            </template>
            <template v-else-if="extname == 'ppt' || extname == 'pptx'">
              <svg-icon icon-class="ppt"></svg-icon>
            </template>
            <template v-else-if="extname == 'pdf'">
              <svg-icon icon-class="pdf"></svg-icon>
            </template>
            <template v-else-if="extname == 'xlsx' || extname == 'xls'">
              <svg-icon icon-class="excel"></svg-icon>
            </template>
            <template v-else-if="extname == 'txt'">
              <svg-icon icon-class="txt"></svg-icon>
            </template>
            <template
              v-else-if="
                [
                  'zip',
                  'rar',
                  'rar4',
                  'jar',
                  '7z',
                  'tar',
                  'cab',
                  'iso',
                  'uue',
                ].includes(extname)
              "
            >
              <svg-icon icon-class="zip" class-name="zip"></svg-icon>
            </template>
            <template
              v-else-if="
                [
                  'mp4',
                  'avi',
                  'wmv',
                  'mpg',
                  'mpeg',
                  'mov',
                  'ram',
                  'rm',
                  'swf',
                  'flv',
                ].includes(extname)
              "
            >
              <svg-icon icon-class="video"></svg-icon>
            </template>
            <template
              v-else-if="
                [
                  'mp3',
                  'wav',
                  'mpeg',
                  'cda',
                  'mid',
                  'wma',
                  'aif',
                  'aiff',
                  'ape',
                  'vqf',
                  'ra',
                ].includes(extname)
              "
            >
              <svg-icon icon-class="mp3"></svg-icon>
            </template>
            <template v-else>
              <svg-icon icon-class="unknown"></svg-icon>
            </template>
          </span>
        </div>
        <el-progress
          v-if="slotProps.downData.status == 1"
          :percentage="slotProps.downData.process"
          :color="(percentage) => (percentage === 100 ? '#67c23a' : '#409eff')"
        />
      </div>
    </template>
  </message-bubble>
</template>

<script>
import MessageBubble from "../message-bubble";
import { Progress } from "element-ui";
export default {
  name: "FileElement",
  props: {
    customData: {
      type: Object,
      required: true,
    },
    message: {
      type: Object,
      required: true,
    },
    isMine: {
      type: Boolean,
    },
  },
  components: {
    MessageBubble,
    ElProgress: Progress,
  },
  computed: {
    fileName() {
      return this.customData.fileName;
    },
    extname() {
      let index = this.customData.fileName.lastIndexOf(".");
      if (index != -1) {
        return this.customData.fileName.substring(index + 1).toLowerCase();
      }
      return "";
    },
    fileUrl() {
      return this.customData.fileUrl;
    },
    size() {
      if(!this.customData.fileSize){
        return '未知'
      }
      const size = this.customData.fileSize;
      if (size > 1024) {
        if (size / 1024 > 1024) {
          return `${this.toFixed(size / 1024 / 1024)} Mb`;
        }
        return `${this.toFixed(size / 1024)} Kb`;
      }
      return `${this.toFixed(size)}B`;
    },
  },
  methods: {
    toFixed(number, precision = 2) {
      return number.toFixed(precision);
    },
    downloadFile() {
      // 浏览器支持fetch则用blob下载，避免浏览器点击a标签，跳转到新页面预览的行为
      if (window.fetch) {
        fetch(this.fileUrl)
          .then((res) => res.blob())
          .then((blob) => {
            let a = document.createElement("a");
            let url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = this.fileName;
            a.click();
          });
      } else {
        let a = document.createElement("a");
        a.href = this.fileUrl;
        a.target = "_blank";
        a.download = this.filename;
        a.click();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.file-element-wrapper {
  cursor: pointer;
  padding: 10px;
  border: 1px solid #eeeeee;
  background-color: #ffffff;
}
.file_box {
  user-select: none;
  width: 160px;
  display: flex;
  align-items: center;
  .file_icon {
    height: 50px;
    font-size: 36px;
    .zip {
      font-size: 44px;
      margin-right: -8px;
    }
    .unknown {
      font-size: 44px;
      margin-right: -6px;
    }
    .ppt {
      font-size: 38px;
      margin-right: -2px;
    }
  }
  .file_detail {
    flex: 1;
    margin-left: 10px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    .file_name {
      @include ellipsis;
    }
    .file_size {
      margin-top: 6px;
      font-size: 12px;
      color: #aaaaaa;
    }
  }
}
</style>
