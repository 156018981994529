<template>
  <div
    id="message-send-box-wrapper"
    :style="focus ? { backgroundColor: 'white' } : {}"
    @drop="dropHandler"
  >
    <div class="send-header-bar">
      <el-popover placement="top" width="400" trigger="click">
        <div class="emojis">
          <div
            v-for="item in emojiName"
            class="emoji"
            :key="item"
            @click="chooseEmoji(item)"
          >
            <img
              :src="emojiUrl + emojiMap[item]"
              style="width: 30px; height: 30px"
            />
          </div>
        </div>
        <!-- <i class="iconfont icon-biaoqing" slot="reference" title="发表情"></i> -->
        <span slot="reference" title="发表情" class="chat_tool_btn">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-xiaolian" />
          </svg>
        </span>
      </el-popover>
      <span title="文件上传"
          class="chat_tool_btn"
          @click="handleSendFileClick">
        <svg
          class="icon"
          aria-hidden="true"
        >
          <use xlink:href="#icon-wenjian" />
        </svg>
      </span>
    </div>
    <div class="bottom">
      <textarea
        placeholder="请输入想说的内容"
        ref="text-input"
        resize="false"
        v-model="messageContent"
        class="text-input"
        @focus="focus = true"
        @blur="focus = false"
        @input="inputChange"
        @keydown.enter.exact.prevent="handleEnter"
        @keyup.ctrl.enter.prevent.exact="handleLine"
        @keydown.up.stop="handleUp"
        @keydown.down.stop="handleDown"
        @contextmenu="sendInputContextClick"
      >
      </textarea>
      <div class="bottom-btn">
        <el-tooltip
          class="item"
          effect="dark"
          content="按Enter发送消息，Ctrl+Enter换行"
          placement="left-start"
        >
          <span class="btn-send" @click="sendTextMessage">发送</span>
        </el-tooltip>
      </div>
    </div>
    <input
      type="file"
      id="filePicker"
      ref="filePicker"
      @change="fileSelectChange"
      style="display: none"
    />
    <div
      class="calling-member-list"
      v-if="
        currentConversationType === TIM.TYPES.CONV_GROUP && showCallingMember
      "
    >
      <calling-member-list
        @getList="getList"
        :type="listTpye"
      ></calling-member-list>
      <div class="calling-list-btn">
        <span class="calling-btn" @click="cancelCalling">取消</span>
        <span class="calling-btn" @click="callingHandler">确定</span>
      </div>
    </div>
    <student-new-class-require></student-new-class-require>
    <refund-order-preview></refund-order-preview>
    <learn-report-preview></learn-report-preview>
    <learn-report-detail></learn-report-detail>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from "vuex";
import callingMemberList from "./trtc-calling/group-member-list";
import studentNewClassRequire from "../dialog/student-new-class-require";
import refundOrderPreview from "../dialog/refund-order-preview";
import learnReportPreview from '../dialog/learn-report-preview.vue'
import learnReportDetail from '../dialog/learn-report-detail.vue'
import {
  Form,
  FormItem,
  Input,
  Dialog,
  Popover,
  Tooltip,
  Rate,
} from "element-ui";
import { emojiMap, emojiName, emojiUrl } from "@/utils/emojiMap";
import { getBytesLength } from "@/utils/tool";
export default {
  name: "message-send-box",
  props: {
    dialogChat: {
      default: false,
    }
  },
  components: {
    callingMemberList: callingMemberList,
    ElInput: Input,
    ElForm: Form,
    ElFormItem: FormItem,
    ElDialog: Dialog,
    ElPopover: Popover,
    ElTooltip: Tooltip,
    ElRate: Rate,
    studentNewClassRequire,
    refundOrderPreview,
    learnReportPreview,
    learnReportDetail,
  },
  data() {
    return {
      callingList: [],
      groupAtList: [],
      listTpye: "",
      callingType: "",
      groupAt: false,
      showCallingMember: false,
      colors: ["#99A9BF", "#F7BA2A", "#FF9900"],
      messageContent: "",
      form: {
        data: "",
        description: "",
        extension: "",
      },
      rate: 5, // 评分
      suggestion: "", // 建议
      file: "",
      emojiMap: emojiMap,
      emojiName: emojiName,
      emojiUrl: emojiUrl,
      showAtGroupMember: false,
      atUserID: "",
      focus: false,
      cursorIndex: null,
    };
  },
  inject: ["timChat"],
  computed: {
    ...mapGetters("chatInfo", ["toAccount", "currentConversationType"]),
    ...mapState("chatInfo", {
      memberList: (state) => state.currentMemberList,
      userID: (state) => state.userID,
      groupProfile: (state) => state.currentConversation.groupProfile,
    }),
  },
  created() {
    this.$bus.$on("new-class-need", this.handleNewClassNeed);
    this.$bus.$on("refund-order", this.handleRefundOrder)
  },
  mounted() {
    this.$refs["text-input"].addEventListener("paste", this.handlePaste);
    this.$bus.$on("reEditMessage", this.reEditMessage);
  },
  beforeDestroy() {
    console.log("message-send-box beforeDestroy");
    this.$refs["text-input"].removeEventListener("paste", this.handlePaste);
    this.$bus.$off("reEditMessage", this.reEditMessage);
    this.$bus.$off("new-class-need", this.handleNewClassNeed);
    this.$bus.$off('refund-order', this.handleRefundOrder)
  },
  methods: {
    ...mapMutations("chatInfo", ["pushCurrentMessageList"]),
    sendInputContextClick(e) {},
    handleNewClassNeed(data) {
      const message = this.timChat.tim.createCustomMessage({
        to: this.toAccount,
        conversationType: this.currentConversationType,
        payload: {
          data: "new-class-need",
          description: JSON.stringify(data),
          extension: "",
        },
      });
      this.pushCurrentMessageList(message);
      console.log("message: ", message);
      this.timChat.tim
        .sendMessage(message)
        .then(() => {
          this.msgSuccess("新课需求发送成功！");
        })
        .catch((error) => {
          this.msgError(error.message);
        });
    },
    handleRefundOrder(data) {
      const message = this.timChat.tim.createCustomMessage({
        to: this.toAccount,
        conversationType: this.currentConversationType,
        payload: {
          data: "refund-order",
          description: JSON.stringify(data),
          extension: "",
        },
      });
      this.pushCurrentMessageList(message);
      console.log("message: ", message);
      this.timChat.tim
        .sendMessage(message)
        .then(() => {
          this.msgSuccess("退费申请发送成功！");
        })
        .catch((error) => {
          this.msgError(error.message);
        });
    },
    sendFileFromPrepareData(fileName, fileUrl) {
      let index = fileName.lastIndexOf(".");
      let extname = fileName.substring(index + 1);
      const message = this.timChat.tim.createCustomMessage({
        to: this.toAccount,
        conversationType: this.currentConversationType,
        payload: {
          data: "prepare-data",
          description: JSON.stringify({
            fileName,
            fileUrl,
            fileType: extname,
          }),
          extension: "",
        },
      });
      this.pushCurrentMessageList(message);
      console.log("message: ", message);
      this.timChat.tim
        .sendMessage(message)
        .then(() => {
          this.msgSuccess(`${fileName}发送成功！`);
        })
        .catch((error) => {
          this.msgError(error.message);
        });
    },
    handlePaste() {},
    getList(value) {
      this.callingList = value.map((item) => {
        let obj = JSON.parse(item);
        return obj.userID;
      });
      this.groupAtList = value.map((item) => {
        let data = JSON.parse(item);
        return data.nick;
      });
    },
    cancelCalling() {
      this.showCallingMember = false;
    },
    callingHandler() {
      if (this.callingList.length < 1) {
        this.msgWarn("请选择成员");
        return;
      }
      if (this.listTpye === "groupAt") {
        this.groupAtList.forEach((item, index) => {
          if (index === 0) {
            this.messageContent += `${item} `;
          } else {
            this.messageContent += `@${item} `;
          }
        });
        this.showCallingMember = false;
        this.$refs["text-input"].focus();
        return;
      }
      if (this.listTpye === "calling") {
        let callingData = {
          memberList: this.callingList,
          type: this.TIM.TYPES.CONV_GROUP,
        };
        this.$store.commit("setCallingList", callingData);
        if (this.callingType === "video") {
          this.$bus.$emit("video-call");
        }
        if (this.callingType === "audio") {
          this.$bus.$emit("audio-call");
        }
        this.showCallingMember = false;
      }
    },
    trtcCalling(type) {
      this.listTpye = "calling";
      if (type === "video") {
        this.callingType = "video";
      }
      if (type === "audio") {
        this.callingType = "audio";
      }
      // 呼叫方设置
      if (this.currentConversationType === "C2C") {
        let member = [this.toAccount];
        let callingData = {
          memberList: member,
          type: "C2C",
        };
        this.$store.commit("setCallingList", callingData);
        this.$bus.$emit(`${type}-call`);
        return;
      }
      if (this.currentConversationType === this.TIM.TYPES.CONV_GROUP) {
        this.showCallingMember = true;
      }
      // this.$store.commit('pushCurrentMessageList', true)
    },
    handleEmojiShow() {
      this.emojiShow = true;
      this.bigEmojiShow = false;
    },
    handleBigEmojiShow(index) {
      let elm = document.getElementById("bigEmojiBox");
      elm && (elm.scrollTop = 0);
      this.curItemIndex = index;
      this.curBigEmojiItemList = this.bigEmojiList[index].list;
      this.emojiShow = false;
      this.bigEmojiShow = true;
    },
    chooseBigEmoji(item) {
      this.popoverVisible = false;
      let message = this.timChat.tim.createFaceMessage({
        to: this.toAccount,
        conversationType: this.currentConversationType,
        payload: {
          index: this.curItemIndex + 1,
          data: `${item}@2x`,
        },
      });

      this.pushCurrentMessageList(message);
      this.$bus.$emit("scroll-bottom");
      this.timChat.tim.sendMessage(message).catch((error) => {
        this.msgError(error.message);
      });
    },
    reEditMessage(payload) {
      this.messageContent = payload;
    },
    handleUp() {
      const index = this.memberList.findIndex(
        (member) => member.userID === this.atUserID
      );
      if (index - 1 < 0) {
        return;
      }
      this.atUserID = this.memberList[index - 1].userID;
    },
    handleDown() {
      const index = this.memberList.findIndex(
        (member) => member.userID === this.atUserID
      );
      if (index + 1 >= this.memberList.length) {
        return;
      }
      this.atUserID = this.memberList[index + 1].userID;
    },
    handleEnter() {
      this.sendTextMessage();
    },
    inputChange(value) {
      if (
        this.currentConversationType === this.TIM.TYPES.CONV_GROUP &&
        value.data === "@"
      ) {
        this.groupAt = true;
        this.listTpye = "groupAt";
        this.showCallingMember = true;
      }
      if (value.data === " " && this.messageContent.indexOf("@ ") !== -1) {
        this.groupAt = false;
        this.listTpye = "";
        this.showCallingMember = false;
      }
    },
    handleLine() {
      this.messageContent += "\n";
    },
    dropHandler(e) {
      e.preventDefault();
      let file = e.dataTransfer.files[0];
      console.log(file);
      if (file.type === "video/mp4") {
        this.sendVideo(file, file.path);
      } else if (
        file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/gif"
      ) {
        this.sendImage(file, file.path);
      } else {
        this.sendFile(file, file.path);
      }
    },
    sendTextMessage() {
      console.log(222222);
      if (
        this.messageContent === "" ||
        this.messageContent.trim().length === 0
      ) {
        this.messageContent = "";
        this.msgInfo("不能发送空消息哦！");
        return;
      }
      if (getBytesLength(this.messageContent) > 8000) {
        this.msgWarn("发送的文本过多，不能大于8000个字节！");
        return;
      }
      if (
        this.currentConversationType === this.TIM.TYPES.CONV_GROUP &&
        this.groupAt
      ) {
        let message = this.timChat.tim.createTextAtMessage({
          to: this.toAccount,
          conversationType: this.TIM.TYPES.CONV_GROUP,
          payload: {
            text: this.messageContent,
            atUserList: this.callingList, // 'denny' 'lucy' 都是 userID，而非昵称
          },
        });
        this.pushCurrentMessageList(message);
        this.$bus.$emit("scroll-bottom");
        console.log(1);
        this.timChat.tim.sendMessage(message).catch((error) => {
          console.log(error.message);
          this.msgError(error.message);
        });
        this.messageContent = "";
        this.groupAt = false;
        return;
      }
      console.log(`this.messageContent ---> ${this.messageContent}`);
      const message = this.timChat.tim.createTextMessage({
        to: this.toAccount,
        conversationType: this.currentConversationType,
        payload: { text: this.messageContent },
      });
      this.pushCurrentMessageList(message);
      this.$bus.$emit("scroll-bottom");
      this.timChat.tim.sendMessage(message).catch((error) => {
        console.log(error.message);
        if (error.message.includes("secure check error! beat word: ")) {
          let str = error.message
            .split("secure check error! beat word: ")[1]
            .split("|")[0];
          this.msgWarn(`发送失败，包含了非法字符：${str}`);
          return;
        }
        this.msgError(error.message);
      });
      this.messageContent = "";
    },
    random(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min);
    },
    chooseEmoji(item) {
      this.messageContent += item;
    },
    handleScreenShotClick() {},
    handleSendFileClick() {
      this.$refs.filePicker.click();
    },
    groupLive() {
      this.$store.commit("updateGroupLiveInfo", {
        groupID: this.toAccount,
        anchorID: this.userID,
      });
      this.$bus.$emit("open-group-live", { channel: 1 });
    },
    fileSelectChange() {
      let file = this.$refs.filePicker.files[0]
      let index = file.name.lastIndexOf(".");
      let extname = file.name.substring(index + 1);
      console.log('extname: ', extname)
      if (file.size > 1024 * 1024 * 200) {
        this.msgWarn("发送的文件大小不能超过200M！");
        return;
      }
      if (
        ["png", "jpeg", "jpg", "gif"].includes(extname.toLocaleLowerCase())
      ) {
        this.sendImage(file, file.path);
      } else if (["mp4"].includes(extname.toLocaleLowerCase())) {
        this.sendVideo(file, file.path);
      } else {
        this.sendFile(file, file.path);
      }
    },
    sendImage(file, filePath) {
      const message = this.timChat.tim.createImageMessage({
        to: this.toAccount,
        conversationType: this.currentConversationType,
        payload: {
          file: file,
        },
        onProgress: (percent) => {
          this.$set(message, "progress", percent); // 手动给message 实例加个响应式属性: progress
        },
      });
      this.pushCurrentMessageList(message);
      this.timChat.tim
        .sendMessage(message)
        .then(({ data }) => {
          if (filePath) {
            this.db.updateData("chatFile", {
              id: message.ID,
              filePath,
            });
          }
        })
        .catch((imError) => {
          this.msgError(imError.message);
        });
    },
    sendFile(file, filePath) {
      const message = this.timChat.tim.createFileMessage({
        to: this.toAccount,
        conversationType: this.currentConversationType,
        payload: {
          file: file,
        },
        onProgress: (percent) => {
          this.$set(message, "progress", percent); // 手动给message 实例加个响应式属性: progress
        },
      });
      this.pushCurrentMessageList(message);
      this.timChat.tim
        .sendMessage(message)
        .then(() => {
          if (filePath) {
            this.db.updateData("chatFile", {
              id: message.ID,
              filePath,
            });
          }
        })
        .catch((imError) => {
          this.msgError(imError.message);
        });
    },
    sendVideo(file, filePath) {
      const message = this.timChat.tim.createVideoMessage({
        to: this.toAccount,
        conversationType: this.currentConversationType,
        payload: {
          file: file, // 或者用event.target
        },
        onProgress: (percent) => {
          this.$set(message, "progress", percent); // 手动给message 实例加个响应式属性: progress
        },
      });
      this.pushCurrentMessageList(message);
      this.timChat.tim
        .sendMessage(message)
        .then(() => {
          if (filePath) {
            this.db.updateData("chatFile", {
              id: message.ID,
              filePath,
            });
          }
        })
        .catch((imError) => {
          this.msgError(imError.message);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
#message-send-box-wrapper {
  box-sizing: border-box;
  overflow: hidden;
  padding: 3px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.emojis {
  height: 160px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow-y: scroll;
}

.emoji {
  height: 40px;
  width: 40px;
  box-sizing: border-box;
  cursor: pointer;
}

.send-header-bar {
  box-sizing: border-box;
  padding: 3px 0 0 0;
}
.chat_tool_btn {
  cursor: pointer;
  font-size: 24px;
  color: gray;
  margin: 0 12px 0 0;
}




.text-input {
  font-size: 16px;
  width: 100%;
  box-sizing: box-sizing;
  border: none;
  outline: none;
  background-color: transparent;
}

.block {
  padding: 10px 0;
  display: flex;
  align-items: center;
}
/deep/ {
  .icon_prepare {
    font-size: 20px;
    margin-left: -3px;
  }
}
.bottom {
  padding-top: 10px;
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  textarea {
    flex: 1;
    resize: none;
  }
  .bottom-btn {
    height: 30px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .btn-send {
      padding: 6px 10px;
      border-radius: 4px;
      background-color: #024df8;
      color: #ffffff;
      cursor: pointer;
      font-size: 14px;
    }
  }
  .btn-send {
    cursor: pointer;
    position: absolute;
    color: #2d8cf0;
    font-size: 30px;
    right: 0;
    bottom: -5px;
    padding: 6px 6px 4px 4px;
    border-radius: 50%;
  }
}
.group-live-icon-box {
  display: inline-block;
  position: relative;
  top: 3px;
  width: 25px;
  height: 25px;
  margin-right: 12px;
  .group-live-icon {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 25px;
    height: 25px;
    background: url("../../../../../assets/image/chat/live-icon.png") center
      no-repeat;
    background-size: cover;
    z-index: 2;
  }
  .group-live-icon-hover {
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 25px;
    height: 25px;
    background: url("../../../../../assets/image/chat/live-icon-hover.png")
      center no-repeat;
    background-size: cover;
    z-index: 1;
  }
}
.group-live-icon-box:hover {
  .group-live-icon {
    z-index: 1;
  }
  .group-live-icon-hover {
    z-index: 2;
  }
}
.calling-member-list {
  position: absolute;
  top: 50px;
  background: #fff;
  margin-right: 20px;
  .calling-list-btn {
    width: 140px;
    display: flex;
    float: right;
    margin: 10px 0;
    .calling-btn {
      cursor: pointer;
      padding: 6px 12px;
      background: #00a4ff;
      color: #ffffff;
      font-size: 14px;
      border-radius: 20px;
      margin-left: 13px;
    }
  }
}
.el-popover {
  padding: 12px 0 0 0 !important;
}
</style>
