<template>
  <div >
    <div
      class="x_dialog"
      draggable="false"
      ondragstart="return false;"
      ondrop="return false;"
      v-show="dialogFileViewPrivateInfo.show"
    >
      <div class="x_dialog_header">
        文件预览
        <span
          class="dialog_close iconfont icon-guanbi3"
          title="关闭"
          @click="closeClick"
        ></span>
      </div>
      <div class="x_dialog_content" ref="imageWrapper">
        <div
          class="prev_img img_btn"
          :class="{
            img_btn_disable: curStep == 0,
            img_btn_able: curStep != 0,
          }"
          v-show="dialogFileViewPrivateInfo.type == 'img'"
          @click="moveImgListClick('prev')"
        >
          <span class="iconfont icon-zuojiantou"></span>
        </div>
        <div
          class="next_img img_btn"
          :class="{
            img_btn_disable: curStep == maxStep,
            img_btn_able: curStep != maxStep,
          }"
          v-show="dialogFileViewPrivateInfo.type == 'img'"
          @click="moveImgListClick('next')"
        >
          <span class="iconfont icon-youjiantou"></span>
        </div>
        <div v-if="dialogFileViewPrivateInfo.type == 'file'">
          <iframe
            :src="dialogFileViewPrivateInfo.src"
            frameborder="0"
            class="file_iframe"
          ></iframe>
        </div>
        <div
          v-else-if="dialogFileViewPrivateInfo.type == 'img'"
          class="img_box"
        >
          <img :src="curImgSrc" alt="" :style="[imgStyle]" v-drag-img />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      scale: 1,
      imgStyle: {
        transform: "scale(1)",
        marginLeft: "0",
        marginTop: "0",
      },
      curStep: 0,
      maxStep: 1,
      imgList: [],
      curImgSrc: null,
    };
  },
  props: ["dialogFileViewPrivateInfo"],
  directives: {
    dragImg: {
      inserted(el, binding, vnode) {
        el.onmousedown = function (e) {
          e.stopPropagation();
          let oldMarinLeft = parseInt(el.style.marginLeft),
            oldMarginTop = parseInt(el.style.marginTop);
          document.onmousemove = function (ev) {
            vnode.context.imgStyle.marginLeft =
              ev.clientX - e.clientX + oldMarinLeft + "px";
            vnode.context.imgStyle.marginTop =
              ev.clientY - e.clientY + oldMarginTop + "px";
          };
          document.onmouseup = function () {
            document.onmousemove = null;
            document.onmouseup = null;
          };
        };
      },
    },
  },
  components: {},
  computed: {},
  mounted() {
    if (this.dialogFileViewPrivateInfo.type == "img") {
      this.imageWrapperAddMouseWheel();
    }
  },
  watch: {
    "dialogFileViewPrivateInfo.type"() {
      if (this.dialogFileViewPrivateInfo.type == "img") {
        if (!this.$refs.imageWrapper.onmousewheel) {
          this.imageWrapperAddMouseWheel();
        }
      }
    },
    "dialogFileViewPrivateInfo.show"() {
      this.scale = 1;
      this.imgStyle = {
        transform: "scale(1)",
        marginLeft: "0",
        marginTop: "0",
      };
      this.curImgSrc = this.dialogFileViewPrivateInfo.src;
      this.imgList = this.dialogFileViewPrivateInfo.imgList
        ? this.dialogFileViewPrivateInfo.imgList
        : [];
      this.curStep = this.dialogFileViewPrivateInfo.curIndex
        ? this.dialogFileViewPrivateInfo.curIndex
        : 0;
      this.maxStep = this.imgList.length - 1;
    },
    curStep() {
      this.scale = 1;
      this.imgStyle = {
        transform: "scale(1)",
        marginLeft: "0",
        marginTop: "0",
      };
    },
  },
  methods: {
    moveImgListClick(type) {
      if (type == "prev" && this.curStep > 0) {
        this.curStep--;
      } else if (type == "next" && this.curStep < this.maxStep) {
        this.curStep++;
      }
      this.curImgSrc = this.imgList[this.curStep];
    },
    closeClick() {
      this.$emit("closeFileView");
    },
    zoomOut() {
      if (this.scale <= 0.2) {
        return;
      }
      this.scale -= 0.1;
      this.imgStyle.transform = `scale(${this.scale})`;
    },
    zoomIn() {
      if (this.scale > 8) {
        return;
      }
      this.scale += 0.1;
      this.imgStyle.transform = `scale(${this.scale})`;
    },
    imageWrapperAddMouseWheel() {
      let scrollFunc = (e) => {
        if (this.dialogFileViewPrivateInfo.type == "img") {
          e = e || window.event;
          //e.wheelDelta 判断浏览器IE，谷歌滑轮事件; e.detail 判断火狐滑轮事件
          let detail =
            (e.wheelDelta && (e.wheelDelta > 0 ? 1 : -1)) ||
            (e.detail && (e.detail > 0 ? 1 : -1));
          if (detail > 0) {
            this.zoomIn();
          } else {
            this.zoomOut();
          }
        }
      };
      this.$refs.imageWrapper.onmousewheel = scrollFunc;
    },
  },
};
</script>
<style lang="scss" scoped>
.x_dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 900px;
  height: 660px;
  display: flex;
  // z-index: 30000;
  flex-direction: column;
  background-color: #fff;
  color: #222222;
  z-index:9999999999999 !important;
  .x_dialog_header {
    height: 40px;
    background-color: #ffffff;
    line-height: 40px;
    text-align: center;
    cursor: move;
    .dialog_close {
      position: absolute;
      top: 0px;
      right: 10px;
      cursor: pointer;
      font-size: 20px;
    }
  }
  .x_dialog_content {
    flex: 1;
    overflow: hidden;
    position: relative;
    .file_iframe {
      width: 100%;
      height: 600px;
    }
    .img_box {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .img_btn {
      width: 30px;
      height: 30px;
      border-radius: 30px;
      background-color: #ffffff;
      border: 1px solid #dddddd;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      text-align: center;
      line-height: 30px;
      cursor: pointer;
      z-index: 10;
      .iconfont {
        color: #aaaaaa;
      }
    }
    .img_btn_able {
      &:hover {
        .iconfont {
          color: #024df8;
        }
        border-color: #024df8;
      }
    }
    .img_btn_disable {
      cursor: default;
      background-color: #dddddd;
    }
    .prev_img {
      left: 20px;
    }
    .next_img {
      right: 20px;
    }
  }
}
</style>
