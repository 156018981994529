var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"group-member-list-wrapper"},[_c('div',{staticClass:"header"},[_c('span',{staticClass:"member-count text-ellipsis"},[_vm._v("群成员："+_vm._s(_vm.currentConversation.groupProfile.memberCount))])]),_c('div',{staticClass:"scroll-content"},[_c('div',{staticClass:"group-member-list"},[_c('el-checkbox-group',{on:{"change":_vm.handleCheckedMembersChange},model:{value:(_vm.callingList),callback:function ($$v) {_vm.callingList=$$v},expression:"callingList"}},[(_vm.type === 'groupAt')?_c('el-checkbox',{attrs:{"label":JSON.stringify({
              userID: this.TIM.TYPES.MSG_AT_ALL,
              nick: '所有人',
            })}},[_c('div',{staticClass:"group-member"},[_c('avatar',{attrs:{"src":''}}),_c('div',{staticClass:"member-name text-ellipsis"},[_c('span',[_vm._v("所有人")])])],1)]):_vm._e(),_vm._l((_vm.members),function(member){return _c('el-checkbox',{key:member.userID,attrs:{"disabled":member.userID === _vm.userID,"label":JSON.stringify({
              userID: member.userID,
              nick: member.nameCard || member.nick || member.userID,
            })}},[_c('div',{staticClass:"group-member"},[_c('avatar',{attrs:{"src":member.avatar}}),_c('div',{staticClass:"member-name text-ellipsis"},[(member.nameCard)?_c('span',[_vm._v(_vm._s(member.nameCard))]):(member.nick)?_c('span',[_vm._v(_vm._s(member.nick))]):_c('span',[_vm._v(_vm._s(member.userID))])])],1)])})],2)],1)]),_c('div',{staticClass:"more"},[(_vm.showLoadMore)?_c('el-button',{attrs:{"type":"text"},on:{"click":_vm.loadMore}},[_vm._v("查看更多")]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }