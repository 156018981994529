import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import login from '@/page/login'
import index from '@/page/index'
Vue.use(Router)
const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return routerPush.call(this, location).catch(error => { })
}
const router = new Router({
    routes: [{
        path: '/',
        name: 'login',
        redirect: '/login',
        meta: {
            title: '登录',
        },
    },
    {
        path: '/login',
        name: 'login',
        component: login,
        meta: {
            title: '登录',
        },
    },
    {
        path: '/index',
        name: 'index',
        component: index,
        meta: {
            title: '首页',
            breadList: [''],
        },
        children: [
            /* 系统 -> 系统管理路由 */
            {
                path: '/system/systemManage/areaManage',
                name: 'areaManage',
                component: () => {
                    return import( /* webpackChunkName: "areaManage" */ '../page/system/systemManage/areaManage.vue')
                },
                meta: {
                    title: '行政区域',
                    breadList: ['系统', '系统管理', '行政区域'],
                },
            },
            {
                path: '/system/systemManage/courseManage',
                name: 'courseManage',
                component: () => {
                    return import( /* webpackChunkName: "courseManage" */ '../page/system/systemManage/courseManage.vue')
                },
                meta: {
                    title: '课程体系',
                    breadList: ['系统', '系统管理', '课程体系'],
                },
            },
            {
                path: '/system/systemManage/macroManage',
                name: 'macroManage',
                component: () => {
                    return import( /* webpackChunkName: "macroManage" */ '../page/system/systemManage/macroManage.vue')
                },
                meta: {
                    title: '通用字典',
                    breadList: ['系统', '系统管理', '通用字典'],
                },
            },
            {
                path: '/system/systemManage/orgManage',
                name: 'orgManage',
                component: () => {
                    return import( /* webpackChunkName: "orgManage" */ '../page/system/systemManage/orgManage.vue')
                },
                meta: {
                    title: '机构管理',
                    breadList: ['系统', '系统管理', '机构管理'],
                },
            },
            {
                path: '/system/systemManage/roleManage',
                name: 'roleManage',
                component: () => {
                    return import( /* webpackChunkName: "orgMaroleManagenage" */ '../page/system/systemManage/roleManage.vue')
                },
                meta: {
                    title: '角色管理',
                    breadList: ['系统', '系统管理', '角色管理'],
                },
            },
            {
                path: '/system/systemManage/menuManage',
                name: 'menuManage',
                component: () => {
                    return import( /* webpackChunkName: "menuManage" */ '../page/system/systemManage/menuManage.vue')
                },
                meta: {
                    title: '系统菜单',
                    breadList: ['系统', '系统管理', '系统菜单'],
                },
            },
            {
                path: '/system/systemManage/userManage',
                name: 'userManage',
                component: () => {
                    return import( /* webpackChunkName: "userManage" */ '../page/system/systemManage/userManage.vue')
                },
                meta: {
                    title: '用户管理',
                    breadList: ['系统', '系统管理', '用户管理'],
                },
            },
            /* 系统 -> app管理路由 */
            {
                path: '/system/appManage/articleManage',
                name: 'articleManage',
                component: () => {
                    return import( /* webpackChunkName: "userManage" */ '../page/system/appManage/articleManage.vue')
                },
                meta: {
                    title: '好文推荐',
                    breadList: ['系统', '资讯管理', '好文推荐'],
                },
            },
            {
                path: '/system/appManage/bannerManage',
                name: 'bannerManage',
                component: () => {
                    return import( /* webpackChunkName: "bannerManage" */ '../page/system/appManage/bannerManage.vue')
                },
                meta: {
                    title: 'Banner 管理',
                    breadList: ['系统', '资讯管理', 'Banner 管理'],
                },
            },
            {
                path: '/system/appManage/informationManage',
                name: 'informationManage',
                component: () => {
                    return import( /* webpackChunkName: "informationManage" */ '../page/system/appManage/informationManage.vue')
                },
                meta: {
                    title: '资讯列表',
                    breadList: ['系统', '资讯管理', '资讯列表'],
                },
            },
            {
                path: '/system/appManage/inlineManage',
                name: 'inlineManage',
                component: () => {
                    return import( /* webpackChunkName: "inlineManage" */ '../page/system/appManage/inlineManage.vue')
                },
                meta: {
                    title: '内链管理列表',
                    breadList: ['系统', '资讯管理', '内链管理列表'],
                },
            },
            {
                path: '/system/appManage/tagsManage',
                name: 'tagsManage',
                component: () => {
                    return import( /* webpackChunkName: "tagsManage" */ '../page/system/appManage/tagsManage.vue')
                },
                meta: {
                    title: 'tags标签管理',
                    breadList: ['系统', '资讯管理', 'tags标签管理'],
                },
            },
            {
                path: '/system/appManage/teacherCashManage',
                name: 'teacherCashManage',
                component: () => {
                    return import( /* webpackChunkName: "teacherCashManage" */ '../page/system/appManage/teacherCashManage.vue')
                },
                meta: {
                    title: '教师提现配置',
                    breadList: ['系统', '资讯管理', '教师提现配置'],
                },
            },
            /* 系统 -> 公共资源库 */
            {
                path: '/system/publicSource/sourceManage',
                name: 'sourceManage',
                component: () => {
                    return import( /* webpackChunkName: "sourceManage" */ '../page/system/publicSource/sourceManage.vue')
                },
                meta: {
                    title: '公共资源库',
                    breadList: ['系统', '公共资源库'],
                },
            },
            /* 销售 */
            {
                path: '/sales/sectionManage/teamSetting',
                name: 'teamSetting',
                component: () => {
                    return import( /* webpackChunkName: "teamSetting" */ '../page/sales/sectionManage/teamSetting.vue')
                },
                meta: {
                    title: '团队设置',
                    breadList: ['销售', '部门管理', '团队设置'],
                },
            },
            {
                path: '/sales/sectionManage/customerManage',
                name: 'customerManage',
                component: () => {
                    return import( /* webpackChunkName: "customerManage" */ '../page/sales/sectionManage/customerManage.vue')
                },
                meta: {
                    title: '客户管理池',
                    breadList: ['销售', '部门管理', '客户管理池'],
                },
            },
            {
                path: '/sales/sectionManage/releaseCustomer',
                name: 'releaseCustomer',
                component: () => {
                    return import( /* webpackChunkName: "releaseCustomer" */ '../page/sales/sectionManage/releaseCustomer.vue')
                },
                meta: {
                    title: '被释放客户',
                    breadList: ['销售', '部门管理', '被释放客户'],
                },
            },
            {
                path: '/sales/myOrder/myOrder',
                name: 'myOrder',
                component: () => {
                    return import( /* webpackChunkName: "myOrder" */ '../page/sales/myOrder/myOrder.vue')
                },
                meta: {
                    title: '我的订单',
                    breadList: ['销售', '我的订单', '我的订单'],
                },
            },

            /* 我的客户 */
            {
                path: '/sales/myCustomer/myCustomer',
                name: 'myCustomer',
                component: () => {
                    return import( /* webpackChunkName: "myCustomer" */ '../page/sales/myCustomer/myCustomer.vue')
                },
                meta: {
                    title: '我的客户',
                    breadList: ['销售', '我的客户', '我的客户'],
                },
            },
            {
                path: '/sales/myCustomer/myCustomer/studentInfo/:id',
                name: 'myCustomer_studentInfo',
                component: () => {
                    return import( /* webpackChunkName: "myCustomer_studentInfo" */ '../page/sales/myCustomer/studentInfo.vue')
                },
                meta: {
                    title: '客户详情',
                    breadList: ['销售', '我的客户', '我的客户', '客户详情'],
                },
            },
            {
                path: '/sales/sectionManage/sectionManage/studentInfo/:id',
                name: 'sectionManageStudentInfo',
                component: () => {
                    return import( /* webpackChunkName: "sectionManageStudentInfo" */ '../page/sales/sectionManage/studentInfo.vue')
                },
                meta: {
                    title: '客户详情',
                    breadList: ['销售', '部门管理', '客户详情'],
                },
            },
            {
                path: '/sales/blackArea',
                name: 'blackArea',
                component: () => {
                    return import( /* webpackChunkName: "blackArea" */ '../page/sales/blackArea.vue')
                },
                meta: {
                    title: '待黑区',
                    breadList: ['销售', '数据管理', '待黑区'],
                },
            },
            {
                path: '/sales/blackList',
                name: 'blackList',
                component: () => {
                    return import( /* webpackChunkName: "blackList" */ '../page/sales/blackList.vue')
                },
                meta: {
                    title: '黑名单客户',
                    breadList: ['销售', '数据管理', '黑名单客户'],
                },

            },
            {
                path: '/sales/registered',
                name: 'registered',
                component: () => {
                    return import( /* webpackChunkName: "registered" */ '../page/sales/registered.vue')
                },
                meta: {
                    title: '已注册客户',
                    breadList: ['销售', '数据管理', '已注册客户'],
                },
            },
            {
                path: '/sales/customerPool',
                name: 'customerPool',
                component: () => {
                    return import( /* webpackChunkName: "customerPool" */ '../page/sales/customerPool.vue')
                },
                meta: {
                    title: '客户管理池',
                    breadList: ['销售', '数据管理', '客户管理池'],
                },
            },
            {
                path: '/sales/autoDist',
                name: 'autoDist',
                component: () => {
                    return import( /* webpackChunkName: "autoDist" */ '../page/sales/autoDist.vue')
                },
                meta: {
                    title: '自动分配',
                    breadList: ['销售', '数据管理', '自动分配'],
                },
            },
            /* 运营 */
            {
                path: '/operates/productManage/oneToOne',
                name: 'oneToOne',
                component: () => {
                    return import( /* webpackChunkName: "oneToOne" */ '../page/operates/productManage/oneToOne.vue')
                },
                meta: {
                    title: '试听课',
                    breadList: ['运营', '试听课'],
                },
            },
            {
                path: '/operates/productManage/oneToMore',
                name: 'oneToMore',
                component: () => {
                    return import( /* webpackChunkName: "oneToMore" */ '../page/operates/productManage/oneToMore.vue')
                },
                meta: {
                    title: '一对多',
                    breadList: ['运营', '一对多'],
                },
            },
            {
                path: '/operates/productManage/oneToAll',
                name: 'oneToAll',
                component: () => {
                    return import( /* webpackChunkName: "oneToAll" */ '../page/operates/productManage/oneToAll.vue')
                },
                meta: {
                    title: '免费公开课',
                    breadList: ['运营', '免费公开课'],
                },
            },
            /* 财务 */
            {
                path: '/finance/orderCheck',
                name: 'orderCheck',
                component: () => {
                    return import( /* webpackChunkName: "orderCheck" */ '../page/finance/orderCheck.vue')
                },
                meta: {
                    title: '订单审核',
                    breadList: ['财务', '订单审核'],
                },
            },
            {
                path: '/finance/personManage',
                name: 'personManage',
                component: () => {
                    return import( /* webpackChunkName: "personManage" */ '../page/finance/personManage.vue')
                },
                meta: {
                    title: '人员管理',
                    breadList: ['财务', '人员管理'],
                },
            },
            /* 财务中的剩余课时 */
            {
                path: '/finance/tubeTeachBusiness/surplusClassAll',
                name: 'surplusClassAll',
                component: () => {
                    return import( /* webpackChunkName: "surplusClassAll" */ '../page/teachBusiness/tubeTeachBusiness/surplusClassAll.vue')
                },
                meta: {
                    title: '剩余课时',
                    breadList: ['财务', '剩余课时'],
                },
            },
            {
                path: '/finance/productIncome',
                name: 'productIncome',
                component: () => {
                    return import( /* webpackChunkName: "productIncome" */ '../page/finance/productIncome.vue')
                },
                meta: {
                    title: '产品收入',
                    breadList: ['财务', '产品收入'],
                },
            },
            {
                path: '/finance/withdrawalToExamine',
                name: 'withdrawalToExamine',
                component: () => {
                    return import( /* webpackChunkName: "withdrawalToExamine" */ '../page/finance/withdrawalToExamine.vue')
                },
                meta: {
                    title: '提现待审核',
                    breadList: ['财务', '提现待审核'],
                },
            },
            {
                path: '/finance/classPay',
                name: 'classPay',
                component: () => {
                    return import( /* webpackChunkName: "classPay" */ '../page/finance/classPay.vue')
                },
                meta: {
                    title: '课酬设置',
                    breadList: ['财务', '课酬设置'],
                },
            },
            {
                path: '/finance/unitPrice',
                name: 'unitPrice',
                component: () => {
                    return import( /* webpackChunkName: "unitPrice" */ '../page/finance/unitPrice.vue')
                },
                meta: {
                    title: '单价设置',
                    breadList: ['财务', '单价设置'],
                },
            },
            {
                path: '/finance/classHours',
                name: 'classHours',
                component: () => {
                    return import( /* webpackChunkName: "classHours" */ '../page/finance/classHours.vue')
                },
                meta: {
                    title: '课时统计',
                    breadList: ['财务', '课时统计'],
                },
            },
            {
                path: '/finance/refundDetails',
                name: 'refundDetails',
                component: () => {
                    return import( /* webpackChunkName: "refundDetails" */ '../page/finance/refundDetails.vue')
                },
                meta: {
                    title: '退款明细',
                    breadList: ['财务', '退款明细'],
                },
            },
            // 讲师
            {
                path: '/teacher/teacherManage/teacherManageList',
                name: 'teacherManage',
                component: () => {
                    return import( /* webpackChunkName: "teacherManage" */ '../page/teacher/teacherManage/teacherManageList.vue')
                },
                meta: {
                    title: '讲师列表',
                    breadList: ['讲师', '讲师管理', '讲师列表'],
                },
            },
            {
                path: '/teacher/adviserManage/adviserManageList',
                name: 'adviserManage',
                component: () => {
                    return import( /* webpackChunkName: "adviserManage" */ '../page/teacher/adviserManage/adviserManageList.vue')
                },
                meta: {
                    title: '人员管理',
                    breadList: ['讲师', '顾问管理', '人员管理'],
                },
            },
            {
                path: '/teacher/auditorManage/auditorManageList',
                name: 'auditorManage',
                component: () => {
                    return import( /* webpackChunkName: "auditorManage" */ '../page/teacher/auditorManage/auditorManageList.vue')
                },
                meta: {
                    title: '讲师列表',
                    breadList: ['讲师', '讲师审核', '讲师列表'],
                },
            },
            {
                path: '/teacher/auditorManage/teacherScheduling',
                name: 'teacherScheduling',
                component: () => {
                    return import( /* webpackChunkName: "teacherScheduling" */ '../page/teacher/auditorManage/teacherScheduling.vue')
                },
                meta: {
                    title: '教师排课量',
                    breadList: ['讲师', '讲师审核', '教师排课量'],
                },
            },
            {
                path: '/teacher/auditorManage/subjectManageList',
                name: 'subjectManage',
                component: () => {
                    return import( /* webpackChunkName: "subjectManage" */ '../page/teacher/auditorManage/subjectManageList.vue')
                },
                meta: {
                    title: '试题列表',
                    breadList: ['讲师', '讲师审核', '试题试卷', '试题列表'],
                },
            },
            {
                path: '/teacher/auditorManage/knowledgeCatalog',
                name: 'knowledgeCatalog',
                component: () => {
                    return import( /* webpackChunkName: "knowledgeCatalog" */ '../page/teacher/auditorManage/knowledgeCatalog.vue')
                },
                meta: {
                    title: '知识点目录',
                    breadList: ['讲师', '讲师审核', '试题试卷', '知识点目录'],
                },
            },
            {
                path: '/teacher/auditorManage/knowledgeList',
                name: 'knowledgeList',
                component: () => {
                    return import( /* webpackChunkName: "knowledgeList" */ '../page/teacher/auditorManage/knowledgeList.vue')
                },
                meta: {
                    title: '知识点列表',
                    breadList: ['讲师', '讲师审核', '试题试卷', '知识点列表'],
                },
            },
            {
                path: '/teacher/auditorManage/teachingCatalog',
                name: 'teachingCatalog',
                component: () => {
                    return import( /* webpackChunkName: "teachingCatalog" */ '../page/teacher/auditorManage/teachingCatalog.vue')
                },
                meta: {
                    title: '教学目录',
                    breadList: ['讲师', '讲师审核', '试题试卷', '教学目录'],
                },
            },
            {
                path: '/teacher/auditorManage/paperList',
                name: 'paperList',
                component: () => {
                    return import( /* webpackChunkName: "paperList" */ '../page/teacher/auditorManage/paperList.vue')
                },
                meta: {
                    title: '试卷列表',
                    breadList: ['讲师', '讲师审核', '试题试卷', '试卷列表'],
                },
            },
            {
                path: '/teacher/auditorManage/interviewAnswer',
                name: 'interviewAnswer',
                component: () => {
                    return import( /* webpackChunkName: "interviewAnswer" */ '../page/teacher/auditorManage/interviewAnswer.vue')
                },
                meta: {
                    title: '试答题',
                    breadList: ['讲师', '讲师审核', '试题试卷', '试答题'],
                },
            },
            {
                path: '/teachBusiness/tubeTeachBusiness/personnelList',
                name: 'personnelList',
                component: () => {
                    return import( /* webpackChunkName: "personnelList" */ '../page/teachBusiness/tubeTeachBusiness/personnelList.vue')
                },
                meta: {
                    title: '人员管理',
                    breadList: ['教务', '教务主管', '人员管理'],
                },
            },
            {
                path: '/teachBusiness/tubeTeachBusiness/allClassHours',
                name: 'allClassHours',
                component: () => {
                    return import( /* webpackChunkName: "allClassHours" */ '../page/teachBusiness/tubeTeachBusiness/allClassHours.vue')
                },
                meta: {
                    title: '部门课时',
                    breadList: ['教务', '教务主管', '部门课时'],
                },
            },
            {
                path: '/teachBusiness/tubeTeachBusiness/allsuperviseClass',
                name: 'allsuperviseClass',
                component: () => {
                    return import( /* webpackChunkName: "allsuperviseClass" */ '../page/teachBusiness/tubeTeachBusiness/allsuperviseClass.vue')
                },
                meta: {
                    title: '监课管理',
                    breadList: ['教务', '教务主管', '监课管理'],
                },
            },
            /* 教务主管中的剩余课时 */
            {
                path: '/teachBusiness/tubeTeachBusiness/surplusClassAll',
                name: 'surplusClassAll',
                component: () => {
                    return import( /* webpackChunkName: "surplusClassAll" */ '../page/teachBusiness/tubeTeachBusiness/surplusClassAll.vue')
                },
                meta: {
                    title: '剩余课时',
                    breadList: ['教务', '教务主管', '剩余课时'],
                },
            },
            {
                path: '/teachBusiness/expertTeachBusiness/orderlist',
                name: 'orderlist',
                component: () => {
                    return import( /* webpackChunkName: "orderlist" */ '../page/teachBusiness/expertTeachBusiness/orderlist.vue')
                },
                meta: {
                    title: '教务订单',
                    breadList: ['教务', '教务专员', '教务订单'],
                },
            },
            {
                path: '/teachBusiness/expertTeachBusiness/teacherManage',
                name: 'teacherManageList',
                component: () => {
                    return import( /* webpackChunkName: "teacherManageList" */ '../page/teachBusiness/expertTeachBusiness/teacherManage.vue')
                },
                meta: {
                    title: '教师管理',
                    breadList: ['教务', '教务专员', '教师管理'],
                },
            },
            {
                path: '/teachBusiness/tubeTeachBusiness/classStudent',
                name: 'classStudent',
                component: () => {
                    return import( /* webpackChunkName: "classStudent" */ '../page/teachBusiness/tubeTeachBusiness/classStudent.vue')
                },
                meta: {
                    title: '部门学员',
                    breadList: ['教务', '教务主管', '部门学员'],
                },
            },
            {
                path: '/teachBusiness/expertTeachBusiness/studentManage',
                name: 'studentManage',
                component: () => {
                    return import( /* webpackChunkName: "studentManage" */ '../page/teachBusiness/expertTeachBusiness/studentManage.vue')
                },
                meta: {
                    title: '学员管理',
                    breadList: ['教务', '教务专员', '学员管理'],
                },
            },
            {
                path: '/teachBusiness/expertTeachBusiness/studentManage2',
                name: 'studentManage2',
                component: () => {
                    return import( /* webpackChunkName: "studentManage2" */ '../page/teachBusiness/expertTeachBusiness/studentManage2.vue')
                },
            },
            {
                path: '/teachBusiness/expertTeachBusiness/messageCenter',
                name: 'messageCenter',
                component: () => {
                    return import( /* webpackChunkName: "messageCenter" */ '../page/teachBusiness/expertTeachBusiness/chat/messageCenter.vue')
                },
                meta: {
                    title: '消息中心',
                    breadList: ['教务', '教务专员', '消息中心'],
                },
            },
            {
                path: '/teachBusiness/expertTeachBusiness/playback',
                name: 'playback',
                component: () => {
                    return import( /* webpackChunkName: "playback" */ '../page/teachBusiness/expertTeachBusiness/playback.vue')
                },
                meta: {
                    title: '回放管理',
                    breadList: ['教务', '教务专员', '回放管理'],
                },
            },
            {
                path: '/teachBusiness/tubeTeachBusiness/allplayback',
                name: 'allplayback',
                component: () => {
                    return import( /* webpackChunkName: "allplayback" */ '../page/teachBusiness/tubeTeachBusiness/allplayback.vue')
                },
                meta: {
                    title: '回放管理',
                    breadList: ['教务', '教务主管', '回放管理'],
                },
            },
            {
                path: '/teachBusiness/expertTeachBusiness/superviseClass',
                name: 'superviseClass',
                component: () => {
                    return import( /* webpackChunkName: "superviseClass" */ '../page/teachBusiness/expertTeachBusiness/superviseClass.vue')
                },
                meta: {
                    title: '监课管理',
                    breadList: ['教务', '教务专员', '监课管理'],
                },
            },
            {
                path: '/teachBusiness/expertTeachBusiness/surplusClass',
                name: 'surplusClass',
                component: () => {
                    return import( /* webpackChunkName: "surplusClass" */ '../page/teachBusiness/expertTeachBusiness/surplusClass.vue')
                },
                meta: {
                    title: '剩余课时',
                    breadList: ['教务', '教务专员', '剩余课时'],
                },
            },
            {
                path: '/teachBusiness/tubeTeachBusiness/onToMoreBranch',
                name: 'onToMoreBranch',
                component: () => {
                    return import( /* webpackChunkName: "onToMoreBranch" */ '../page/teachBusiness/tubeTeachBusiness/onToMoreBranch.vue')
                },
            },
            {
                path: '/teachBusiness/expertTeachBusiness/onToMoreWork',
                name: 'onToMoreWork',
                component: () => {
                    return import( /* webpackChunkName: "onToMoreWork" */ '../page/teachBusiness/expertTeachBusiness/onToMoreWork.vue')
                },
            },
            {
                path: '/teachBusiness/expertTeachBusiness/timetable',
                name: 'timetable',
                component: () => {
                    return import( /* webpackChunkName: "timetable" */ '../page/teachBusiness/expertTeachBusiness/timetable.vue')
                },
                meta: {
                    title: '课表管理',
                    breadList: ['教务', '教务专员', '课表管理'],
                },
            },
            {
                path: '/teachBusiness/expertTeachBusiness/classHour',
                name: 'classHour',
                component: () => {
                    return import( /* webpackChunkName: "classHour" */ '../page/teachBusiness/expertTeachBusiness/classHour.vue')
                },
                meta: {
                    title: '课时管理',
                    breadList: ['教务', '教务专员', '课时管理'],
                },
            },
            {
                path: '/sales/sectionManage/allocated',
                name: 'allocated',
                component: () => {
                    return import( /* webpackChunkName: "allocated" */ '../page/sales/sectionManage/allocated.vue')
                },
                meta: {
                    title: '已分配客户',
                    breadList: ['销售', '部门管理', '已分配客户'],
                },
            },
            {
                path: '/teachBusiness/tubeTeachBusiness/classTimetable',
                name: 'classTimetable',
                component: () => {
                    return import( /* webpackChunkName: "classTimetable" */ '../page/teachBusiness/tubeTeachBusiness/classTimetable.vue')
                },
                meta: {
                    title: '部门课表',
                    breadList: ['教务', '教务主管', '部门课表'],
                },
            },
            {
                path: '/teachBusiness/tubeTeachBusiness/refund',
                name: 'refund',
                component: () => {
                    return import( /* webpackChunkName: "refund" */ '../page/teachBusiness/tubeTeachBusiness/refund.vue')
                },
                meta: {
                    title: '退费审核',
                    breadList: ['教务', '教务主管', '退费审核'],
                },
            },
            {
                path: '/openClass/index',
                name: 'openClass',
                component: () => {
                    return import( /* webpackChunkName: "openClass" */ '../page/openClass/index.vue')
                },
                meta: {
                    title: '公开课排课',
                    breadList: ['班课管理员', '公开课排课'],
                },
            },
            {
                path: '/openClass/supervise',
                name: 'supervise',
                component: () => {
                    return import( /* webpackChunkName: "supervise" */ '../page/openClass/supervise.vue')
                },
                meta: {
                    title: '监课管理',
                    breadList: ['班课管理员', '监课管理'],
                },
            },
            {
                path: '/openClass/back',
                name: 'back',
                component: () => {
                    return import( /* webpackChunkName: "back" */ '../page/openClass/back.vue')
                },
                meta: {
                    title: '回放管理',
                    breadList: ['班课管理员', '回放管理'],
                },
            },
            {
                path: '/openClass/classManage',
                name: 'classManage',
                component: () => {
                    return import( /* webpackChunkName: "classManage" */ '../page/openClass/classManage.vue')
                },
                meta: {
                    title: '课时管理',
                    breadList: ['班课管理员', '课时管理'],
                },
            },
            {
                path: '/openClass/oneToMany',
                name: 'oneToMany',
                component: () => {
                    return import( /* webpackChunkName: "oneToMany" */ '../page/openClass/oneToMany.vue')
                },
                meta: {
                    title: '一对多排课',
                    breadList: ['班课管理员', '一对多排课'],
                },
            },
        ]
    },
    ]
})
router.beforeEach((to, _, next) => {
    //console.log('router.beforeEach: ', to)
    if (to.path.includes('messageCenter') || to.path.includes('login')) {
        store.commit('chatInfo/chatShotShowChange', false)
    } else {
        store.commit('chatInfo/chatShotShowChange', true)
    }
    store.commit('chatInfo/chatBoxShowChange', {
        show: false
    })
    store.commit('chatInfo/chatShotConListShowChange', false)
    store.commit('chatInfo/chatShotConversationShowChange', false)
    next()
})
router.afterEach(to => {
    document.title = to.meta.title
    store.commit('chatInfo/breadListChange', to.meta.breadList)
})
export default router