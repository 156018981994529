<template>
  <div class="file_view" v-show="dialogFileViewInfo.show">
    <div class="file-container">
      <iframe
        :src="dialogFileViewInfo.url"
        frameborder="0"
        class="file_iframe"
      ></iframe>
    </div>
    <div class="close_btn" title="关闭" @click="handleCloseDialog">
      <span class="dialog_close iconfont icon-guanbi3" ></span>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex';
import { mapState } from 'vuex';
export default {
  data() {
    return {};
  },
  components: {},
  computed: {
    ...mapState('dialogCont', ['dialogFileViewInfo']),
  },
  mounted() {},
  methods: {
    ...mapMutations('dialogCont', ['dialogFileViewInfoChange']),
    handleCloseDialog(){
        this.dialogFileViewInfoChange({
            show: false,
            url: '',
        })
    },
  },
};
</script>
<style lang='scss' scoped>
.file_view {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba($color: #000000, $alpha: 0.7);
  z-index: 99999999;
  display: flex;
  justify-content: center;
  align-items: center;
  .file-container {
    width: 900px;
    height: 100vh;
    background-color: #ffffff;
    .file_iframe {
      width: 100%;
      height: 100vh;
    }
  }
  .close_btn {
    position: absolute;
    right: 40px;
    top: 40px;
    cursor: pointer;
    .dialog_close{
        color: #ffffff;
        font-size: 40px;
    }
  }
}
</style>