import tim from '@/tim'
import TIM from 'tim-js-sdk'
import store from '..'
import { filterCallingMessage } from '@/utils/common'
//import { Message } from 'element-ui'
//import Message from '@/components/tool/message';
import Vue from 'vue'
const vm = new Vue()
import {
  getTeacherRealName,
  getIsMainGroup,
  getParentsDetail,
} from "@/api/chat/index";
const parentLackInfo = {}, teacherLackInfo = {}, groupLackInfo = {}
const setConLackInfo = (conversationList) => {
  conversationList.forEach((item) => {
    if (!item.selfType) {
      item.selfType = ''
      item.selfId = ''
      item.selfNick = ''
      item.isMainGroup = 0
      const { type, userProfile } = item;
      if (type == "GROUP") {
        item.selfType = "group";
      } else if (type == "C2C") {
        let chatId = userProfile.userID;
        if (chatId.includes("_tea_")) {
          item.selfType = "teacher";
        } else if (chatId.includes("_par_")) {
          item.selfType = "parent";
        } else {
          item.selfType = "other";
        }
      }
    }
    if (item.selfType == "group" && groupLackInfo[item.selfId]) {
      item.isMainGroup = groupLackInfo[item.selfId]; // 0 非主群 1 主群
    } else if (
      item.selfType == "teacher" &&
      teacherLackInfo[item.selfId]
    ) {
      item.selfNick = teacherLackInfo[item.selfId];
    } else if (
      item.selfType == "parent" &&
      parentLackInfo[item.selfId]
    ) {
      item.selfNick = parentLackInfo[item.selfId];
    }
  });
  return conversationList
}
const dealLackInfo = async (conversationList) => {
  try {
    let groupList = [],
      teacherList = [],
      parentList = [];
    conversationList.forEach((item) => {
      const { type, userProfile, groupProfile } = item;
      if (type == "GROUP") {
        let chatId = groupProfile.groupID;
        item.selfType = "group";
        item.selfId = chatId;
        if (groupLackInfo[chatId] === undefined) {  // -1 为最初状态
          groupList.push(chatId);
        }
      } else if (type == "C2C") {
        let chatId = userProfile.userID;
        if (chatId.includes("_tea_")) {
          if (!teacherLackInfo[chatId]) {
            teacherList.push(chatId);
          }
          item.selfType = "teacher";
          item.selfId = chatId;
        } else if (chatId.includes("_par_")) {
          if (!parentLackInfo[chatId]) {
            parentList.push(chatId);
          }
          item.selfType = "parent";
          item.selfId = chatId;
        } else {
          item.selfType = "other";
        }
      }
    });

    if (groupList.length > 0) {
      const { rows: data } = await getIsMainGroup(groupList);
      data.forEach((item) => {
        groupLackInfo[item.groupId] = item.flag;
      });
    }
    if (teacherList.length > 0) {
      const { rows: data } = await getTeacherRealName(teacherList);
      data.forEach((item) => {
        if (item) {
          teacherLackInfo[
            item.teaChatId
          ] = `${item.realname}${item.subjectName}`;
        }
      });
    }
    if (parentList.length > 0) {
      const { rows: data } = await getParentsDetail(parentList);
      data.forEach((item) => {
        if (item) {
          parentLackInfo[item.parChatId] = `${item.name}家长`;
        }
      });
    }
    return setConLackInfo(conversationList);

  } catch (err) {
    console.error("dealLackInfo err: ", err);
  }
}
export const chatInfo = {
  namespaced: true,
  state: () => ({
    currentConversation: {},
    currentUserProfile: {},
    conversationList: [],
    currentMessageList: [],
    currentMemberList: [],
    nextReqMessageID: '',
    isCompleted: false,
    userID: 0,
    userSig: '',
    sdkAppID: 0,
    message: undefined,
    blacklist: [],
    isSDKReady: false, // TIM SDK 是否 ready
    clearConversationSearchKey: null,
    showWin: 'mainChat',    // 'mainChat'  'myRelation'
    groupList: [],
    current: Date.now(), // 当前时间
    chatToUserParams: {
      show: false,
      chatId: null,
      info: {},
    },
    curTeacherList: [],  //item => teaChatId
    curStudentList: [],
    chatShotShow: true,
    chatShotConListShow: false,
    chatShotConversationShow: false,
    hasChatMenu: false,
    breadList: '',
    isHidden: true,
  }),
  getters: {
    //教师列表未读消息数
    teacherManageUnreadCount: state => {
      let num = 0
      let teacherManage = location.href.includes('/expertTeachBusiness/teacherManage') && document.querySelector('.online_chat_window') && document.querySelector('.online_chat_window').style.display != 'none'
      state.conversationList.forEach(conItem => {
        if (conItem.type == 'C2C') {
          let chatId = conItem.conversationID.split("C2C")[1]
          state.curTeacherList.forEach(teaItem => {
            if (teaItem.teaChatId == chatId && !teacherManage) {
              num += conItem.unreadCount
            }
          })
        }
      })
      return num
    },
    //学生列表未读消息数
    studentManageUnreadCount: state => {
      let num = 0
      let studentManage = location.href.includes('/expertTeachBusiness/studentManage') && document.querySelector('.online_chat_window') && document.querySelector('.online_chat_window').style.display != 'none'
      state.conversationList.forEach(conItem => {
        if (conItem.type == 'C2C') {
          let chatId = conItem.conversationID.split("C2C")[1]
          state.curStudentList.forEach(stuItem => {
            if (stuItem.parChatId == chatId && !studentManage) {
              num += conItem.unreadCount
            }
          })
        }
      })
      return num
    },
    //消息中心未读消息数
    totalUnreadCount: state => {
      let messageCenter = location.href.includes('/expertTeachBusiness/messageCenter')
      let teacherManage = location.href.includes('/expertTeachBusiness/teacherManage') && document.querySelector('.online_chat_window') && document.querySelector('.online_chat_window').style.display != 'none'
      const result = state.conversationList.reduce((count, conversation) => {
        // 当前会话不计算总未读
        if (!state.isHidden && (messageCenter || teacherManage) && state.currentConversation.conversationID === conversation.conversationID) {
          tim.setMessageRead({ conversationID: conversation.conversationID })
          return count
        }
        return count + conversation.unreadCount
      }, 0)
      return result
    },
    toAccount: state => {
      if (!state.currentConversation || !state.currentConversation.conversationID) {
        return ''
      }
      switch (state.currentConversation.type) {
        case 'C2C':
          return state.currentConversation.conversationID.replace('C2C', '')
        case 'GROUP':
          return state.currentConversation.conversationID.replace('GROUP', '')
        default:
          return state.currentConversation.conversationID
      }
    },
    currentConversationType: state => {
      if (!state.currentConversation || !state.currentConversation.type) {
        return ''
      }
      return state.currentConversation.type
    },
    // 用于当前会话的图片预览
    imgUrlList: state => {
      return state.currentMessageList
        .filter(message => {
          // 分别处理默认的图片信息和从备课网盘发送的自定义文件消息
          if (message.type === TIM.TYPES.MSG_IMAGE) {
            return message.type === TIM.TYPES.MSG_IMAGE && !message.isRevoked && !message.isDeleted
          } else if (message.type === TIM.TYPES.MSG_CUSTOM && message.payload.data === 'prepare-data' && ['png', 'jpg', 'jpeg', 'gif'].includes(JSON.parse(message.payload.description).fileType.toLocaleLowerCase())) {
            return !message.isRevoked && !message.isDeleted
          }
        }) // 筛选出没有撤回并且类型是图片类型的消息
        .map(message => {
          if (message.type === TIM.TYPES.MSG_IMAGE) {
            return message.payload.imageInfoArray[0].url
          } else {
            let customData = JSON.parse(message.payload.description)
            return customData.fileUrl
          }
        })
    }
  },
  mutations: {
    updateCurTeacherList(state, curTeacherList) {
      state.curTeacherList = curTeacherList
    },
    updateCurStudentList(state, curStudentList) {
      state.curStudentList = curStudentList
    },
    updateGroupList(state, groupList) {
      state.groupList = groupList
    },
    deleteGroupMemberList(state, userIDList) {
      state.currentMemberList = state.currentMemberList.filter((member) => !userIDList.includes(member.userID))
    },
    updateCurrentConversation(state, conversation) {
      state.currentConversation = conversation
      state.currentMessageList = []
      state.nextReqMessageID = ''
      state.isCompleted = false
    },
    updateCurrentUserProfile(state, userProfile) {
      state.currentUserProfile = userProfile
    },
    async updateConversationList(state, conversationList) {
      let list = setConLackInfo(conversationList)
      state.conversationList = list
      state.conversationList = await dealLackInfo(list)
    },
    resetCurrentMemberList(state) {
      state.currentMemberList = []
    },
    updateCurrentMemberList(state, memberList) {
      state.currentMemberList = [...state.currentMemberList, ...memberList]
    },
    GET_USER_INFO(state, payload) {
      state.userID = payload.userID
      state.userSig = payload.userSig
      state.sdkAppID = payload.sdkAppID
    },
    /**
 * 重置当前会话
 * 调用时机：需要重置当前会话时，例如：当前会话是一个群组，正好被踢出群时（被踢群事件触发），重置当前会话
 * @param {Object} state
 */
    resetCurrentConversation(state) {
      state.currentConversation = {}
    },
    /**
     * 将消息插入当前会话列表
     * 调用时机：收/发消息事件触发时
     * @param {Object} state
     * @param {Message[]|Message} data
     * @returns
     */
    pushCurrentMessageList(state, data) {
      // 还没当前会话，则跳过
      if (!state.currentConversation.conversationID) {
        return
      }
      if (Array.isArray(data)) {
        // 筛选出当前会话的消息
        const result = data.filter(item => item.conversationID === state.currentConversation.conversationID)
        state.currentMessageList = [...state.currentMessageList, ...result]
        filterCallingMessage(state.currentMessageList)
      } else if (data.conversationID === state.currentConversation.conversationID) {
        state.currentMessageList = [...state.currentMessageList, data]
        filterCallingMessage(state.currentMessageList)
      }
    },
    delCurrentMessage(state, ID) {
      let index = -1
      state.currentMessageList.forEach((item, i) => {
        if (item.ID == ID) {
          index = i
        }
      })
      if (index != -1) {
        state.currentMessageList.splice(index, 1)
      }
    },
    /**
     * 从当前消息列表中删除某条消息
     * @param {Object} state
     * @param {Message} message
     */
    removeMessage(state, message) {
      const index = state.currentMessageList.findIndex(({ ID }) => ID === message.ID)
      if (index >= 0) {
        state.currentMessageList.splice(index, 1)
      }
    },
    reset(state) {
      Object.assign(state, {
        currentConversation: {},
        currentMessageList: [],
        nextReqMessageID: '',
        isCompleted: false, // 当前会话消息列表是否已经拉完了所有消息
        conversationList: []
      })
    },
    showMessage(state, options) {
      /* if (state.message) {
        state.message.close()
      }
      state.message = Message({
        message: options.message,
        type: options.type || 'success',
        duration: options.duration || 2000,
        offset: 40
      }) */
    },
    updateBlacklist(state, blacklist) {
      state.blacklist = blacklist
    },
    removeFromBlacklist(state, userID) {
      state.blacklist = state.blacklist.filter(item => item.userID !== userID)
    },
    toggleIsSDKReady(state, isSDKReady) {
      state.isSDKReady = typeof isSDKReady === 'undefined' ? !state.isSDKReady : isSDKReady
    },
    clearConversationSearchKeyChange(state, clearConversationSearchKey) {
      state.clearConversationSearchKey = clearConversationSearchKey
    },
    showWinChange(state, showWin) {
      state.showWin = showWin
    },
    chatBoxShowChange(state, chatToUserParams) {
      state.chatToUserParams = { ...state.chatToUserParams, ...chatToUserParams }
    },
    chatShotShowChange(state, chatShotShow) {
      state.chatShotShow = chatShotShow
    },
    chatShotConversationShowChange(state, chatShotConversationShow) {
      state.chatShotConversationShow = chatShotConversationShow
    },
    chatShotConListShowChange(state, chatShotConListShow) {
      state.chatShotConListShow = chatShotConListShow
    },
    hasChatMenuChange(state, hasChatMenu) {
      state.hasChatMenu = hasChatMenu
    },
    breadListChange(state, breadList) {
      state.breadList = breadList
    },
    isHiddenChange(state, isHidden) {
      state.isHidden = isHidden
    },
  },
  actions: {
    /**
     * 获取消息列表
     * 调用时机：打开某一会话时或下拉获取历史消息时
     * @param {Object} context
     * @param {String} conversationID
     */
    getMessageList(context, conversationID) {
      if (context.state.isCompleted) {
        context.commit('showMessage', {
          message: '已经没有更多的历史消息了哦',
          type: 'info'
        })
        return
      }
      const { nextReqMessageID, currentMessageList } = context.state
      tim.getMessageList({ conversationID, nextReqMessageID, count: 15 }).then(imReponse => {
        // 更新messageID，续拉时要用到
        context.state.nextReqMessageID = imReponse.data.nextReqMessageID
        context.state.isCompleted = imReponse.data.isCompleted
        if (imReponse.data.messageList.length < 15) {
          context.state.isCompleted = true
        }
        // 更新当前消息列表，从头部插入
        context.state.currentMessageList = [...imReponse.data.messageList, ...currentMessageList].filter(message => {
          return !message.isRevoked && !message.isDeleted
        })
        filterCallingMessage(context.state.currentMessageList)

      })
    },
    /**
     * 切换会话
     * 调用时机：切换会话时
     * @param {Object} context
     * @param {String} conversationID
     */
    checkoutConversation(context, conversationID) {
      context.commit('resetCurrentMemberList')
      // 1.切换会话前，将切换前的会话进行已读上报
      if (context.state.currentConversation.conversationID && location.href.includes('/expertTeachBusiness/messageCenter')) {
        const prevConversationID = context.state.currentConversation.conversationID
        tim.setMessageRead({ conversationID: prevConversationID })
      }
      // 2.待切换的会话也进行已读上报
      tim.setMessageRead({ conversationID })
      // 3. 获取会话信息
      return tim.getConversationProfile(conversationID).then(({ data }) => {
        // 3.1 更新当前会话
        context.commit('updateCurrentConversation', data.conversation)
        // 3.2 获取消息列表
        context.dispatch('getMessageList', conversationID)
        // 3.3 拉取第一页群成员列表
        if (data.conversation.type === TIM.TYPES.CONV_GROUP) {
          return context.dispatch('getGroupMemberList', data.conversation.groupProfile.groupID)
        }
        return Promise.resolve()
      }).catch(imError => {
        console.warn('getConversationProfile error:', imError);
        vm.msgWarn(imError.message)
      })
    },
    getGroupMemberList(context, groupID) {
      return tim.getGroupMemberList({
        groupID: groupID,
        offset: context.state.currentMemberList.length,
        count: 30
      }).then((imResponse) => {
        context.commit('updateCurrentMemberList', imResponse.data.memberList)
        return imResponse
      })
    },
    getBlacklist(context) {
      tim
        .getBlacklist()
        .then(({ data }) => {
          if (data.length > 0) {
            tim.getUserProfile({ userIDList: data })
              .then(({ data }) => {
                context.commit('updateBlacklist', data)
              })
          }
        })
    },
  },

}