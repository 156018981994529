<template>
  <div class="dialog_mask" v-show="studentListParams.show">
    <div class="stu_list_dialog" v-show="step == 1">
      <div class="title">学生列表</div>
      <span
        class="iconfont icon-error icon_close"
        @click="closeListClick"
      ></span>
      <div class="list_content">
        <div class="student_list" v-show="listInfo.list.length > 0">
          <div class="student_item">
            <span>学生姓名</span>
            <span>学生年级</span>
            <span>学生性别</span>
            <span>所在学校</span>
            <!-- <span>操作</span> -->
          </div>
          <div
            class="student_item"
            v-for="(item, index) in listInfo.list"
            :key="index"
          >
            <span :title="item.name">{{ item.name }}</span>
            <span>{{ item.sectionName ? item.sectionName : "未知" }}</span>
            <span>{{
              item.sex == 1 ? "男" : item.sex == 2 ? "女" : "未知"
            }}</span>
            <span :title="item.schoolName ? item.schoolName : '暂无'">{{
              item.schoolName ? item.schoolName : "暂无"
            }}</span>
            <!-- <span class="item_opt">
              <span class="btn" @click="singleStudentClick(item)"
                >查看详情</span
              >
            </span> -->
          </div>
        </div>
        <div class="no_data" v-show="listInfo.list.length == 0">
          <img
            class="no_data_img"
            :src="require('@/assets/image/chat/no_data_2.jpg')"
            alt=""
          />
          <span class="txt">暂无学生</span>
        </div>
      </div>
      <div class="dialog_bottom">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="listInfo.total"
          prev-text="上一页"
          next-text="下一页"
          size="small"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <div class="stu_detail_dialog" v-show="step == 2">
      <div class="title">学生详情</div>
      <span
        class="iconfont icon-error icon_close"
        @click="step2CloseClick"
      ></span>
      <div class="content_detail">
        <div class="row_1">
          <div class="row_1_item">
            <img
              class="item_img"
              :src="
                stuDetail.photo
                  ? stuDetail.photo
                  : require('@/assets/image/chat/default_avatar.png')
              "
              alt=""
            />
            <span class="name_sex">
              <span class="stu_name" :title="stuDetail.name">{{
                stuDetail.name
              }}</span>
              <span
                class="iconfont icon-nan1 icon_sex icon_sex_man"
                v-show="stuDetail.sex == 1"
              ></span>
              <span
                class="iconfont icon-nv1 icon_sex icon_sex_women"
                v-show="stuDetail.sex == 2"
              ></span>
            </span>
          </div>
          <div class="row_1_item">
            <span class="item_label">出生日期</span>
            <span class="item_value">{{
              stuDetail.birthday ? stuDetail.birthday : "暂无"
            }}</span>
          </div>
          <div class="row_1_item">
            <span class="item_label">就读学校</span>
            <span class="item_value">{{
              stuDetail.schoolName ? stuDetail.schoolName : "暂无"
            }}</span>
          </div>
          <div class="row_1_item">
            <span class="item_label">年级</span>
            <span class="item_value">{{
              stuDetail.sectionName ? stuDetail.sectionName : "暂无"
            }}</span>
          </div>
          <div class="row_1_item">
            <span class="item_label">星座</span>
            <span class="item_value">{{
              stuDetail.constellationName ? stuDetail.constellationName : "暂无"
            }}</span>
          </div>
        </div>
        <div class="row_2">
          <div class="sub_title">
            <img :src="require('@/assets/image/chat/nature.png')" alt="" />
            <span>孩子性格</span>
          </div>
          <div class="children_nature">
            {{ stuDetail.childNature ? stuDetail.childNature : "暂无" }}
          </div>
        </div>
        <div class="row_3">
          <div class="sub_title">
            <img :src="require('@/assets/image/chat/score.png')" alt="" />
            <span>学生成绩</span>
          </div>
          <div
            class="score_list"
            v-if="stuDetail.studentAcademicRecordList.length > 0"
          >
            <div
              class="score_item"
              v-for="(item, index) in stuDetail.studentAcademicRecordList"
              :key="index"
            >
              <span class="item_time">{{ item.examinationTime }}</span>
              <span class="item_type">{{
                getScoreType(item.examinationType)
              }}</span>
              <span class="item_score"
                >{{ item.subjectScore }} / {{ item.subjectAllScore }}</span
              >
              <span class="item_remarks" :title="item.remarks">{{
                item.remarks
              }}</span>
              <span class="item_opt">
                <span class="btn" @click="singlePaperPreviewClick(item)"
                  >预览</span
                >
              </span>
            </div>
          </div>
          <div class="no_data" v-else>暂无成绩数据</div>
        </div>
        <div class="row_4">
          <div class="sub_title">
            <img :src="require('@/assets/image/chat/course.png')" alt="" />
            <span>排课教务</span>
          </div>
          <div class="edu_course">
            {{
              stuDetail.teacherManagerName
                ? stuDetail.teacherManagerName
                : "暂无"
            }}
          </div>
        </div>
        <div class="row_5">
          <span class="btn" @click="handleGroupClick">进入交流群</span>
        </div>
      </div>
    </div>
    <div class="paper_preview_dialog" v-show="step == 3">
      <div class="title">试卷预览</div>
      <span class="iconfont icon-error icon_close" @click="step = 2"></span>
      <div class="preview_content">
        <div class="c_row">
          <span class="c_label">考试时间：</span>
          <span class="c_value">{{ previewDetail.examinationTime }}</span>
        </div>
        <div class="c_row">
          <span class="c_label">考试类型：</span>
          <span class="c_value">{{
            getScoreType(previewDetail.examinationType)
          }}</span>
        </div>
        <div class="c_row">
          <span class="c_label">成绩/总分：</span>
          <span class="c_value"
            >{{ previewDetail.subjectScore }} /
            {{ previewDetail.subjectAllScore }}</span
          >
        </div>
        <div class="c_row">
          <span class="c_label">备注说明：</span>
          <span class="c_value">{{ previewDetail.remarks }}</span>
        </div>
        <div class="c_title">试卷：</div>
        <div class="img_list" v-show="previewDetail.imgList.length > 0">
          <div
            class="prev_img img_btn"
            :class="{
              img_btn_disable: curStep == 0,
              img_btn_able: curStep != 0,
            }"
            @click="moveImgListClick('prev')"
          >
            <span class="iconfont icon-zuojiantou"></span>
          </div>
          <div
            class="next_img img_btn"
            :class="{
              img_btn_disable: curStep == maxStep,
              img_btn_able: curStep != maxStep,
            }"
            @click="moveImgListClick('next')"
          >
            <span class="iconfont icon-youjiantou"></span>
          </div>
          <ul class="img_ul" ref="imgUl" :style="[imgUlStyle]">
            <li
              class="img_item img_item_true"
              v-for="(item, index) in previewDetail.imgList"
              :key="index"
            >
              <div class="img_hover">
                <span
                  class="iconfont icon-yulan1 icon_preview"
                  title="预览"
                  @click="imgItemSeeClick(item)"
                ></span>
              </div>
              <img :src="item" alt="" />
              <span class="img_mask"></span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapMutations } from "vuex";
import { getStudentDetail, getStudentPage } from "@/api/chat";
export default {
  data() {
    return {
      step: 1, // 1,显示list   2,显示学生个人的信息   3,个人信息中单个试卷的预览
      listParams: {
        pageNo: 1,
        pageSize: 10,
      },
      listInfo: {
        total: 1,
        list: [],
      },
      showStuDialog: false,
      stuDetail: {
        studentAcademicRecordList: [],
      },
      showPaperPreviewDialog: false,
      previewDetail: {
        imgList: [],
      },
      maxStep: 0,
      curStep: 0,
      imgUrlWidth: "",
      imgListWidth: 460,
      imgUlStyle: {
        left: "0px",
      },
    };
  },
  props: ["studentListParams"],
  watch: {
    "studentListParams.show"(show) {
      if (show) {
        this.step = this.studentListParams.step;
        if (this.step == 1) {
          this.getEduAndTeacherStudentList();
        } else if (this.step == 2) {
          this.seeStudentDetail({ id: this.studentListParams.studentId });
        }
      }
    },
  },
  components: {},
  computed: {},
  mounted() {},
  methods: {
    ...mapActions("chatInfo", ["checkoutConversation"]),
    ...mapMutations("chatInfo", ["showWinChange"]),

    handleGroupClick() {
      if (!this.stuDetail.groupId) {
        const conversationID = `GROUP${this.stuDetail.groupId}`;
        this.showWinChange("mainChat");
        this.checkoutConversation(conversationID);
        this.$emit("studentListParamsChange");
      }else{
        this.msgWarn('还未创建群聊，请联系教务创建！')
      }
    },
    step2CloseClick() {
      if (this.studentListParams.step == 1) {
        this.step = 1;
      } else if (this.studentListParams.step == 2) {
        this.closeListClick();
      }
    },
    closeListClick() {
      this.$emit("studentListParamsChange");
    },
    singleStudentClick(item) {
      this.step = 2;
      this.seeStudentDetail(item);
    },
    getEduAndTeacherStudentList() {
      getStudentPage({
        teacherId: this.studentListParams.teacherId,
        ...this.listParams,
      }).then((res) => {
        this.listInfo.list = res.msg.rows;
        this.listInfo.total = res.msg.total;
        console.log(res);
      });
    },
    seeStudentDetail(item) {
      getStudentDetail({
        studentId: item.id,
        teacherManagerId: this.studentListParams.eduId,
      }).then((res) => {
        this.stuDetail = res.msg;
      });
    },
    getScoreType(examinationType) {
      switch (Number(examinationType)) {
        case 1:
          return "模拟考试";
        case 2:
          return "期中";
        case 3:
          return "期末";
        case 4:
          return "月考";
        case 5:
          return "随堂练";
      }
    },
    singlePaperPreviewClick(item) {
      this.step = 3;
      item.imgList = item.testPapers ? item.testPapers.split(",") : [];
      this.previewDetail = item;
      this.$nextTick(() => {
        this.imgUrlWidth = this.$refs.imgUl.getBoundingClientRect().width;
        console.log(this.imgUrlWidth);
        if (this.imgUrlWidth > this.imgListWidth) {
          this.maxStep = Math.ceil(
            (this.imgUrlWidth - this.imgListWidth) / 120
          );
        }
      });
    },
    moveImgListClick(type) {
      if (this.curStep != 0 && type == "prev") {
        this.curStep--;
      } else if (this.curStep != this.maxStep && type == "next") {
        this.curStep++;
      }
      this.imgUlStyle.left = `-${this.curStep * 140}px`;
    },
    handleCurrentChange(page) {
      this.listParams.pageNo = page;
      this.getEduAndTeacherStudentList();
    },
  },
};
</script>
<style lang='scss' scoped>
.dialog_mask {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: fixed;
  background-color: rgba($color: #000000, $alpha: 0.5);
  z-index: 1000;
  .no_data {
    @include no_data;
  }
  .stu_list_dialog {
    width: 600px;
    padding: 16px 20px 20px;
    min-height: 500px;
    max-height: 80%;
    border: 1px solid #e0e0e0;
    overflow-y: auto;
    position: absolute;
    background-color: #ffffff;
    border-radius: 6px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    z-index: 20;
    .title {
      line-height: 30px;
      font-size: 16px;
      font-weight: bolder;
      text-align: center;
    }
    .icon_close {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
    .list_content {
      flex: 1;
      overflow-y: auto;
      .student_item {
        display: flex;
        > span {
          flex: 1;
          width: 0;
          line-height: 40px;
          border-bottom: 1px solid #eeeeee;
          text-align: center;
          @include ellipsis;
        }
        .item_opt {
          .btn {
            color: #024df8;
            cursor: pointer;
          }
        }
      }
    }
    .dialog_bottom {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .paper_preview_dialog {
    width: 600px;
    padding: 16px 20px 20px;
    min-height: 300px;
    max-height: 80%;
    border: 1px solid #e0e0e0;
    overflow-y: auto;
    position: absolute;
    background-color: #ffffff;
    border-radius: 6px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
    .title {
      line-height: 30px;
      font-size: 16px;
      font-weight: bolder;
      text-align: center;
    }
    .icon_close {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
    .preview_content {
      .c_row {
        margin-top: 20px;
        display: flex;
        padding: 4px 0;
        .c_label {
          line-height: 20px;
          color: #666666;
          margin-right: 10px;
          width: 76px;
        }
        .c_value {
          flex: 1;
          line-height: 20px;
          color: #222222;
        }
      }
    }
    .c_title {
      margin-top: 20px;
      line-height: 20px;
      color: #666666;
    }
    .img_list {
      margin-top: 20px;
      width: 100%;
      height: 120px;
      position: relative;
      overflow: hidden;
      .img_btn {
        width: 30px;
        height: 30px;
        border-radius: 30px;
        background-color: #ffffff;
        border: 1px solid #dddddd;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        line-height: 30px;
        cursor: pointer;
        z-index: 10;
        .iconfont {
          color: #aaaaaa;
        }
      }
      .img_btn_able {
        &:hover {
          .iconfont {
            color: #024df8;
          }
        }
      }
      .img_btn_disable {
        cursor: default;
        background-color: #dddddd;
      }
      .prev_img {
        left: 0px;
      }
      .next_img {
        right: 0px;
      }
      .img_ul {
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        .img_item {
          position: relative;
          margin: 0 10px;
          cursor: pointer;
          height: 120px;
          width: 120px;
          border: 1px solid #dddddd;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          &:nth-of-type(1) {
            margin-left: 0;
          }
          &:nth-last-of-type(1) {
            margin-right: 0;
          }
          .img_hover {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: none;
            z-index: 6;
            align-items: center;
            justify-content: center;
            background-color: rgba($color: #000000, $alpha: 0.7);
            .icon_del {
              margin-left: 10px;
              cursor: pointer;
              color: #ffffff;
            }
            .icon_preview {
              cursor: pointer;
              color: #ffffff;
              font-size: 14px;
            }
          }
          &:hover {
            .img_hover {
              display: flex;
            }
            .img_mask {
              display: none;
            }
          }
          img {
            width: 120px;
            height: 120px;
          }
          .img_mask {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 2;
            background-color: rgba($color: #000000, $alpha: 0.3);
          }
          .icon_new {
            font-size: 40px;
          }
        }
      }
    }
  }
  .stu_detail_dialog {
    width: 600px;
    padding: 16px 20px 20px;
    min-height: 300px;
    max-height: 80%;
    border: 1px solid #e0e0e0;
    overflow-y: auto;
    position: absolute;
    background-color: #ffffff;
    border-radius: 6px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    .title {
      line-height: 30px;
      font-size: 16px;
      font-weight: bolder;
      text-align: center;
    }
    .icon_close {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
    .content_detail {
      .row_1 {
        width: 100%;
        display: flex;
        align-items: center;
        .row_1_item {
          flex: 1;
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          .item_img {
            width: 66px;
            height: 66px;
            border-radius: 66px;
          }
          .name_sex {
            margin-top: 10px;
            .stu_name {
              display: inline-block;
              max-width: 80px;
              @include ellipsis;
              margin-right: 6px;
            }
            .icon_sex_women {
              font-size: 14px;
              color: #e81123;
            }
            .icon_sex_man {
              font-size: 14px;
              color: #024df8;
            }
          }
          .item_label {
            color: #aaaaaa;
          }
          .item_value {
            margin-top: 16px;
            color: #222222;
          }
        }
      }
      .sub_title {
        height: 40px;
        display: flex;
        align-items: center;
        img {
          width: 20px;
          height: 20px;
        }
        span {
          margin-left: 10px;
        }
      }
      .row_2 {
        .children_nature {
          line-height: 20px;
          color: #666666;
        }
      }
      .row_3 {
        .score_item {
          height: 40px;
          display: flex;
          color: #666666;
          align-items: center;
          > span {
            padding: 0 4px;
            width: 0;
            @include ellipsis;
          }
          .item_remarks {
            flex: 1;
            width: 0;
          }
          .item_time {
            width: 140px;
          }
          .item_type {
            width: 60px;
          }
          .item_score {
            width: 88px;
          }
          .item_opt {
            width: 60px;
            .btn {
              display: inline-block;
              font-size: 12px;
              cursor: pointer;
              padding: 4px 10px;
              background: #ffffff;
              border: 1px solid #dddddd;
              border-radius: 15px;
              &:hover {
                color: #024df8;
                border-color: #024df8;
              }
            }
          }
        }
        .no_data {
          line-height: 20px;
          color: #666666;
        }
      }
      .row_4 {
        .edu_course {
          line-height: 20px;
          color: #666666;
        }
      }
      .row_5 {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        .btn {
          color: #ffffff;
          cursor: pointer;
          border-radius: 6px;
          padding: 10px 18px;
          background-color: #024df8;
        }
      }
    }
  }
}
</style>