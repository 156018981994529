<template>
  <message-bubble :isMine="isMine" :message="message">
    <template v-slot:default="slotProps">
      <img
        class="image-element"
        :src="imageUrl"
        @load="onImageLoaded"
        @click.stop="handlePreview"
        draggable="false"
      />
      <el-progress
        v-if="showProgressBar"
        :percentage="percentage"
        :color="(percentage) => (percentage === 100 ? '#67c23a' : '#409eff')"
      />
      <el-progress
        v-if="slotProps.downData.status == 1"
        :percentage="slotProps.downData.process"
        :color="(percentage) => (percentage === 100 ? '#67c23a' : '#409eff')"
      />
    </template>
  </message-bubble>
</template>

<script>
import MessageBubble from "../message-bubble";
import { Progress } from "element-ui";
import { mapGetters } from "vuex";
export default {
  name: "ImageElemnt",
  props: {
    payload: {
      type: Object,
      required: true,
    },
    message: {
      type: Object,
      required: true,
    },
    isMine: {
      type: Boolean,
    },
  },
  components: {
    MessageBubble,
    ElProgress: Progress,
  },
  computed: {
    ...mapGetters(["imgUrlList"]),
    imageUrl() {
      const url = this.payload.imageInfoArray[0].url;
      if (typeof url !== "string") {
        return "";
      }
      return url.slice(0, 2) === "//" ? `https:${url}` : url;
    },
    showProgressBar() {
      return this.$parent.message.status === "unSend";
    },
    percentage() {
      return Math.floor((this.$parent.message.progress || 0) * 100);
    },
  },
  methods: {
    onImageLoaded(event) {
      this.$bus.$emit("image-loaded", event);
    },
    handlePreview() {
      this.$bus.$emit("image-preview", {
        url: this.payload.imageInfoArray[0].url,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.image-element {
  max-width: 250px;
  cursor: zoom-in;
}
</style>
