<template>
  <div class="avatar" :class="shape === 'circle' ? 'shape-circle' : ''">
    <ul>
      <li class="" v-for="(item, index) in avatarList" :key="index">
        <img :src="item ? item : defaultSrc" alt="" />
      </li>
    </ul>
  </div>
</template>

<script>
import userAvatar from "@/assets/image/chat/default_avatar.png";
import { mapState } from "vuex";
export default {
  data() {
    return {
      avatarList: [],
    };
  },
  inject: ["timChat"],
  props: {
    shape: {
      type: String,
      default: "circle",
    },
    groupID: String,
    memberCount: Number | String,
  },
  computed: {
    ...mapState("chatInfo", ["isSDKReady"]),
    defaultSrc: function () {
      return userAvatar;
    },
  },
  watch: {
    memberCount() {
      this.getAvatarList()
    },
  },
  methods: {
    getAvatarList() {
      if (this.isSDKReady) {
        this.timChat.tim
          .getGroupMemberList({
            groupID: this.groupID,
            offset: 0,
            count: 9,
          })
          .then((imResponse) => {
            this.avatarList = [];
            imResponse.data.memberList.forEach((item) => {
              if (item.avatar) {
                this.avatarList.push(item.avatar);
              } else {
                this.avatarList.push("");
              }
            });
          });
      }
    },
  },
  mounted() {
    this.getAvatarList()
  },
};
</script>

<style lang="scss" scoped>
.avatar {
  text-align: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  padding: 1px;
  ul {
    width: 100%;
    height: 100%;
    background-color: #eee;
    li {
      img {
        width: 100%;
        height: 100%;
        outline: 1px solid #fff;
      }
    }
    /* 1个 */
    li:only-child {
      height: 100%;
      width: 100%;
    }

    /* 2个 */
    li:first-child:nth-last-child(2),
    li:first-child:nth-last-child(2) + li {
      margin-top: 25%;
      width: 50%;
      height: 50%;
      float: left;
    }

    /* 3个 */
    li:first-child:nth-last-child(3),
    li:first-child:nth-last-child(3) ~ li {
      width: 50%;
      height: 50%;
    }

    li:first-child:nth-last-child(3) {
      margin: auto;
    }

    li:first-child:nth-last-child(3) ~ li {
      float: left;
    }

    /* 4个 */
    li:first-child:nth-last-child(4),
    li:first-child:nth-last-child(4) ~ li {
      width: 50%;
      height: 50%;
      float: left;
    }

    /* 5个和6个 */
    li:first-child:nth-last-child(5) {
      margin-left: 17%;
      margin-top: 17%;
    }

    li:first-child:nth-last-child(5) + li {
      margin-top: 17%;
    }

    li:first-child:nth-last-child(6),
    li:first-child:nth-last-child(6) + li,
    li:first-child:nth-last-child(6) + li + li {
      margin-top: 17%;
    }

    li:first-child:nth-last-child(5),
    li:first-child:nth-last-child(6) {
      width: 33.3%;
      height: 33.3%;
      float: left;
    }

    li:first-child:nth-last-child(5) ~ li,
    li:first-child:nth-last-child(6) ~ li {
      width: 33.3%;
      height: 33.3%;
      float: left;
    }

    /* 7个 8个 9个 */
    li:first-child:nth-last-child(7),
    li:first-child:nth-last-child(8),
    li:first-child:nth-last-child(9),
    li:first-child:nth-last-child(7) ~ li,
    li:first-child:nth-last-child(8) ~ li,
    li:first-child:nth-last-child(9) ~ li {
      width: 33.3%;
      height: 33.3%;
      float: left;
    }

    li:first-child:nth-last-child(7) {
      float: none;
      margin: auto;
    }

    li:first-child:nth-last-child(8) {
      margin-left: 20%;
    }
  }
}

.shape-circle {
  border-radius: 6px;
}
</style>
