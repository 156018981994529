<template>
  <div class="dialog_con" v-show="chatShotShow && hasChatMenu">
    <div
      class="chat_btn"
      :title="chatShotConListShow ? '关闭会话列表' : '打开会话列表'"
      @click="handleShotBtnClick"
    >
      <span class="chat_icon_c">
        <span class="unread_msg" v-show="totalUnreadCount > 0">{{
          totalUnreadCount > 99 ? "99+" : totalUnreadCount
        }}</span>
        <img
          class="chat_icon"
          :src="require('@/assets/image/chat/chat_icon.png')"
          alt=""
        />
      </span>
      <span>在</span>
      <span>线</span>
      <span>沟</span>
      <span>通</span>
    </div>
    <div
      class="cur_conversation"
      v-show="chatShotConversationShow && chatShotConListShow"
    >
      <current-conversation :dialogChat="dialogChat"></current-conversation>
    </div>
    <div class="conversation_list" v-show="chatShotConListShow">
      <conversation-item
        :conversation="item"
        :dialogCon="dialogCon"
        v-for="item in conversationList"
        :key="item.conversationID"
      />
      <div class="no-conversation" v-show="conversationList.length == 0">
        暂无会话
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters, mapMutations } from "vuex";
import conversationItem from "../conversation/conversation-item";
import currentConversation from "../conversation/current-conversation";
export default {
  data() {
    return {
      dialogCon: true,
      dialogChat: true,
    };
  },
  inject: ["timChat"],
  components: {
    conversationItem,
    currentConversation,
  },
  computed: {
    ...mapState("chatInfo", [
      "conversationList",
      "chatShotShow",
      "hasChatMenu",
      "chatShotConversationShow",
      "chatShotConListShow",
    ]),
    ...mapGetters("chatInfo", ["totalUnreadCount"]),
  },
  mounted() {},
  methods: {
    ...mapMutations("chatInfo", [
      "chatShotConversationShowChange",
      "chatShotConListShowChange",
    ]),
    handleShotBtnClick() {
      let flag = !this.chatShotConListShow;
      this.chatShotConListShowChange(flag);
      if (!flag) {
        this.chatShotConversationShowChange(false);
      }
    },
  },
};
</script>
<style lang='scss' scoped>
.dialog_con {
  position: fixed;
  right: 0;
  top: calc((100vh - 660px) / 2);
  z-index: 100;
  display: flex;
  align-items: center;
  transition: width 0.5;
  height: 660px;
  .chat_btn {
    width: 40px;
    display: flex;
    flex-direction: column;
    background-color: #606266;
    background-color: #909399;
    color: #ffffff;
    cursor: pointer;
    justify-content: space-evenly;
    align-items: center;
    height: 160px;
    padding: 10px 0;
    .chat_icon_c {
      position: relative;
      .unread_msg {
        position: absolute;
        background-color: red;
        color: #ffffff;
        border-radius: 20px;
        right: -2px;
        top: -6px;
        font-size: 10px;
      }
    }
    &:hover {
      background-color: #606266;
    }
    .chat_icon {
      width: 30px;
    }
  }
  .cur_conversation {
    width: 600px;
    height: 660px;
    background-color: #ffffff;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.38);
  }
  .conversation_list {
    padding: 6px 0;
    background-color: #ffffff;
    overflow-y: auto;
    height: 660px;
    width: 260px;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.38);
    .no-conversation {
      line-height: 60px;
      text-align: center;
      color: #909399;
    }
  }
}
</style>