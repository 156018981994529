<template>
  <div class="current-conversation-wrapper" @click="currentConversationClick">
    <div
      class="current-conversation"
      @scroll="onScroll"
      v-if="showCurrentConversation"
    >
      <div class="header" v-show="!chatToUserParams.show">
        <div class="name">
          <span> {{ name }}</span>
          <span v-show="memberCount">({{ memberCount }}人)</span>
          <span v-show="currentConversation.isMainGroup == 1">(主群)</span>
        </div>
        <div
          class="teacher_info"
          v-if="
            currentConversation.selfType == 'teacher' && teacherInfo.sexName
          "
        >
          <span class="info_label">{{ teacherInfo.sexName }}</span>
          <span class="h_line">|</span>
          <span class="info_label">{{ teacherInfo.age }}</span>
          <span class="h_line">|</span>
          <span class="info_label">{{ teacherInfo.identityName }}</span>
          <span class="h_line">|</span>
          <span class="info_label">{{ teacherInfo.subjectName }}</span>
          <span class="h_line">|</span>
          <span class="info_label">{{ teacherInfo.periodName }}</span>
        </div>
        <div
          class="teacher_info"
          v-if="
            currentConversation.selfType == 'parent' && studentInfo.sexName
          "
        >
          <span class="info_label">{{ studentInfo.name }}</span>
          <span class="h_line">|</span>
          <span class="info_label">{{ studentInfo.sexName }}</span>
          <span class="h_line">|</span>
          <span class="info_label">{{ studentInfo.sectionName }}</span>
          <span class="h_line">|</span>
          <span class="info_label">{{ studentInfo.studentState }}</span>
        </div>
        <span
          v-show="dialogChat"
          class="dialog_close iconfont icon-guanbi3"
          title="关闭"
          @click="chatShotConversationShowChange(false)"
        ></span>
        <span
          class="btn-more-info iconfont icon-gengduo2"
          :class="showConversationProfile ? 'profile-active' : ''"
          @click="showMore"
          v-show="!dialogChat && currentConversation.type == 'GROUP'"
          title="查看详细信息"
        ></span>
      </div>
      <div class="content">
        <div class="message-list" ref="message-list" @scroll="this.onScroll">
          <div class="more" v-if="!isCompleted">
            <el-button
              type="text"
              @click="getMessageList(currentConversation.conversationID)"
              >查看更多</el-button
            >
          </div>
          <div class="no-more" v-else>没有更多了</div>
          <message-item
            v-for="message in currentMessageList"
            :key="message.ID"
            :message="message"
          />
        </div>
        <div
          v-show="isShowScrollButtomTips"
          class="newMessageTips"
          @click="scrollMessageListToButtom"
        >
          回到最新位置
        </div>
      </div>
      <div
        class="message_tool"
        v-if="
          showMessageSendBox && (groupType === 'main' || C2CStuPar || C2CTea)
        "
      >
        <div class="course_btn_area">
          <div
            class="course_btn"
            @click="chatBtnClick('student-class-require')"
            v-show="C2CStuPar && !dialogChat"
          >
            <span class="iconfont icon-xuqiu1 icon_require"></span>
            <span>新课需求</span>
          </div>
          <div
            class="course_btn"
            @click="chatBtnClick('student-list')"
            v-show="C2CTea && !dialogChat"
          >
            <span class="iconfont icon-xueshengsvg icon_problem"></span>
            <span>学生信息</span>
          </div>
          <div
            class="course_btn"
            @click="chatBtnClick('student-question')"
            v-show="groupType === 'main'"
          >
            <span class="iconfont icon-wenti-copy icon_problem"></span>
            <span>学生问题</span>
          </div>
          <div
            class="course_btn"
            @click="chatBtnClick('student-homework')"
            v-show="groupType === 'main'"
          >
            <span class="iconfont icon-wenti-copy icon_problem"></span>
            <span>学生作业</span>
          </div>
        </div>
      </div>
      <div class="footer" v-if="showMessageSendBox">
        <message-send-box :dialogChat="dialogChat" />
      </div>
    </div>
    <div class="profile" v-if="showConversationProfile">
      <conversation-profile />
    </div>
    <!-- 群成员资料组件 -->
    <member-profile-card />
    <chat-student-info
      :studentParams="studentParams"
      @studentParamsChange="studentParamsChange"
    ></chat-student-info>
    <chat-student-question
      :studentQuestionParams="studentQuestionParams"
      @studentQuestionParamsChange="studentQuestionParamsChange"
    ></chat-student-question>
    <chat-student-list
      :studentListParams="studentListParams"
      @studentListParamsChange="studentListParamsChange"
    ></chat-student-list>
    <student-homework-list
      :studentHomeworkListParams="studentHomeworkListParams"
      @studentHomeworkListParamsChange="studentHomeworkListParamsChange"
    ></student-homework-list>
    <file-view></file-view>
    <el-dialog
      title="选择学科"
      :visible.sync="dialogSubjectSelect"
      width="50%"
      center
    >
      <div class="subject_select">
        <div class="subject_row">
          <span class="s_label">文化课：</span>
          <span class="s_btn_area">
            <span
              class="s_btn"
              v-for="item in baseSubject"
              :key="item.macroId"
              :class="{ s_btn_active: selectSubjectId == item.macroId }"
              @click="sBtnClick(item)"
              >{{ item.name }}</span
            >
          </span>
        </div>
        <div class="subject_row">
          <span class="s_label">小语种：</span>
          <span class="s_btn_area">
            <span
              class="s_btn"
              v-for="item in languageSubject"
              :key="item.macroId"
              :class="{ s_btn_active: selectSubjectId == item.macroId }"
              @click="sBtnClick(item)"
              >{{ item.name }}</span
            >
          </span>
        </div>
        <div class="subject_row">
          <span class="s_label">艺术课：</span>
          <span class="s_btn_area">
            <span
              class="s_btn"
              v-for="item in artSubject"
              :key="item.macroId"
              :class="{ s_btn_active: selectSubjectId == item.macroId }"
              @click="sBtnClick(item)"
              >{{ item.name }}</span
            >
          </span>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogSubjectSelect = false"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="confirmSelectSubject"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getSelectList, subjectCode } from "@/api/public";
import {
  getAllSubject,
  getNewCourseNeedsBySubjectIdAndStudentId,
  getTeacherDetail,
  getParentsDetail,
} from "@/api/chat";
const sexObj = {
  '0': "性别未知",
  '1': "男",
  '2': "女",
};
const getSexName = (key) => {
  if (!key) {
    return "性别未知";
  }
  return sexObj[String(key)];
};
const titleObj = {
  '59': "初级教师",
  '60': "中级教师",
  '61': "高级教师",
  '62': "特级教师",
};
const getTitleName = (key) => {
  if (!key) {
    return "职级未知";
  }
  return titleObj[String(key)];
};
const identityObj = {
  '1': "学科类-老师",
  '2': "学科类-在校大学生",
  '3': "素质教育类-老师",
  '4': "素质教育类-在校大学生",
};
const getIdentityName = (key) => {
  if (!key) {
    return "暂无认证信息";
  }
  return identityObj[String(key)];
};
const periodObj = {
  '107': "高中",
  '106': "初中",
  '105': "小学",
  '108': "艺术培训",
  '109': "小语种",
  '235': "全学段",
};
const getPeriodName = (str) => {
  if (!str) {
    return "学段未知";
  }
  let list = str.split(","),
    res = "";
  list.forEach((item) => {
    res += periodObj[String(item)] + ",";
  });
  return res.substring(0, res.length - 1);
};
const getSubjectName = async (str) => {
  if (!str) {
    return "学科未知";
  }
  try {
    const { rows: list } = await getSelectList({ parentCode: subjectCode });
    let res = [],
      selectList = str.split(",");
    selectList.forEach((selectItem) => {
      list.forEach((originItem) => {
        const { macroId, name } = originItem;
        if (macroId == selectItem) {
          res.push(name);
        }
      });
    });
    return res.join(",");
  } catch (err) {
    console.error("getSubjectName err: ", err);
  }
};
const teachYearObj = {
  '27': "5年以下",
  '28': "5-10",
  '29': "10-15",
  '227': "15-20以上",
};
const getTeachYear = (key) => {
  if (!key) {
    return "教龄未知";
  }
  return teachYearObj[String(key)];
};
const activeStatusObj = {
  '1': "在线",
  '2': "刚刚活跃",
  '3': "一天前活跃",
  '4': "七天前活跃",
};
const getActiveStatus = (key) => {
  if (!key) {
    return "活跃状态未知";
  }
  return activeStatusObj[String(key)];
};
const classModeObj = {
  '0': "上课模式未知",
  '1': "线上",
  '2': "线下",
};
const getClassModeName = (key) => {
  if (!key) {
    return "上课方式未知";
  }
  return classModeObj[String(key)];
};
const studentStatusObj = {
  '0': '新生',
  '1': '在读',
  '2': '暂停课',
  '3': '退费',
  '4': '结课',
  '5': '试听',
  '6': '试听未报名',
}
const getStudentStatus = (studentStatus) => {
  if(!studentStatus){
    return '学生状态未知'
  }
  return studentStatusObj[String(studentStatus)]
}
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import MessageSendBox from "../message/message-send-box";
import MessageItem from "../message/message-item";
import ConversationProfile from "./conversation-profile.vue";
import MemberProfileCard from "../group/member-profile-card";
import chatStudentInfo from "../dialog/chat-student-info";
import chatStudentQuestion from "../dialog/chat-student-question";
import chatStudentList from "../dialog/chat-student-list";
import studentHomeworkList from "../dialog/student-homework-list.vue";
import fileView from "../common/file-view";
import dayjs from 'dayjs';

export default {
  name: "CurrentConversation",
  components: {
    MessageSendBox,
    MessageItem,
    ConversationProfile,
    MemberProfileCard,
    chatStudentInfo,
    chatStudentQuestion,
    chatStudentList,
    studentHomeworkList,
    fileView,
  },
  props: {
    dialogChat: {
      default: false,
    },
  },
  data() {
    return {
      isShowScrollButtomTips: false,
      preScrollHeight: 0,
      showConversationProfile: false,
      timeout: "",
      studentParams: {
        show: false,
        studentId: null,
      },
      studentQuestionParams: {
        show: false,
      },
      studentListParams: {
        show: false,
        step: 1,
        studentId: null,
        eduId: null,
      },
      studentHomeworkListParams: {
        show: false,
        teacherId: null,
        studentId: null,
      },
      dialogSubjectSelect: false,
      artSubject: [],
      languageSubject: [],
      baseSubject: [],
      selectSubjectId: null,
      selectSubjectName: null,
      studentId: null,
      teacherInfo: {
        sexName: "",
        subjectName: "",
        periodName: "",
        classModeName: "",
        titleName: "",
        teachYear: "",
        activeStatus: "",
        age: '',
        identityName: '',
        titleName: '',
      },
      studentInfo: {
        sexName: '',
        sectionName: '',
        studentState: '',
        name: '',
        titleName: '',
      },
    };
  },
  inject: ["timChat"],
  computed: {
    ...mapState("chatInfo", [
      "currentConversation",
      "currentMessageList",
      "isCompleted",
      "isSDKReady",
      "chatToUserParams",
    ]),
    // 是否是学生和老师课程交流群
    groupType() {
      if (
        this.currentConversation &&
        this.currentConversation.type === "GROUP"
      ) {
        for (
          let i = 0,
            len = this.currentConversation.groupProfile.groupCustomField.length;
          i < len;
          i++
        ) {
          if (
            this.currentConversation.groupProfile.groupCustomField[i].key ===
            "groupType"
          ) {
            return this.currentConversation.groupProfile.groupCustomField[i]
              .value;
          }
        }
      }
      return "";
    },
    // 一对一会话当前是否是教务
    C2CStuPar() {
      if (this.currentConversation && this.currentConversation.type === "C2C") {
        if (
          this.currentConversation.conversationID.includes("_stu_") ||
          this.currentConversation.conversationID.includes("_par_")
        ) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
    memberCount() {
      if (
        this.currentConversation &&
        this.currentConversation.type === "GROUP"
      ) {
        return this.currentConversation.groupProfile.memberCount;
      }
      return "";
    },
    // 一对一会话当前是否是老师
    C2CTea() {
      if (this.currentConversation && this.currentConversation.type === "C2C") {
        if (this.currentConversation.conversationID.includes("_tea_")) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
    ...mapGetters("chatInfo", ["toAccount"]),
    // 是否显示当前会话组件
    showCurrentConversation() {
      return !!this.currentConversation.conversationID;
    },
    name() {
      const { type, selfNick, userProfile, groupProfile } =
        this.currentConversation;
      if (type === "C2C") {
        const { nick, userID } = userProfile;
        if (userID.includes("_tea_") || userID.includes("_par_")) {
          return selfNick ? selfNick : "";
        } else {
          return nick ? nick : userID;
        }
      } else if (type === "GROUP") {
        const { name, groupID } = groupProfile;
        return name ? name : groupID;
      } else if (this.currentConversation.conversationID === "@TIM#SYSTEM") {
        return "系统通知";
      }
      return this.toAccount;
    },
    showMessageSendBox() {
      return this.currentConversation.type !== this.TIM.TYPES.CONV_SYSTEM;
    },
    roleType() {
      return this.timChat.roleType;
    },
  },
  mounted() {
    this.$bus.$on("image-loaded", this.onImageLoaded);
    this.$bus.$on("scroll-bottom", this.scrollMessageListToButtom);
    if (this.currentConversation.conversationID === "@TIM#SYSTEM") {
      return false;
    }
    if (
      this.currentConversation &&
      this.currentConversation.conversationID &&
      this.currentConversation.selfType == "teacher"
    ) {
      this.getTeacherDetail();
    }else if(this.currentConversation &&
      this.currentConversation.conversationID &&
      this.currentConversation.selfType == "parent"){
      this.getParentsDetail()
    }
  },
  updated() {
    this.keepMessageListOnButtom();
    // 1. 系统会话隐藏右侧资料组件
    // 2. 没有当前会话时，隐藏右侧资料组件。
    //    背景：退出群组/删除会话时，会出现一处空白区域
    if (
      this.currentConversation.conversationID === "@TIM#SYSTEM" ||
      typeof this.currentConversation.conversationID === "undefined"
    ) {
      this.showConversationProfile = false;
    }
  },
  watch: {
    currentConversation() {
      if (this.currentConversation.selfType == "teacher") {
        this.getTeacherDetail();
      }else if(this.currentConversation.selfType == "parent"){
        this.getParentsDetail()
      }
      console.log("currentConversation: ", this.currentConversation);
    },
  },
  methods: {
    ...mapMutations("chatInfo", ["chatShotConversationShowChange"]),
    ...mapActions("chatInfo", ["getMessageList"]),
    async getTeacherDetail() {
      try {
        const { rows: data } = await getTeacherDetail([
          this.currentConversation.selfId
        ]);
        const {
          classForm,
          onLineFlag,
          periodId,
          sexId,
          subjectId,
          teachTitleId,
          teachingAgeId,
          birthday,
          identityType,
        } = data[0];
        const teacherInfo = {
          sexName: getSexName(sexId),
          subjectName: await getSubjectName(subjectId),
          periodName: getPeriodName(periodId),
          classModeName: getClassModeName(classForm),
          titleName: getTitleName(teachTitleId),
          teachYear: getTeachYear(teachingAgeId),
          activeStatus: getActiveStatus(onLineFlag),
          age: birthday ? dayjs().get('year') - dayjs(birthday).get('year') : '年龄未知',
          identityName: getIdentityName(identityType),
        };
        this.teacherInfo = teacherInfo;
      } catch (err) {
        this.msgError("处理老师详情信息发生错误！");
        console.error("getTeacherDetail err：", err);
      }
    },
    async getParentsDetail() {
      try {
        const { rows: data} = await getParentsDetail([this.currentConversation.selfId])
        const {sectionName, sex: sexId, studentState, name } = data[0]
        this.studentInfo = {
          sectionName,
          name,
          sexName: getSexName(sexId),
          studentState: getStudentStatus(studentState),
        }
      }catch(err){
        this.msgError('处理家长详情信息发生错误！')
        console.error('getParentsDetail err: ', err)
      }
    },
    sBtnClick(item) {
      console.log(item);
      this.selectSubjectId = item.macroId;
      this.selectSubjectName = item.name;
    },
    confirmSelectSubject() {
      getNewCourseNeedsBySubjectIdAndStudentId({
        studentId: this.studentId,
        subjectId: this.selectSubjectId,
      }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          let id = res.rows.studentSubjectRelation.id;
          this.$bus.$emit("new-class-need", {
            id,
            subjectName: this.selectSubjectName,
            studentName: this.currentConversation.userProfile.nick,
          });
        }
      });
    },
    studentHomeworkListParamsChange() {
      this.studentHomeworkListParams.show = false;
    },
    onScroll({ target: { scrollTop } }) {
      let messageListNode = this.$refs["message-list"];
      if (!messageListNode) {
        return;
      }
      if (
        this.preScrollHeight - messageListNode.clientHeight - scrollTop <
        20
      ) {
        this.isShowScrollButtomTips = false;
      }
    },
    // 如果滚到底部就保持在底部，否则提示是否要滚到底部
    keepMessageListOnButtom() {
      let messageListNode = this.$refs["message-list"];
      if (!messageListNode) {
        return;
      }
      // 距离底部20px内强制滚到底部,否则提示有新消息
      if (
        this.preScrollHeight -
          messageListNode.clientHeight -
          messageListNode.scrollTop <
        20
      ) {
        this.$nextTick(() => {
          messageListNode.scrollTop = messageListNode.scrollHeight;
        });
        this.isShowScrollButtomTips = false;
      } else {
        this.isShowScrollButtomTips = true;
      }
      this.preScrollHeight = messageListNode.scrollHeight;
    },
    // 直接滚到底部
    scrollMessageListToButtom() {
      this.$nextTick(() => {
        let messageListNode = this.$refs["message-list"];
        if (!messageListNode) {
          return;
        }
        messageListNode.scrollTop = messageListNode.scrollHeight;
        this.preScrollHeight = messageListNode.scrollHeight;
        this.isShowScrollButtomTips = false;
      });
    },
    showMore() {
      this.showConversationProfile = !this.showConversationProfile;
    },
    onImageLoaded() {
      this.keepMessageListOnButtom();
    },
    currentConversationClick() {
      this.studentParamsChange();
    },
    studentParamsChange() {
      this.studentParams = {
        show: false,
        studentId: null,
      };
    },
    studentQuestionParamsChange() {
      this.studentQuestionParams = {
        show: false,
      };
    },
    studentListParamsChange() {
      this.studentListParams.show = false;
    },
    chatBtnClick(type) {
      let studentId, teacherId; //stu001   tea001
      if (this.isSDKReady) {
        if (this.currentConversation.type == "C2C") {
          if (
            this.currentConversation.userProfile.userID.includes("_stu_chat_")
          ) {
            this.studentId = studentId =
              this.currentConversation.userProfile.userID.split(
                "_stu_chat_"
              )[1];
          } else if (
            this.currentConversation.userProfile.userID.includes("_par_chat_")
          ) {
            this.studentId = studentId =
              this.currentConversation.userProfile.userID.split(
                "_par_chat_"
              )[1];
          } else if (
            this.currentConversation.userProfile.userID.includes("_tea_chat_")
          ) {
            teacherId =
              this.currentConversation.userProfile.userID.split(
                "_tea_chat_"
              )[1];
          }
          switch (type) {
            case "student-class-require":
              //  新增了  529   530  538
              this.dialogSubjectSelect = true;
              getAllSubject().then((res) => {
                console.log(res);
                if (res.code == 0) {
                  this.baseSubject = res.rows.baseSubject;
                  this.languageSubject = res.rows.languageSubject;
                  this.artSubject = res.rows.artSubject;
                }
              });
              break;
            case "student-list":
              this.studentListParams = {
                show: true,
                step: 1,
                teacherId,
              };
              break;
          }
        } else {
          this.timChat.tim
            .getGroupMemberList({
              groupID: this.currentConversation.groupProfile.groupID,
              offset: 0,
              count: 99,
            })
            .then((imResponse) => {
              imResponse.data.memberList.forEach((item) => {
                if (item.userID.includes("_tea_chat_")) {
                  teacherId = item.userID.split("_tea_chat_")[1];
                } else if (item.userID.includes("_stu_chat_")) {
                  studentId = item.userID.split("_stu_chat_")[1];
                }
              });
              console.log("teacherId: ", teacherId, "studentId: ", studentId);
              switch (type) {
                case "student-question":
                  this.studentQuestionParams = {
                    show: true,
                    studentId,
                    teacherId,
                  };
                  break;
                case "student-homework":
                  this.studentHomeworkListParams = {
                    show: true,
                    teacherId,
                    studentId,
                  };
                  break;
              }
            })
            .catch((err) => {
              this.msgError(err.message);
            });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/* 当前会话的骨架屏 */
.current-conversation-wrapper {
  height: 100%;
  color: #1c2438;
  display: flex;
  width: 100%;
  .current-conversation {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .profile {
    height: calc(100vh - 50px);
    overflow-y: auto;
    width: 280px;
    border-left: 1px solid #e7e7e7;
    flex-shrink: 0;
    position: fixed;
    top: 50px;
    right: 0;
  }

  .more {
    display: flex;
    justify-content: center;
    font-size: 12px;
  }

  .no-more {
    display: flex;
    justify-content: center;
    color: #a5b5c1;
    font-size: 12px;
    padding: 10px 10px;
  }
}

.header {
  border-bottom: 1px solid #e7e7e7;
  height: 50px;
  position: relative;
  display: flex;
  align-items: center;
  .profile-active {
    right: 280px;
  }
}
.subject_select {
  .subject_row {
    padding: 10px;
    overflow: hidden;
    display: flex;
    .s_label {
      margin: 6px 0;
      float: left;
      padding: 6px 10px;
    }
    .s_btn_area {
      flex: 1;
      .s_btn {
        float: left;
        padding: 6px 10px;
        border: 1px solid #eeeeee;
        border-radius: 2px;
        cursor: pointer;
        margin: 6px 0 6px 10px;
        word-break: keep-all;
        &:hover {
          background-color: #1890ff;
          color: #ffffff;
        }
      }
    }
    .s_btn_active {
      background-color: #1890ff;
      color: #ffffff;
    }
  }
}
.name {
  padding: 0 20px;
  color: #1c2438;
  font-size: 16px;
  font-weight: bold;
  line-height: 50px;
  max-width: 600px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.teacher_info {
  flex: 1;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  .h_line {
    margin: 0 8px;
    color: #dcdfe6;
  }
}
.btn-more-info {
  position: absolute;
  top: 12px;
  right: 10px;
  font-size: 26px;
  cursor: pointer;
}
.dialog_close {
  font-size: 20px;
  position: absolute;
  right: 12px;
  top: 10px;
  cursor: pointer;
}
.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;
  .message-list {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    padding: 0 20px;
  }
  .newMessageTips {
    position: absolute;
    cursor: pointer;
    padding: 5px;
    width: 120px;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 5px;
    font-size: 12px;
    text-align: center;
    border-radius: 10px;
    border: #e9eaec 1px solid;
    background-color: #ffffff;
    color: #2d8cf0;
  }
}
.message_tool {
  height: 40px;
  .course_btn_area {
    height: 40px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e3e5e6;
    .course_btn {
      padding: 4px 8px;
      border-radius: 20px;
      border: 1px solid #dddddd;
      cursor: pointer;
      margin-left: 20px;
      display: flex;
      align-items: center;
      .iconfont {
        color: #024df8;
        margin-right: 4px;
      }
      .icon_homework {
        font-size: 14px;
      }
    }
  }
  .message_icon_area {
    height: 40px;
    display: flex;
    align-items: center;
    .iconfont {
      cursor: pointer;
      font-size: 20px;
      margin-left: 20px;
    }
  }
}
.footer {
  flex-shrink: 0;
  min-height: 200px;
  border-top: 1px solid #e7e7e7;
}

.show-more {
  text-align: right;
  padding: 10px 20px 0 0;
}
</style>
