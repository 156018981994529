<template>
  <ul v-show="visible" :style="{left:left+'px',top:top+'px'}" class="contextmenu">
    <li v-for="(item, index) in menuParams.menuList" :key="index" @click="item.click">{{item.label}}</li>
  </ul>
</template>
<script>
export default {
  name: 'ContextMenu',
  props: {
  },
  data () {
    return {
      left: 0,
      top: 0,
      visible: false,
      menuParams: {},
    }
  },
  watch: {
    menuParams: {
      handler: function () {
        const { x, y, menuList } = this.menuParams
        const menuMinWidth = 146, itemHeight = 34
        if (x < menuMinWidth) {
          this.left = x + menuMinWidth
        } else if(window.innerWidth - x < menuMinWidth) {
          this.left = window.innerWidth - menuMinWidth
        }else{
          this.left = x
        }
        if(y + menuList.length * 34 > window.innerHeight){
          this.top = window.innerHeight - menuList.length * 34
        }else{
          this.top = y
        }
        this.visible = true
      },
      deep: true
    },
    visible (value) {
      if (value) {
        document.body.addEventListener('click', this.closeMenu)
      } else {
        document.body.removeEventListener('click', this.closeMenu)
      }
    }
  },
  beforeDestroy(){
    this.$bus.$off('menu-open')
  },
  mounted() {
    this.$bus.$on('menu-open', (data) => {
      console.log('menu-open', data)
      this.menuParams = data
    })
  },
  methods: {
    closeMenu () {
      this.visible = false
    }
  }
}
</script>
<style lang="scss" scoped>
  .contextmenu {
    margin: 0;
    background: #fff;
    z-index: $max-index - 1000;
    position: fixed;
    list-style-type: none;
    padding: 5px 0;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #333;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, .3);
  }
  .contextmenu li {
    list-style-type: none;
    margin: 0;
    padding: 7px 16px;
    cursor: pointer;
  }
  .contextmenu li:hover {
    background: #eee;
  }
</style>