var render = function render(){var _vm=this,_c=_vm._self._c;return _c('message-bubble',{attrs:{"isMine":_vm.isMine,"message":_vm.message},scopedSlots:_vm._u([{key:"default",fn:function(slotProps){return [_c('div',{staticClass:"file-element-wrapper"},[_c('div',{staticClass:"file_box"},[_c('span',{staticClass:"file_detail"},[_c('span',{staticClass:"file_name"},[_vm._v(_vm._s(_vm.fileName))]),_c('span',{staticClass:"file_size"},[_vm._v(_vm._s(_vm.size ? _vm.size : "未知大小"))])]),_c('span',{staticClass:"file_icon",class:[_vm.extname]},[(_vm.extname == 'doc' || _vm.extname == 'docx')?[_c('svg-icon',{attrs:{"icon-class":"word"}})]:(_vm.extname == 'ppt' || _vm.extname == 'pptx')?[_c('svg-icon',{attrs:{"icon-class":"ppt"}})]:(_vm.extname == 'pdf')?[_c('svg-icon',{attrs:{"icon-class":"pdf"}})]:(_vm.extname == 'xlsx' || _vm.extname == 'xls')?[_c('svg-icon',{attrs:{"icon-class":"excel"}})]:(_vm.extname == 'txt')?[_c('svg-icon',{attrs:{"icon-class":"txt"}})]:(
              [
                'zip',
                'rar',
                'rar4',
                'jar',
                '7z',
                'tar',
                'cab',
                'iso',
                'uue',
              ].includes(_vm.extname)
            )?[_c('svg-icon',{attrs:{"icon-class":"zip","class-name":"zip"}})]:(
              [
                'mp4',
                'avi',
                'wmv',
                'mpg',
                'mpeg',
                'mov',
                'ram',
                'rm',
                'swf',
                'flv',
              ].includes(_vm.extname)
            )?[_c('svg-icon',{attrs:{"icon-class":"video"}})]:(
              [
                'mp3',
                'wav',
                'mpeg',
                'cda',
                'mid',
                'wma',
                'aif',
                'aiff',
                'ape',
                'vqf',
                'ra',
              ].includes(_vm.extname)
            )?[_c('svg-icon',{attrs:{"icon-class":"mp3"}})]:[_c('svg-icon',{attrs:{"icon-class":"unknown"}})]],2)]),(slotProps.downData.status == 1)?_c('el-progress',{attrs:{"percentage":slotProps.downData.process,"color":(percentage) => (percentage === 100 ? '#67c23a' : '#409eff')}}):_vm._e()],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }