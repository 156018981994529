<template>
  <message-bubble :isMine="isMine" :message="message">
    <div class="text_box">
      <template v-for="(item, index) in contentList">
        <span :key="index" v-if="item.name === 'text'" class="txt">{{
          item.text
        }}</span>
        <img
          v-else-if="item.name === 'img'"
          :src="item.src"
          width="20px"
          height="20px"
          :key="index"
        />
      </template>
    </div>
  </message-bubble>
  <!-- <div class="chat-bubble">
    <div class="message-content" :class="isMine ? 'message-send' : 'message-received'">
      <template v-for="(item, index) in contentList">
        <span :key="index" v-if="item.name === 'text'">{{ item.text }}</span>
        <img v-else-if="item.name === 'img'" :src="item.src" width="20px" height="20px" :key="index"/>
      </template>
    </div>
  </div> -->
</template>

<script>
import MessageBubble from "../message-bubble";
import { decodeText } from "@/utils/decodeText";

export default {
  name: "TextElement",
  components: {
    MessageBubble,
  },
  props: {
    payload: {
      type: Object,
      required: true,
    },
    message: {
      type: Object,
      required: true,
    },
    isMine: {
      type: Boolean,
    },
  },
  computed: {
    contentList() {
      return decodeText(this.payload);
    },
  },
};
</script>

<style lang="scss" scoped>
.txt {
  user-select: text;
  line-height: 20px;
}
</style>
