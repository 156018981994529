var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.dialogFileViewPrivateInfo.show),expression:"dialogFileViewPrivateInfo.show"}],staticClass:"x_dialog",attrs:{"draggable":"false","ondragstart":"return false;","ondrop":"return false;"}},[_c('div',{staticClass:"x_dialog_header"},[_vm._v(" 文件预览 "),_c('span',{staticClass:"dialog_close iconfont icon-guanbi3",attrs:{"title":"关闭"},on:{"click":_vm.closeClick}})]),_c('div',{ref:"imageWrapper",staticClass:"x_dialog_content"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.dialogFileViewPrivateInfo.type == 'img'),expression:"dialogFileViewPrivateInfo.type == 'img'"}],staticClass:"prev_img img_btn",class:{
          img_btn_disable: _vm.curStep == 0,
          img_btn_able: _vm.curStep != 0,
        },on:{"click":function($event){return _vm.moveImgListClick('prev')}}},[_c('span',{staticClass:"iconfont icon-zuojiantou"})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.dialogFileViewPrivateInfo.type == 'img'),expression:"dialogFileViewPrivateInfo.type == 'img'"}],staticClass:"next_img img_btn",class:{
          img_btn_disable: _vm.curStep == _vm.maxStep,
          img_btn_able: _vm.curStep != _vm.maxStep,
        },on:{"click":function($event){return _vm.moveImgListClick('next')}}},[_c('span',{staticClass:"iconfont icon-youjiantou"})]),(_vm.dialogFileViewPrivateInfo.type == 'file')?_c('div',[_c('iframe',{staticClass:"file_iframe",attrs:{"src":_vm.dialogFileViewPrivateInfo.src,"frameborder":"0"}})]):(_vm.dialogFileViewPrivateInfo.type == 'img')?_c('div',{staticClass:"img_box"},[_c('img',{directives:[{name:"drag-img",rawName:"v-drag-img"}],style:([_vm.imgStyle]),attrs:{"src":_vm.curImgSrc,"alt":""}})]):_vm._e()])])])
}
var staticRenderFns = []

export { render, staticRenderFns }