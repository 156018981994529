<template>
  <div class="chat-bubble" @mousedown.stop @contextmenu.prevent>
    <div
      style="display: flex"
      @contextmenu="contextmenuClick"
      @click.stop="fileClick"
      v-if="!message.isRevoked"
    >
      <div v-if="isMine && messageReadByPeer" class="message-status">
        <span>{{ messageReadByPeer }}</span>
      </div>
      <div
        v-if="message.type === TIM.TYPES.MSG_TEXT || message.type == TIM.TYPES.MSG_SOUND"
        class="message-content text-message-content"
        :class="[bubbleStyle, `${message.ID}`]"
      >
        <slot :downData="downData"></slot>
      </div>
      <div
        v-else
        class="message-content"
        :class="[bubbleStyle, `${message.ID}`]"
      >
        <slot :downData="downData"></slot>
      </div>
    </div>
    <div class="group-tip-element-wrapper" v-if="message.isRevoked">
      {{ text }}
      <el-button
        type="text"
        size="mini"
        class="edit-button"
        v-show="isEdit"
        @click="reEdit"
        >&nbsp;重新编辑</el-button
      >
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { browerDownloadFile } from "@/utils/tool";
export default {
  name: "MessageBubble",
  data() {
    return {
      isTimeout: false,
      downData: {
        error: null,
        cancel: null,
        status: 0, // 0未开始下载   1下载中  2下载完成 3下载失败
        process: 0,
        open: false,
        tip: true,
      },
    };
  },
  props: {
    isMine: {
      type: Boolean,
    },
    isNew: {
      type: Boolean,
    },
    message: {
      type: Object,
      required: true,
    },
  },
  inject: ["timChat"],
  components: {},
  created() {
    this.isTimeoutHandler();
  },
  mounted() {},
  computed: {
    bubbleStyle() {
      let classString = "",
        type = "";
      if (this.message.type === this.TIM.TYPES.MSG_TEXT || this.message.type === this.TIM.TYPES.MSG_SOUND) {
        type = "text";
      } else {
        type = "other";
      }
      if (this.isMine) {
        classString += `${type}-message-send`;
      } else {
        classString += `${type}-message-received`;
      }
      if (this.isNew) {
        classString += "new";
      }
      return classString;
    },
    text() {
      if (
        this.message.conversationType === this.TIM.TYPES.CONV_C2C &&
        !this.isMine
      ) {
        return "对方撤回了一条消息";
      }
      if (
        this.message.conversationType === this.TIM.TYPES.CONV_GROUP &&
        !this.isMine
      ) {
        return `${this.message.from}撤回了一条消息`;
      }
      return "你撤回了一条消息";
    },
    messageReadByPeer() {
      if (this.message.status !== "success") {
        return false;
      }
      if (
        this.message.conversationType === this.TIM.TYPES.CONV_C2C &&
        this.message.isPeerRead
      ) {
        return "已读";
      }
      if (
        this.message.conversationType === this.TIM.TYPES.CONV_C2C &&
        !this.message.isPeerRead
      ) {
        return "未读";
      }
      return "";
    },
    isEdit() {
      if (!this.isMine) {
        return false;
      }
      if (this.message.type !== this.TIM.TYPES.MSG_TEXT) {
        return false;
      }
      if (this.isTimeout) {
        return false;
      }
      return true;
    },
  },
  methods: {
    ...mapMutations("chatInfo", ["delCurrentMessage"]),
    ...mapMutations('dialogCont', ['dialogFileViewInfoChange']),
    async fileClick(e) {
      const { type, payload } = this.message
      if(type == this.TIM.TYPES.MSG_SOUND){
        return
      } else if(type == 'TIMFileElem'){
        const { fileUrl } = payload
        if(/(doc|docx|ppt|pptx|xls|xlsx)$/gi.test(fileUrl)){
          this.dialogFileViewInfoChange({
            show: true,
            url: '',
          })
        }else if(/(pdf)$/gi.test(fileUrl)){
          this.dialogFileViewInfoChange({
            show: true,
            url: '',
          })
        }
      } else if(type == 'TIMCustomElem'){
        const { fileUrl } = JSON.parse(payload.description);
        if(/(doc|docx|ppt|pptx|xls|xlsx)$/gi.test(fileUrl)){
          this.dialogFileViewInfoChange({
            show: true,
            url: `https://view.officeapps.live.com/op/view.aspx?src=${fileUrl}`,
          })
        }else if(/(pdf)$/gi.test(fileUrl)){
          this.dialogFileViewInfoChange({
            show: true,
            url: `${this.webUrl}/pdf/web/viewer.html?file=${fileUrl}`,
          })
        }
      }
      //this.contextmenuClick(e)
    },
    async contextmenuClick(e) {
      console.log("contextmenuClick", e, this.message);
      if (this.message.type == "TIMTextElem") {
        let dom = e.target;
        while (!dom.className.includes(this.message.ID)) {
          dom = dom.parentNode;
        }
        if (
          !(
            dom.contains(window.getSelection().anchorNode) &&
            window.getSelection().toString()
          )
        ) {
          let range = document.createRange();
          range.selectNode(dom.querySelector(".text_box"));
          window.getSelection().removeAllRanges();
          window.getSelection().addRange(range);
        }
      }
      let menuList = [];
      if (this.message.status == "success") {
        if (
          this.message.type == "TIMImageElem" ||
          this.message.type == "TIMFileElem" ||
          this.message.type == "TIMVideoFileElem" ||
          (this.message.type == "TIMCustomElem" &&
            this.message.payload.data == "prepare-data")
        ) {
          let url, fileName;
          if (this.message.type == "TIMImageElem") {
            url = this.message.payload.imageInfoArray[0].url;
            fileName = new Date().getTime() + ".png";
          } else if (this.message.type == "TIMFileElem") {
            url = this.message.payload.fileUrl;
            fileName = this.message.payload.fileName;
          } else if (this.message.type == "TIMVideoFileElem") {
            url = this.message.payload.remoteVideoUrl;
            fileName =
              new Date().getTime() + "." + this.message.payload.videoFormat;
          } else if (
            this.message.type == "TIMCustomElem" &&
            this.message.payload.data == "prepare-data"
          ) {
            let customData = JSON.parse(this.message.payload.description);
            url = customData.fileUrl;
            fileName = customData.fileName;
          }
          menuList.push({
            label: "下载",
            click: () => {
              console.log(url, fileName);
              browerDownloadFile({ fileUrl: url, fileName });
            },
          });
        } else if (
          this.message.type == "TIMTextElem" &&
          window.getSelection().toString()
        ) {
          let toBeCopy = window.getSelection().toString()
          menuList.push({
            label: "复制",
            click: () => {
              let tag = document.createElement("input");
              tag.setAttribute("id", "cp_zdy_input");
              tag.value = toBeCopy;
              document.getElementsByTagName("body")[0].appendChild(tag);
              document.getElementById("cp_zdy_input").select();
              document.execCommand("copy");
              document.getElementById("cp_zdy_input").remove();
            },
          });
        }
        menuList.push({
          label: "删除",
          click: () => {
            this.timChat.tim
              .deleteMessage([this.message])
              .then(({ data }) => {
                this.delCurrentMessage(data.messageList[0].ID);
              })
              .catch((err) => {
                this.msgWarn(err);
              });
          },
        });
      }
      if (this.isMine && !this.isTimeout && this.message.status == "success") {
        menuList.push({
          label: "撤销",
          click: () => {
            this.timChat.tim
              .revokeMessage(this.message)
              .then(() => {})
              .catch((err) => {
                this.msgWarn(err);
              });
          },
        });
      }
      if (this.message.status == "fail") {
        menuList.push({
          label: "重发",
          click: () => {
            this.timChat.tim
              .resendMessage(this.message)
              .then(() => {})
              .catch((err) => {
                this.msgWarn(err);
              });
          },
        });
      }
      let data = {
        x: e.x,
        y: e.y,
        menuList,
      };
      this.$bus.$emit("menu-open", data);
    },
    isTimeoutHandler() {
      // 从发送消息时间开始算起，两分钟内可以编辑
      let now = new Date();
      if (parseInt(now.getTime() / 1000) - this.message.time > 2 * 60) {
        this.isTimeout = true;
        return;
      }
      setTimeout(this.isTimeoutHandler, 1000);
    },
    reEdit() {
      this.$bus.$emit("reEditMessage", this.message.payload.text);
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-bubble {
  position: relative;
  margin-top: 10px;
  .message-status {
    display: flex;
    min-width: 25px;
    margin-right: 10px;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    color: #6e7981;
  }
  .message-content {
    outline: none;
    font-size: 14px;
    position: relative;
    max-width: 350px;
    word-wrap: break-word;
    word-break: break-all;
    span {
      white-space: pre-wrap;
      margin: 0;
      text-shadow: #495060 0 0 0.05em;
    }
    img {
      vertical-align: bottom;
    }
  }
  .text-message-content {
    outline: none;
    font-size: 14px;
    position: relative;
    max-width: 350px;
    word-wrap: break-word;
    word-break: break-all;
    padding: 10px;
    span {
      white-space: pre-wrap;
      margin: 0;
    }
    img {
      vertical-align: bottom;
    }
  }
  .other-message-received {
    margin-left: 15px;
  }
  .text-message-received {
    background-color: #ffffff;
    margin-left: 15px;
    border-radius: 4px 4px 4px 4px;
    border: 1px solid #eeeeee;
    &::after {
      content: "";
      width: 0;
      height: 0;
      border: 5px solid transparent;
      border-right: 5px solid #eeeeee;
      position: absolute;
      left: -10px;
      top: 10px;
    }
    &::before {
      content: "";
      width: 0;
      height: 0;
      border: 5px solid transparent;
      border-right: 5px solid #ffffff;
      position: absolute;
      left: -9px;
      top: 9px;
      z-index: 10;
    }
    &.new {
      transform: scale(0);
      transform-origin: top left;
      animation: bounce 500ms linear both;
    }
  }
  .other-message-send {
    margin-right: 15px;
  }
  .text-message-send {
    background-color: #00cc66;
    margin-right: 15px;
    border-radius: 4px 4px 4px 4px;
    color: #ffffff;
    &::after {
      content: "";
      width: 0;
      height: 0;
      border: 5px solid transparent;
      border-left: 5px solid #00cc66;
      position: absolute;
      right: -10px;
      top: 10px;
    }
    &.new {
      transform: scale(0);
      transform-origin: top right;
      animation: bounce 500ms linear both;
    }
  }
}

.group-tip-element-wrapper {
  background: #ffffff;
  padding: 4px 15px;
  border-radius: 3px;
  color: #a5b5c1;
  font-size: 12px;
}

.edit-button {
  padding-top: 4px;
  height: 20px;
  font-size: 10px;
}

@keyframes bounce {
  0% {
    transform: matrix3d(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  4.7% {
    transform: matrix3d(0.45, 0, 0, 0, 0, 0.45, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  9.41% {
    transform: matrix3d(0.883, 0, 0, 0, 0, 0.883, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  14.11% {
    transform: matrix3d(1.141, 0, 0, 0, 0, 1.141, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  18.72% {
    transform: matrix3d(1.212, 0, 0, 0, 0, 1.212, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  24.32% {
    transform: matrix3d(1.151, 0, 0, 0, 0, 1.151, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  29.93% {
    transform: matrix3d(1.048, 0, 0, 0, 0, 1.048, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  35.54% {
    transform: matrix3d(0.979, 0, 0, 0, 0, 0.979, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  41.04% {
    transform: matrix3d(0.961, 0, 0, 0, 0, 0.961, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  52.15% {
    transform: matrix3d(0.991, 0, 0, 0, 0, 0.991, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  63.26% {
    transform: matrix3d(1.007, 0, 0, 0, 0, 1.007, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  85.49% {
    transform: matrix3d(0.999, 0, 0, 0, 0, 0.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
  100% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  }
}
</style>
