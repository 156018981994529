
<template>
  <div class="dialog_mask" v-show="dialogLearnReportInfo.show">
    <dialog-file-view-private
      :dialogFileViewPrivateInfo="dialogFileViewPrivateInfo"
      @closeFileView="closeFileView"
    ></dialog-file-view-private>
    <div class="x_dialog" v-show="!seeWorkPopup">
      <div class="x_dialog_header">
        <span class="dialog-title">课堂反馈</span>
        <span
          class="dialog_close iconfont icon-guanbi3"
          title="关闭"
          @click="dialogLearnReportInfoChange({ show: false })"
        ></span>
      </div>
      <div class="x_dialog_content">
        <div class="save_feedback">
          <div class="flex_1">
            <div class="base_info">
              <div class="part_text">
                <span class="sub_title">基本信息</span>
              </div>
              <div class="part_content">
                <div>
                  <span class="user_name">{{ studentName }}</span>
                  <span class="user_section">【{{ sectionName }}】</span>
                </div>
                <div>
                  <span class="subInfo_label">性 别：</span>
                  <span class="subInfo_value">{{
                    sex == 1 ? "男" : sex == 2 ? "女" : "未知"
                  }}</span>
                  <span class="subInfo_label">出生年月：</span>
                  <span class="subInfo_value">{{
                    birthday ? birthday.substring(0, 11) : "未知"
                  }}</span>
                  <span class="subInfo_label">星座：</span>
                  <span class="subInfo_value">{{
                    constellationName ? constellationName : "未知"
                  }}</span>
                </div>
              </div>
            </div>
            <div class="cousre_info">
              <div class="part_text">上课内容</div>
              <div class="part_content">
                <div class="phase_area">
                  <div class="p_label">选择学段：</div>
                  <div class="p_select">
                    <el-select
                      size="small"
                      v-model="selectPhaseId"
                      placeholder="请选择学段"
                      @change="phaseSelectChange"
                      :popper-append-to-body="false"
                    >
                      <el-option
                        v-for="item in phaseList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                        v-show="item.id != 0"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </div>
                <div class="chapter_container">
                  <span class="chapter_label">章节：</span>
                  <span class="chapter_list">
                    <span
                      class="chapter_item"
                      v-for="item in chapterList"
                      :key="item.id"
                      :class="{ active_chapter: activeChapterId == item.id }"
                      @click="chapterClick(item)"
                      >{{ item.name }}</span
                    >
                  </span>
                </div>
                <div class="section_container" v-show="activeChapterId">
                  <span class="section_label">知识点：</span>
                  <span class="section_list">
                    <el-checkbox
                      class="section_item"
                      v-model="item.checked"
                      v-for="item in knowledgeList"
                      :key="item.id"
                      @change="knowSelectChange"
                      >{{ item.name }}</el-checkbox
                    >
                  </span>
                </div>
                <div
                  class="select_knowledge"
                  v-show="selectKnoewledgeList.length > 0"
                >
                  <span class="select_label">所选知识点</span>
                  <span class="select_list">
                    <span
                      class="select_item"
                      v-for="item in selectKnoewledgeList"
                      :key="item"
                    >
                      <span class="select_item_text">{{ item }}</span>
                      <span
                        class="iconfont icon-error icon_del"
                        @click="deleteKnowledgeClick(item)"
                      ></span>
                    </span>
                  </span>
                </div>
              </div>
            </div>
            <div class="detail_content">
              <div class="detail_content_title">上课内容及学生相关问题</div>
              <div class="detail_content_editer">
                <el-input
                  type="textarea"
                  :rows="3"
                  placeholder="您可以通过课堂内容、掌握情况、课堂表现等多方面描述"
                  v-model="teaDetailedContent"
                >
                </el-input>
              </div>
              <div class="detail_content_title">图片备注</div>
              <div class="img_list">
                <div
                  class="img_item img_item_true"
                  v-for="(item, index) in teaDetailedPictureList"
                  :key="index"
                >
                  <div class="img_hover">
                    <span
                      class="iconfont icon-yulan1 icon_preview"
                      title="预览"
                      @click="imgItemSeeClick(item, index)"
                    ></span>
                    <span
                      class="iconfont icon-shanchu4 icon_del"
                      title="删除"
                      @click="imgItemDelClick(index)"
                    ></span>
                  </div>
                  <img :src="item.url" alt="" />
                </div>
                <div class="img_item" @click="imgSelectClick">
                  <span class="iconfont icon-danyehuaban icon_new"></span>
                  <input
                    type="file"
                    ref="uploadImg"
                    @change="imgSelectChange"
                    style="display: none"
                    accept="image/png, image/jpeg, image/gif, image/jpg"
                  />
                </div>
              </div>
            </div>
            <div class="course_evaluate">
              <div class="part_text">评价学生</div>
              <div class="course_evaluate_item">
                <div class="evaluate_title">
                  1. 学生在本堂课听课的认真程度？
                </div>
                <div class="evaluate_option">
                  <el-radio-group v-model="teaEarnestId">
                    <el-radio
                      class="evaluate_option_item"
                      :label="item.id"
                      v-for="item in teaEarnestList"
                      :key="item.id"
                      >{{ item.name }}</el-radio
                    >
                  </el-radio-group>
                </div>
              </div>
              <div class="course_evaluate_item">
                <div class="evaluate_title">2. 上次课作业的完成程度？</div>
                <div class="evaluate_option">
                  <el-radio-group v-model="teaFinishId">
                    <el-radio
                      class="evaluate_option_item"
                      :label="item.id"
                      v-for="item in teaFinishList"
                      :key="item.id"
                      >{{ item.name }}</el-radio
                    >
                  </el-radio-group>
                </div>
              </div>
              <div class="course_evaluate_item">
                <div class="evaluate_title">3. 学生与老师的互动效果？</div>
                <div class="evaluate_option">
                  <el-radio-group v-model="teaInteractionId">
                    <el-radio
                      class="evaluate_option_item"
                      :label="item.id"
                      v-for="item in teaInteractionList"
                      :key="item.id"
                      >{{ item.name }}</el-radio
                    >
                  </el-radio-group>
                </div>
              </div>
              <div class="course_evaluate_item">
                <div class="evaluate_title">
                  4. 学生对本堂课内容的掌握程度？
                </div>
                <div class="evaluate_option">
                  <el-radio-group v-model="teaUnderstandId">
                    <el-radio
                      class="evaluate_option_item"
                      :label="item.id"
                      v-for="item in teaUnderstandList"
                      :key="item.id"
                      >{{ item.name }}</el-radio
                    >
                  </el-radio-group>
                </div>
              </div>
            </div>
            <div class="homework">
              <div class="part_text part_text_homework">
                作业内容<span class="part_text_tip"
                  >提醒：如果您在其他位置为学生布置过作业，此处可以不传上传内容</span
                >
              </div>
              <ul class="sendJob_ul">
                <li v-for="(item, index) in workList" :key="index">
                  <div>
                    <p>{{ item.homeworkTitle }}</p>
                    <p>{{ item.createTime }}</p>
                  </div>
                  <div>
                    <span @click="seeWork(item)">预览</span>
                    <span @click="removeJobData(item)">删除</span>
                  </div>
                </li>
              </ul>
              <div class="homework_container">
                <div class="homework_title homework_item">
                  <div class="homewok_item_label">作业标题</div>
                  <div class="homework_item_value">
                    <el-input
                      v-model="homeworkTitle"
                      placeholder="请输入作业标题"
                    ></el-input>
                  </div>
                </div>
                <div class="homework_content homework_item">
                  <div class="homewok_item_label">作业内容</div>
                  <div class="homework_item_value">
                    <el-input
                      type="textarea"
                      :rows="4"
                      placeholder="请输入作业内容"
                      v-model="homeworkContent"
                      resize="none"
                    >
                    </el-input>
                  </div>
                </div>
                <div class="homework_file homework_item">
                  <div class="homewok_item_label">上传文件</div>
                  <div class="homework_item_value">
                    <span class="file_btn" @click="btnClick()">
                      <span class="iconfont icon-shangchuan2"></span>
                      <span>本地上传</span>
                      <input
                        type="file"
                        ref="fileUpload"
                        @change="fileUploadChange"
                        style="display: none"
                      />
                    </span>
                  </div>
                </div>
                <div class="homework_item homework_select">
                  <div class="homewok_item_label">文件列表</div>
                  <div class="homework_item_value">
                    <div
                      class="file_list"
                      v-show="xmtHomeworkEntityList.length > 0"
                    >
                      <span
                        v-for="(item, index) in xmtHomeworkEntityList"
                        :key="index"
                        class="file_item"
                        @click="imgItemSeeClick(item, undefined)"
                      >
                        <span class="file_icon" :class="[item.eatname]">
                          <template
                            v-if="
                              item.extname == 'jpg' ||
                              item.extname == 'jpeg' ||
                              item.extname == 'png' ||
                              item.extname == 'bmp'
                            "
                          >
                            <svg-icon icon-class="img_2"></svg-icon>
                          </template>
                          <template
                            v-else-if="
                              item.extname == 'doc' || item.extname == 'docx'
                            "
                          >
                            <svg-icon icon-class="word"></svg-icon>
                          </template>
                          <template
                            v-else-if="
                              item.extname == 'ppt' || item.extname == 'pptx'
                            "
                          >
                            <svg-icon icon-class="ppt"></svg-icon>
                          </template>
                          <template v-else-if="item.extname == 'pdf'">
                            <svg-icon icon-class="pdf"></svg-icon>
                          </template>
                          <template
                            v-else-if="
                              item.extname == 'xlsx' || item.extname == 'xls'
                            "
                          >
                            <svg-icon icon-class="excel"></svg-icon>
                          </template>
                          <template v-else-if="item.extname == 'txt'">
                            <svg-icon icon-class="txt"></svg-icon>
                          </template>
                          <template v-else>
                            <svg-icon
                              icon-class="unknown"
                              class-name="unknown"
                            ></svg-icon>
                          </template>
                        </span>
                        <span class="file_name">{{ item.name }}</span>
                        <span
                          class="iconfont icon-guanbi6 file_del"
                          @click.stop="delListItem(index)"
                        ></span>
                      </span>
                    </div>
                    <div v-show="xmtHomeworkEntityList.length == 0">
                      暂无上传文件
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bottom">
            <span
              class="bottom_btn btn_publish"
              @click="bottomBtnClick('publish')"
              >发布</span
            >
            <span
              class="bottom_btn btn_save"
              v-show="status == 1"
              @click="bottomBtnClick('save')"
              >保存</span
            >
            <span class="bottom_btn btn_save" @click="bottomBtnClick('preview')"
              >预览</span
            >
          </div>
        </div>
      </div>
    </div>
    <dialog-upload-list
      :uploadListParams="uploadListParams"
      @uploadListParamsChange="uploadListParamsChange"
    ></dialog-upload-list>
    <div class="dialog dialog_homework_detail" v-show="seeWorkPopup == true">
      <div class="dialog_header">
        <div class="dialog_title">
          <span>作业预览</span>
        </div>
        <span
          class="iconfont icon-error icon_close"
          @click="seeWorkFn()"
        ></span>
      </div>
      <div class="dialog-content workDiv">
        <p>
          <span>作业标题</span
          ><span style="margin-left: 30px">{{ seeWorkTitle }}</span>
        </p>
        <p style="margin-top: 20px">
          <span>作业内容</span
          ><span style="margin-left: 30px; line-height: 1.5">{{
            seeWorkContent ? seeWorkContent : "暂无作业内容"
          }}</span>
        </p>
        <div class="homework_item homework_select">
          <div class="homewok_item_label">文件列表</div>
          <div class="homework_item_value">
            <div class="file_list" v-show="seeWorkEntityList.length > 0">
              <span
                v-for="(item, index) in seeWorkEntityList"
                :key="index"
                class="file_item"
                @click="imgItemSeeClick(item, undefined)"
              >
                <span class="file_icon" :class="[item.eatname]">
                  <template
                    v-if="
                      item.extname == 'jpg' ||
                      item.extname == 'jpeg' ||
                      item.extname == 'png' ||
                      item.extname == 'bmp'
                    "
                  >
                    <svg-icon icon-class="img_2"></svg-icon>
                  </template>
                  <template
                    v-else-if="item.extname == 'doc' || item.extname == 'docx'"
                  >
                    <svg-icon icon-class="word"></svg-icon>
                  </template>
                  <template
                    v-else-if="item.extname == 'ppt' || item.extname == 'pptx'"
                  >
                    <svg-icon icon-class="ppt"></svg-icon>
                  </template>
                  <template v-else-if="item.extname == 'pdf'">
                    <svg-icon icon-class="pdf"></svg-icon>
                  </template>
                  <template
                    v-else-if="item.extname == 'xlsx' || item.extname == 'xls'"
                  >
                    <svg-icon icon-class="excel"></svg-icon>
                  </template>
                  <template v-else-if="item.extname == 'txt'">
                    <svg-icon icon-class="txt"></svg-icon>
                  </template>
                  <template v-else>
                    <svg-icon
                      icon-class="unknown"
                      class-name="unknown"
                    ></svg-icon>
                  </template>
                </span>
                <span class="file_name">{{ item.name }}</span>
              </span>
            </div>
            <div v-show="seeWorkEntityList.length == 0">暂无上传文件</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import TIM from "tim-js-sdk";
import {
  productItemWorkList,
  productItemWorkSaveInfo,
  productItemWorkRemove,
} from "@/api/administration/expertTeachBusiness/teachBusiness.js";
import { uploadFileTask, computeFileSize } from "@/utils/tool";
import { mapState, mapMutations } from "vuex";
import dialogUploadList from "./dialog-upload-list.vue";
import dialogFileViewPrivate from "../dialog-file-view-private.vue";
import Cookies from "js-cookie";
import {
  getLearnReportInfo,
  updateLearnReport,
  knowledgeTree,
  fileUploadUrl,
  fileUpload,
} from "@/api/chat";
const teaFinishList = [
  {
    id: 192,
    name: "上次课未留作业",
  },
  {
    id: 191,
    name: "完成的太少，没有主动去完成老师布置的作业",
  },
  {
    id: 190,
    name: "部分完成，个别难度大的题目等待老师讲解",
  },
  {
    id: 189,
    name: "无论题目难度大小，全部完成",
  },
];
const teaEarnestList = [
  {
    id: 205,
    name: "走神情况严重",
  },
  {
    id: 206,
    name: "注意力集中时间较短",
  },
  {
    id: 207,
    name: "正常水平",
  },
  {
    id: 208,
    name: "能够跟上老师的节奏",
  },
  {
    id: 209,
    name: "全神贯注",
  },
];
const teaUnderstandList = [
  {
    id: 213,
    name: "记忆层面，只记住知识点",
  },
  {
    id: 214,
    name: "理解层面，可以熟练做题",
  },
  {
    id: 215,
    name: "完全掌握，能够举一反三",
  },
];
const teaInteractionList = [
  {
    id: 217,
    name: "交流少，不主动，不顺畅",
  },
  {
    id: 218,
    name: "只被动回答老师的提问",
  },
  {
    id: 219,
    name: "善于思考，积极回答",
  },
  {
    id: 220,
    name: "思维清晰，主动提问",
  },
];
export default {
  data() {
    return {
      seeWorkPopup: false,
      workList: [],
      studentName: "",
      sectionName: "",
      sex: "",
      birthday: "",
      constellationName: "",
      subjectName: "",
      chapterList: [],
      activeChapterId: "",
      knowledgeList: [],
      activeKnowledgeId: "",
      selectKnoewledgeList: [],
      status: 1, //1 保存 2发布
      id: "",
      itemId: "",
      teaFinishList,
      teaEarnestList,
      teaUnderstandList,
      teaInteractionList,
      teaContent: "",
      teaDetailedContent: "",
      teaFinishId: "",
      teaEarnestId: "",
      teaUnderstandId: "",
      teaInteractionId: "", //
      homeworkTitle: "",
      homeworkContent: "",
      teaDetailedPictureList: [], // teaDetailedPicture保存值
      homeworkId: "",
      /* 
        { url, name, fileSource, createUserId }
      */
      xmtHomeworkEntityList: [],
      uploadListParams: {
        show: false,
        list: [],
      },
      phaseList: [],
      selectPhaseId: null,
      selectPhaseName: null,
      dialogFileViewPrivateInfo: {
        show: false,
        type: "img",
        src: "",
      },
      sendJobparams: {
        pageSize: 100,
        pageNumber: 1,
        itemId: "",
      },
      seeWorkTitle: "",
      seeWorkContent: "",
      seeWorkEntityList: [],
    };
  },
  inject: ["timChat"],
  created() {},
  components: {
    dialogUploadList,
    dialogFileViewPrivate,
  },
  computed: {
    ...mapState("dialogCont", ["dialogLearnReportInfo"]),
  },
  watch: {
    "dialogLearnReportInfo.show"(show) {
      if (show) {
        this.init();
      }
    },
  },
  mounted() {},
  methods: {
    ...mapMutations("dialogCont", [
      "dialogLearnReportInfoChange",
      "dialogLearnReportDetailChange",
    ]),
    ...mapMutations("chatInfo", ["pushCurrentMessageList"]),
    async init() {
      await this.getFeedbackDetail();
      this.productItemWorkFn();
      this.getKonwledgeTree(0);
    },
    productItemWorkFn() {
      this.sendJobparams.itemId = this.dialogLearnReportInfo.customData.itemId
        ? this.dialogLearnReportInfo.customData.itemId
        : this.dialogLearnReportInfo.customData.id;
      productItemWorkList(this.sendJobparams).then((res) => {
        this.workList = res.rows;
      });
    },
    removeJobData(item) {
      this.$confirm("是否删除该作业?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          productItemWorkRemove([item.id]).then((res) => {
            if (res.code == 0) {
              this.productItemWorkFn();
              this.msgSuccess("删除成功！");
            }
          });
        })
        .catch(() => {});
    },
    closeFileView() {
      this.dialogFileViewPrivateInfo.show = false;
    },
    seeWork(item) {
      this.seeWorkPopup = true;
      productItemWorkSaveInfo(item.id).then((res) => {
        if (res.code == 0) {
          const { homeworkContent, homeworkTitle, xmtHomeworkEntityList } =
            res.rows;
          this.seeWorkTitle = homeworkTitle;
          this.seeWorkContent = homeworkContent;
          this.seeWorkEntityList = xmtHomeworkEntityList
            ? xmtHomeworkEntityList.map((item) => {
                let index = item.name.lastIndexOf(".");
                item.extname = item.name
                  .substring(index + 1)
                  .toLocaleLowerCase();
                return item;
              })
            : [];
        } else if (typeof res.msg == "string") {
          this.msgWarn(`${res.msg}`);
          return;
        }
      });
    },
    imgItemSeeClick(item, index) {
      if (
        ["doc", "docx", "ppt", "pptx", "xlsx", "xls"].includes(
          item.extname.toLowerCase()
        )
      ) {
        this.dialogFileViewPrivateInfo = {
          show: true,
          type: "file",
          src: `https://view.officeapps.live.com/op/view.aspx?src=${
            item.fileUrl ? item.fileUrl : item.url
          }`,
        };
      } else if (["pdf"].includes(item.extname.toLowerCase())) {
        this.dialogFileViewPrivateInfo = {
          show: true,
          type: "file",
          src: `${this.webUrl}/pdf/web/viewer.html?file=${
            item.fileUrl ? item.fileUrl : item.url
          }`,
        };
      } else if (
        ["png", "jpg", "bmp", "jpeg", "gif"].includes(
          item.extname.toLowerCase()
        )
      ) {
        this.dialogFileViewPrivateInfo = {
          show: true,
          type: "img",
          src: item.fileUrl ? item.fileUrl : item.url,
          curIndex: index ? index : 0,
          imgList:
            index != undefined
              ? this.teaDetailedPictureList.map((item) => {
                  return item.fileUrl ? item.fileUrl : item.url;
                })
              : [item.fileUrl ? item.fileUrl : item.url],
        };
      } else {
        this.msgWarn("该文件格式不支持预览！");
        return;
      }
    },
    seeWorkFn() {
      this.seeWorkPopup = false;
      this.dialogLearnReportInfo.show = true;
    },
    imgItemDelClick(index) {
      this.teaDetailedPictureList.splice(index, 1);
    },
    imgSelectClick() {
      this.$refs.uploadImg.value = "";
      this.$refs.uploadImg.click();
    },
    imgSelectChange() {
      if (
        !/\.(png|jpg|gif|jpeg|webp)$/.test(this.$refs.uploadImg.files[0].name)
      ) {
        this.msgWarn("只能是图片格式的文件！");
        return;
      }
      let formData = new FormData();
      formData.append("file", this.$refs.uploadImg.files[0]);
      fileUpload(formData).then((uploadRes) => {
        if (uploadRes[0].url) {
          let index = uploadRes[0].originalName.lastIndexOf(".");
          let extname = uploadRes[0].originalName.substring(index + 1);
          this.teaDetailedPictureList.push({
            name: uploadRes[0].originalName,
            url: uploadRes[0].url,
            extname,
          });
        }
      });
    },
    uploadListParamsChange(data) {
      if (data.type == "close") {
        this.uploadListParams.show = false;
      } else if (data.type == "delete") {
        data.item.cancel && data.item.cancel();
        this.uploadListParams.list.splice(data.index, 1);
        console.log(data, this.uploadListParams.list);
        if (this.uploadListParams.list.length == 0) {
          this.uploadListParams.show = false;
        }
      }
    },
    async getFeedbackDetail() {
      this.activeChapterId = "";
      this.homeworkTitle = "";
      this.homeworkContent = "";
      this.xmtHomeworkEntityList = [];
      return getLearnReportInfo(
        this.dialogLearnReportInfo.customData.itemId
          ? this.dialogLearnReportInfo.customData.itemId
          : this.dialogLearnReportInfo.customData.id
      ).then((res) => {
        if (typeof res.msg == "string") {
          this.msgWarn(`${res.msg}`);
          return;
        }
        const {
          studentName,
          sectionName,
          sex,
          birthday,
          constellationName,
          subjectName,
          id,
          teaContent,
          teaDetailedContent,
          teaEarnestId,
          teaFinishId,
          teaInteractionId,
          teaUnderstandId,
          xmtProductItemWorkEntity,
          teaDetailedPictureList,
          mainGroupId,
          itemId,
          status,
        } = res.rows;
        this.mainGroupId = mainGroupId;
        this.status = status;
        this.chatParams = {
          sectionName,
          studentName,
          itemId,
          subjectName,
        };
        this.studentName = studentName;
        this.sectionName = sectionName;
        this.sex = sex;
        this.birthday = birthday;
        this.constellationName = constellationName;
        this.subjectName = subjectName;
        this.id = id;
        this.teaContent = teaContent;
        this.teaDetailedContent = teaDetailedContent;
        this.teaEarnestId = teaEarnestId;
        this.teaFinishId = teaFinishId;
        this.teaInteractionId = teaInteractionId;
        this.teaUnderstandId = teaUnderstandId;
        this.teaDetailedPictureList = teaDetailedPictureList.map((item) => {
          let index = item.name.lastIndexOf(".");
          item.extname = item.name.substring(index + 1);
          return item;
        });
        if (teaContent) {
          this.selectKnoewledgeList = teaContent.split(";");
        } else {
          this.selectKnoewledgeList = [];
        }
        // this.xmtHomeworkEntityList =
        //   xmtProductItemWorkEntity.xmtHomeworkEntityList.map((item) => {
        //     let index = item.name.lastIndexOf(".");
        //     item.extname = item.name.substring(index + 1).toLocaleLowerCase();
        //     return item;
        //   });
        // this.homeworkId = xmtProductItemWorkEntity.id;
        // if (xmtProductItemWorkEntity.homeworkTitle) {
        //   this.homeworkTitle = xmtProductItemWorkEntity.homeworkTitle;
        // }
        // this.homeworkContent = xmtProductItemWorkEntity.homeworkContent;
      });
    },
    delListItem(index) {},
    knowSelectChange() {
      this.knowledgeList.forEach((item) => {
        let index = this.selectKnoewledgeList.indexOf(item.name);
        if (index != -1 && !item.checked) {
          this.selectKnoewledgeList.splice(index, 1);
        } else if (index == -1 && item.checked) {
          this.selectKnoewledgeList.push(item.name);
        }
      });
    },
    deleteKnowledgeClick(name) {
      let index = this.selectKnoewledgeList.indexOf(name);
      this.selectKnoewledgeList.splice(index, 1);
      this.knowledgeList.forEach((item) => {
        if (item.name == name) {
          item.checked = false;
        }
      });
    },
    saveFeedback(status) {
      if (this.saveFlag) {
        return;
      }
      this.saveFlag = true;
      this.teaContent = this.selectKnoewledgeList.join(";");
      let xmtHomeworkEntityList = [];
      this.xmtHomeworkEntityList.forEach((item) => {
        xmtHomeworkEntityList.push({
          url: item.url,
          name: item.name,
          fileSource: item.fileSource,
          createUserId: item.createUserId,
        });
      });
      let xmtProductItemWorkEntity = null;
      // 作业可以不布置。都为空，但是布置作业，作业标题是必传
      if (this.homeworkContent === null) {
        this.homeworkContent = "";
      }
      if (this.homeworkTitle === null) {
        this.homeworkTitle = "";
      }
      if (
        (this.homeworkContent.trim() || xmtHomeworkEntityList.length > 0) &&
        !this.homeworkTitle.trim()
      ) {
        this.msgWarn("作业标题不能为空！");
        this.saveFlag = false;
        return;
      }
      if (
        this.homeworkTitle.trim() ||
        this.homeworkContent.trim() ||
        xmtHomeworkEntityList.length > 0
      ) {
        xmtProductItemWorkEntity = {
          id: this.homeworkId,
          homeworkTitle: this.homeworkTitle,
          homeworkContent: this.homeworkContent,
          xmtHomeworkEntityList,
        };
      }

      if (
        !this.teaInteractionId &&
        !this.teaUnderstandId &&
        !this.teaFinishId &&
        !this.teaEarnestId &&
        this.selectKnoewledgeList.length == 0 &&
        !this.teaDetailedContent
      ) {
        this.msgWarn("不能保存空的课堂评价！");
        this.saveFlag = false;
        return;
      }
      let teaDetailedPicture = [];
      this.teaDetailedPictureList.forEach((item) => {
        teaDetailedPicture.push({
          name: item.name,
          url: item.url,
        });
      });
      updateLearnReport({
        id: this.id,
        itemId: this.dialogLearnReportInfo.customData.itemId
          ? this.dialogLearnReportInfo.customData.itemId
          : this.dialogLearnReportInfo.customData.id,
        teaContent: this.teaContent,
        teaDetailedContent: this.teaDetailedContent,
        teaEarnestId: this.teaEarnestId,
        teaFinishId: this.teaFinishId,
        teaUnderstandId: this.teaUnderstandId,
        teaInteractionId: this.teaInteractionId,
        xmtProductItemWorkEntity, //布置作业先不用，不删除，防止以后用
        status: status,
        teaDetailedPicture,
      })
        .then((res) => {
          this.saveFlag = false;
          if (res.code == 0) {
            this.msgSuccess("反馈成功！", 2000);
            this.dialogLearnReportInfoChange({
              show: false,
            });
          }else{
            this.msgWarn('反馈失败！')
          }
        })
        .catch((err) => {
          this.msgWarn('反馈失败！')
          this.saveFlag = false;
        });
    },
    bottomBtnClick(type) {
      switch (type) {
        case "publish":
          this.saveFeedback(2);
          break;
        case "save":
          this.saveFeedback(1);
          break;
        case "cancel":
          this.$router.go(-1);
          break;
        case "preview":
          this.dialogLearnReportDetailChange({
            show: true,
            customData: {
              id: this.dialogLearnReportInfo.customData.itemId
                ? this.dialogLearnReportInfo.customData.itemId
                : this.dialogLearnReportInfo.customData.id,
            },
          });
          break;
      }
    },
    chapterClick(item) {
      if (item.id == this.activeChapterId) {
        return;
      }
      this.activeChapterId = item.id;
      this.getSubTree(this.activeChapterId);
    },
    btnClick() {
      this.$refs.fileUpload.value = "";
      this.$refs.fileUpload.click();
    },
    async fileUploadChange() {
      let file = this.$refs.fileUpload.files[0];
      let index = file.name.lastIndexOf(".");
      let extname = file.name.substring(index + 1);
      let data = {
        fileName: file.name,
        extname,
        fileProcess: 0,
        showProcess: true,
        size: computeFileSize(file.size),
        time: new Date().getTime(),
        error: null,
        cancel: null,
      };
      this.uploadListParams.show = true;
      this.uploadListParams.list.push(data);
      let formData = new FormData();
      formData.append("file", file);
      uploadFileTask(
        fileUploadUrl,
        formData,
        (process) => {
          console.log(process);
          data.fileProcess = process;
          if (data.fileProcess == 100) {
            setTimeout(() => {
              data.fileProcess = "处理中";
            }, 300);
          }
        },
        (cancel) => {
          data.cancel = cancel;
        }
      )
        .then(({ data: uploadRes }) => {
          data.showProcess = false;
          if (this.uploadListParams.list.indexOf(data) != -1) {
            this.uploadListParams.list.splice(
              this.uploadListParams.list.indexOf(data),
              1
            );
            if (this.uploadListParams.list.length == 0) {
              this.uploadListParams.show = false;
            }
          }
          if (uploadRes[0].url) {
            let index = uploadRes[0].originalName.lastIndexOf(".");
            this.xmtHomeworkEntityList.push({
              url: uploadRes[0].url,
              name: uploadRes[0].originalName,
              fileSource: 3,
              extname: uploadRes[0].originalName
                .substring(index + 1)
                .toLocaleLowerCase(),
              createUserId: Cookies.get("sysChatId").split("sys_chat_")[1],
            });
          }
        })
        .catch((err) => {
          data.showProcess = false;
        });
    },
    async phaseSelectChange() {
      let subjectList = [],
        res,
        parentId;
      res = await knowledgeTree({
        parentId: this.selectPhaseId,
      });
      subjectList = res.rows;
      for (let i = 0, len = subjectList.length; i < len; i++) {
        if (subjectList[i].name == this.subjectName) {
          parentId = subjectList[i].id;
          break;
        }
      }
      res = await knowledgeTree({
        parentId,
      });
      this.chapterList = res.rows;
      this.activeChapterId = null;
      this.knowledgeList = [];
    },
    async getKonwledgeTree(parentId) {
      let phaseName = "";
      if (this.sectionName.includes("初")) {
        phaseName = "初中";
      } else if (this.sectionName.includes("高")) {
        phaseName = "高中";
      } else {
        phaseName = "小学";
      }
      let phaseList = [],
        res,
        subjectList = [];
      res = await knowledgeTree({
        parentId,
      });
      this.phaseList = phaseList = res.rows;
      for (let i = 0, len = phaseList.length; i < len; i++) {
        if (phaseList[i].name == phaseName) {
          this.selectPhaseId = parentId = phaseList[i].id;
          break;
        }
      }
      res = await knowledgeTree({
        parentId,
      });
      subjectList = res.rows;
      for (let i = 0, len = subjectList.length; i < len; i++) {
        if (subjectList[i].name == this.subjectName) {
          parentId = subjectList[i].id;
          break;
        }
      }
      res = await knowledgeTree({
        parentId,
      });
      this.chapterList = res.rows;
    },
    async getSubTree(parentId) {
      let res;
      res = await knowledgeTree({
        parentId,
      });
      let list = res.rows;
      list.forEach((item) => {
        if (this.selectKnoewledgeList.includes(item.name)) {
          item.checked = true;
        } else {
          item.checked = false;
        }
      });
      this.knowledgeList = res.rows;
    },
  },
};
</script>
<style lang="scss" scoped>
.dialog_mask {
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgba($color: #000000, $alpha: 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.x_dialog {
  position: fixed;
  width: 760px;
  height: 600px;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  color: #222222;
  font-size: 14px;
  .x_dialog_header {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    .dialog-title {
      font-weight: bolder;
      font-size: 18px;
    }
    .dialog_close {
      font-size: 20px;
      position: absolute;
      right: 20px;
      top: 10px;
      cursor: pointer;
    }
  }
  .x_dialog_content {
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #333333;
    padding: 20px;
    .save_feedback {
      background-color: #f1f3f8;
      color: #444444;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      width: 100%;
      height: 100%;
      .flex_1 {
        flex: 1;
        overflow-y: auto;
      }
      .part_text {
        height: 50px;
        font-weight: bold;
        font-size: 18px;
        color: #296afe;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .sub_title {
          font-size: 18px;
        }
      }
      .part_text_homework {
        justify-content: flex-start;
        .part_text_tip {
          margin-left: 20px;
          color: #222222;
        }
      }
      .base_info {
        padding: 10px 20px 20px;
        background-color: #ffffff;
        color: #222222;
        .part_content {
          line-height: 28px;
          .user_name {
            font-size: 18px;
          }
          .subInfo_label {
            color: #aaaaaa;
          }
          .subInfo_value {
            margin: 0 40px 0 6px;
          }
        }
      }
      .img_list {
        width: 100%;
        min-height: 120px;
        overflow: auto;
        .img_item {
          position: relative;
          margin: 10px 10px 10px 0px;
          float: left;
          cursor: pointer;
          height: 100px;
          width: 100px;
          border: 1px solid #dddddd;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          .img_hover {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: none;
            z-index: 6;
            align-items: center;
            justify-content: center;
            background-color: rgba($color: #000000, $alpha: 0.7);
            .icon_del {
              margin-left: 10px;
              cursor: pointer;
              color: #ffffff;
            }
            .icon_preview {
              cursor: pointer;
              color: #ffffff;
              font-size: 14px;
            }
          }
          &:hover {
            .img_hover {
              display: flex;
            }
          }
          img {
            width: 100px;
            height: 100px;
          }
          .icon_new {
            font-size: 40px;
          }
        }
      }
      .cousre_info {
        background-color: #ffffff;
        padding: 10px 20px 20px;
        .part_content {
          border: 1px solid #dddddd;
          padding: 10px;
          .phase_area {
            display: flex;
            align-items: center;
            .p_label {
              font-weight: bolder;
            }
            .p_select {
              width: 180px;
            }
          }
          .chapter_container {
            display: flex;
            .chapter_label {
              line-height: 32px;
              font-weight: bold;
            }
            .chapter_list {
              flex: 1;
              .chapter_item {
                cursor: pointer;
                font-size: 14px;
                line-height: 32px;
                margin-right: 20px;
              }
              .active_chapter {
                color: #024df8;
                font-weight: bold;
              }
            }
          }
          .section_container {
            margin-top: 10px;
            display: flex;
            .section_label {
              line-height: 32px;
              font-weight: bold;
            }
            .section_list {
              flex: 1;
              .section_item {
                line-height: 32px;
                margin-right: 20px;
              }
            }
          }
          .select_knowledge {
            margin-top: 10px;
            display: flex;
            .select_label {
              line-height: 40px;
              color: #888888;
            }
            .select_list {
              flex: 1;
              line-height: 40px;
              .select_item {
                margin-left: 20px;
                padding: 0px 8px;
                height: 30px;
                line-height: 26px;
                border: 1px solid #024df8;
                font-size: 12px;
                display: inline-block;
                .select_item_text {
                  display: inline-block;
                }
                .icon_del {
                  margin-left: 10px;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
      .detail_content {
        padding: 20px;
        background-color: #ffffff;
        .detail_content_title {
          margin-top: 10px;
          line-height: 30px;
          font-weight: 400;
        }
        .detail_content_editer {
          margin-top: 10px;
        }
      }
      .course_evaluate {
        background-color: #ffffff;
        padding: 20px;
        .course_evaluate_item {
          .evaluate_title {
            line-height: 40px;
            font-size: 16px;
            font-weight: 400;
          }
          .evaluate_option {
            line-height: 40px;
            .evaluate_option_item {
              line-height: 40px;
            }
          }
        }
      }
      .homework {
        background-color: #ffffff;
        padding: 20px;
        .homework_container {
          .homework_item {
            display: flex;
            margin-top: 20px;
            .homewok_item_label {
              margin-right: 20px;
              line-height: 40px;
            }
            .homework_item_value {
              flex: 1;
              display: flex;
              align-items: center;
              .file_btn {
                cursor: pointer;
                border: 1px solid #dddddd;
                border-radius: 5px;
                padding: 6px 12px;
                margin-right: 20px;
                .iconfont {
                  color: #024df8;
                  margin-right: 6px;
                }
              }
            }
          }
        }
      }
      .homework_select {
        padding: 20px 0;
        .file_list {
          .file_item {
            padding: 4px 10px;
            line-height: 32px;
            display: inline-block;
            word-break: keep-all;
            box-shadow: 0px 0px 10px 0px rgba(34, 34, 34, 0.15);
            margin-right: 28px;
            margin-bottom: 10px;
            cursor: pointer;
            .file_icon {
              display: inline-block;
              height: 16px;
              margin-right: 10px;
            }
            .unknown {
              font-size: 20px;
              position: relative;
              top: 14px;
            }
            .file_name {
              color: #222222;
            }
            .file_del {
              font-size: 14px;
              margin-left: 20px;
              cursor: pointer;
              color: #666666;
            }
          }
        }
      }
      .bottom {
        height: 80px;
        padding: 20px;
        background-color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        .bottom_btn {
          background: #ffffff;
          border: 1px solid #dddddd;
          border-radius: 2px;
          padding: 10px 26px;
          cursor: pointer;
          margin: 0 20px;
        }
        .btn_publish,
        .btn_save {
          background-color: #024df8;
          color: #ffffff;
        }
      }
    }
  }
  .sendJob_ul {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 10px;
    li {
      width: 48%;
      height: 70px;
      // border:1px solid #ccc;
      border-radius: 6px;
      margin-top: 15px;
      padding: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-shadow: 0 0 4px 2px #ccc;
      div:nth-child(1) {
        width: 75%;
        p:nth-child(1) {
          font-size: 16px;
          color: #000;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        p:nth-child(2) {
          margin-top: 12px;
          font-size: 13px;
        }
      }
      div:nth-child(2) {
        flex: 1;
        text-align: right;
        span {
          cursor: pointer;
        }
        span:nth-child(2) {
          margin-left: 6px;
          font-size: 12px;
        }
      }
    }
  }
}
.workDiv {
  .homework_item_value {
    .file_btn {
      cursor: pointer;
      border: 1px solid #dddddd;
      border-radius: 5px;
      padding: 6px 12px;
      margin-right: 20px;
      .iconfont {
        color: #024df8;
        margin-right: 6px;
      }
    }
  }
  .homework_item {
    display: flex;
    .homewok_item_label {
      margin-right: 20px;
      line-height: 40px;
    }
    .homework_item_value {
      flex: 1;
      display: flex;
      align-items: center;
      .file_btn {
        cursor: pointer;
        border: 1px solid #dddddd;
        border-radius: 5px;
        padding: 6px 12px;
        margin-right: 20px;
        .iconfont {
          color: #024df8;
          margin-right: 6px;
        }
      }
    }
  }
  .homework_select {
    padding: 20px 0;
    .file_list {
      .file_item {
        padding: 4px 10px;
        line-height: 32px;
        display: inline-block;
        word-break: keep-all;
        box-shadow: 0px 0px 10px 0px rgba(34, 34, 34, 0.15);
        margin-right: 28px;
        margin-bottom: 10px;
        cursor: pointer;
        .file_icon {
          display: inline-block;
          height: 16px;
          margin-right: 10px;
        }
        .unknown {
          font-size: 20px;
          position: relative;
          top: 14px;
        }
        .file_name {
          color: #222222;
        }
        .file_del {
          font-size: 14px;
          margin-left: 20px;
          cursor: pointer;
          color: #666666;
        }
      }
    }
  }
}
.dialog_homework_detail {
  width: 600px;
  overflow: hidden;
  padding: 0 10px 30px;
  z-index: 9999999;
  background: #fff;
  .dialog_header {
    position: relative;
    .dialog_title {
      margin-top: 20px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      font-size: 16px;
      font-weight: 700;
      .dialog_title_sub {
        color: #444444;
        font-size: 12px;
      }
    }
    .icon_close {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
  }
  .dialog_content {
    overflow-y: auto;
    padding: 10px;
  }
  .sub_title {
    line-height: 40px;
    font-weight: bold;
  }
  .h_info {
    line-height: 30px;
    .sub_title {
      margin-right: 10px;
    }
  }
}
</style>