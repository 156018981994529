<template>
  <div class="dialog_mask" v-show="studentHomeworkListParams.show">
    <dialog-file-view-private
      :dialogFileViewPrivateInfo="dialogFileViewPrivateInfo"
      @closeFileView="closeFileView"
    ></dialog-file-view-private>
    <div class="dialog" v-show="step == 1">
      <div class="dialog_header">
        <div class="dialog_title">
          <span>学生作业</span>
        </div>
        <span
          class="iconfont icon-error icon_close"
          @click="closeDialog"
        ></span>
      </div>
      <div class="dialog_content">
        <div class="condition">
          <div class="condition_item">
            <span>作业状态：</span>
            <el-select
              class="condition_select"
              v-model="selectFileStatus"
              size="small"
              placeholder="请选择"
              @change="fileStatusChange"
            >
              <el-option
                v-for="item in homeworkStatusList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
          <!-- <div class="condition_item">
            <span>科目：</span>
            <el-select
              class="condition_select"
              v-model="selectSubjectId"
              size="small"
              placeholder="请选择"
              @change="subjectChange"
            >
              <el-option
                v-for="item in subjectList"
                :key="item.macroId"
                :label="item.name"
                :value="item.macroId"
              >
              </el-option>
            </el-select>
          </div> -->
          <div class="condition_item">
            <span>上课时间：</span>
            <el-date-picker
              size="small"
              v-model="selectTime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              @change="timeChange"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="list" v-show="homeworkData.list.length > 0">
          <div class="list_head" :class="{ pad_r_10: padR10 }">
            <div class="item_h_title">作业标题</div>
            <div class="item_c_title">课程标题</div>
            <div class="item_time">上课时间</div>
            <div class="item_hour">课程时长</div>
            <div class="item_status">作业状态</div>
            <div class="item_opt">操作</div>
          </div>
          <div class="list_body">
            <div
              class="list_item"
              v-for="(item, index) in homeworkData.list"
              :key="index"
            >
              <div class="item_h_title">
                {{ item.homeworkTitle ? item.homeworkTitle : "暂无" }}
              </div>
              <div class="item_c_title">
                {{ item.title ? item.title : "暂无" }}
              </div>
              <div class="item_time">{{ formatTime(item.beginTime) }}</div>
              <div class="item_hour">{{ item.usedClassHour }}课时</div>
              <div class="item_status">
                <span class="btn" v-show="item.homeworkFileStatus == 1">
                  <span
                    class="iconfont icon-dian"
                    :style="{ color: statusColorList[0] }"
                  ></span>
                  <span>未批改</span>
                </span>
                <span class="btn" v-show="item.homeworkFileStatus == 2">
                  <span
                    class="iconfont icon-dian"
                    :style="{ color: statusColorList[1] }"
                  ></span>
                  <span>已批改</span>
                </span>
                <span class="btn" v-show="item.homeworkFileStatus == 0">
                  <span
                    class="iconfont icon-dian"
                    :style="{ color: statusColorList[2] }"
                  ></span>
                  <span>未提交</span>
                </span>
              </div>
              <div class="item_opt">
                <span @click="seeHomeworkDetailClick(item)">查看</span>
              </div>
            </div>
          </div>
        </div>
        <div class="no_data" v-show="homeworkData.list.length == 0">
          <img
            class="no_data_img"
            :src="require('@/assets/image/chat/no_data_1.jpg')"
            alt=""
          />
          <span class="txt">暂无作业</span>
        </div>
        <div class="bottom">
          <el-pagination
            background
            layout="prev, pager, next"
            :total="homeworkData.total"
            prev-text="上一页"
            next-text="下一页"
            :current-page="homeworkParams.pageNo"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="dialog dialog_homework_detail" v-show="step == 2">
      <div class="dialog_header">
        <div class="dialog_title">
          <span>作业详情</span>
        </div>
        <span class="iconfont icon-error icon_close" @click="step = 1"></span>
      </div>
      <div class="dialog_content">
        <div class="row_1">
          <div class="h_info">
            <span class="sub_title">作业标题</span>
            <span>{{ homeworkTitle }}</span>
          </div>
          <div class="h_info">
            <span class="sub_title">作业内容</span>
            <span>{{ homeworkContent }}</span>
          </div>
          <div class="h_info">
            <span class="sub_title">创建时间</span>
            <span>{{ homeworkCreateTime }}</span>
          </div>
          <div>
            <div class="sub_title">文件列表</div>
            <div class="f_item f_header">
              <span class="f_name">作业名称</span>
              <span class="f_opt">操作</span>
            </div>
            <div
              class="f_item f_body"
              v-for="(item, index) in teacherHomeworkList"
              :key="item.id"
            >
              <span class="f_name" :title="item.name">{{ item.name }}</span>
              <span class="f_opt">
                <span class="btn" @click="imgItemSeeClick(item, index)"
                  >查看</span
                >
                <span class="btn btn_down" @click="downClick(item)">下载</span>
              </span>
            </div>
          </div>
        </div>
        <div class="row_2">
          <div class="sub_title">学生提交</div>
          <div class="img_list" v-show="studentUploadList.length > 0">
            <div
              class="prev_img img_btn"
              :class="{
                img_btn_disable: studentUploadObj.curStep == 0,
                img_btn_able: studentUploadObj.curStep != 0,
              }"
              @click="moveImgListClick('prev', 'studentUploadObj')"
            >
              <span class="iconfont icon-zuojiantou"></span>
            </div>
            <div
              class="next_img img_btn"
              :class="{
                img_btn_disable:
                  studentUploadObj.curStep == studentUploadObj.maxStep,
                img_btn_able:
                  studentUploadObj.curStep != studentUploadObj.maxStep,
              }"
              @click="moveImgListClick('next', 'studentUploadObj')"
            >
              <span class="iconfont icon-youjiantou"></span>
            </div>
            <ul
              class="img_ul"
              ref="imgUlStudent"
              :style="[studentUploadObj.imgUlStyle]"
            >
              <li
                class="img_item img_item_true"
                v-for="(item, index) in studentUploadList"
                :key="index"
              >
                <div class="img_hover">
                  <span
                    class="iconfont icon-yulan1 icon_preview"
                    title="预览"
                    @click="imgItemSeeClick(item, index, studentUploadList)"
                  ></span>
                  <span class="iconfont icon-xiazai2 icon_down" title="下载" @click="downClick(item)"></span>
                </div>
                <img :src="item.url" alt="" />
                <span class="img_mask"></span>
              </li>
            </ul>
          </div>
          <div class="no_data" v-show="studentUploadList.length == 0">
            暂无数据
          </div>
        </div>
        <div class="row_2">
          <div class="sub_title">老师上传</div>
          <div class="img_list" v-show="teacherCorrectList.length > 0">
            <div
              class="prev_img img_btn"
              :class="{
                img_btn_disable: teacherCorrectObj.curStep == 0,
                img_btn_able: teacherCorrectObj.curStep != 0,
              }"
              @click="moveImgListClick('prev', 'teacherCorrectObj')"
            >
              <span class="iconfont icon-zuojiantou"></span>
            </div>
            <div
              class="next_img img_btn"
              :class="{
                img_btn_disable:
                  teacherCorrectObj.curStep == teacherCorrectObj.maxStep,
                img_btn_able:
                  teacherCorrectObj.curStep != teacherCorrectObj.maxStep,
              }"
              @click="moveImgListClick('next', 'teacherCorrectObj')"
            >
              <span class="iconfont icon-youjiantou"></span>
            </div>
            <ul
              class="img_ul"
              ref="imgUlCorrect"
              :style="[teacherCorrectObj.imgUlStyle]"
            >
              <li
                class="img_item img_item_true"
                v-for="(item, index) in teacherCorrectList"
                :key="index"
              >
                <div class="img_hover">
                  <span
                    class="iconfont icon-yulan1 icon_preview"
                    title="预览"
                    @click="imgItemSeeClick(item, index, teacherCorrectList)"
                  ></span>
                  <span class="iconfont icon-xiazai2 icon_down" title="下载" @click="downClick(item)"></span>
                </div>
                <img :src="item.url" alt="" />
                <span class="img_mask"></span>
              </li>
            </ul>
          </div>
          <div class="no_data" v-show="teacherCorrectList.length == 0">
            暂无数据
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as dayjs from "dayjs";
import { studentItemWorkPage, findItemHomeWork } from "@/api/chat";
import dialogFileViewPrivate from "../dialog-file-view-private.vue";
import { browerDownloadFile } from '@/utils/tool';
export default {
  data() {
    return {
      step: 1, // 1显示作业别表  2显示作业详情
      statusColorList: ["#E81123", "#D16BFF", "#5973FF"],
      homeworkParams: {
        teacherId: null,
        pageNo: 1,
        pageSize: 10,
        subjectId: null,
        searchBeginDate: null,
        searchEndDate: null,
        fileStatus: null,
        studentId: null,
      },
      homeworkData: {
        total: 0,
        list: [],
      },
      studentHomeworkUploadParams: {
        itemId: "",
        homeWorkId: "",
        show: false,
      },
      selectFileStatus: -1,
      selectSubjectId: -1,
      selectTime: "",
      homeworkStatusList: [
        {
          id: -1,
          name: "全部",
        },
        {
          id: 0,
          name: "未提交",
        },
        {
          id: 1,
          name: "未批改",
        },
        {
          id: 2,
          name: "已批改",
        },
      ],
      subjectList: [
        {
          macroId: -1,
          name: "全部学科",
        },
      ],
      padR10: false,
      /* 作业详情相关数据 */
      homeworkTitle: null,
      homeworkContent: null,
      homeworkCreateTime: null,
      teacherHomeworkList: [],
      studentUploadList: [],
      teacherCorrectList: [],
      studentUploadObj: {
        maxStep: 0,
        curStep: 0,
        imgUrlWidth: "",
        imgListWidth: 560,
        imgUlStyle: {
          left: "0px",
        },
      },
      teacherCorrectObj: {
        maxStep: 0,
        curStep: 0,
        imgUrlWidth: "",
        imgListWidth: 560,
        imgUlStyle: {
          left: "0px",
        },
      },
      dialogFileViewPrivateInfo: {
        show: false,
        type: "img",
        src: "",
      },
    };
  },
  inject: ["timChat"],
  props: ["studentHomeworkListParams"],
  watch: {
    "studentHomeworkListParams.show"() {
      this.homeworkParams.teacherId = this.studentHomeworkListParams.teacherId;
      this.homeworkParams.studentId = this.studentHomeworkListParams.studentId;
      this.init();
    },
  },
  components: { dialogFileViewPrivate },
  computed: {},
  mounted() {},
  methods: {
    moveImgListClick(type, prop) {
      if (this[prop].curStep != 0 && type == "prev") {
        this[prop].curStep--;
      } else if (this[prop].curStep != this[prop].maxStep && type == "next") {
        this[prop].curStep++;
      }
      this[prop].imgUlStyle.left = `-${this[prop].curStep * 140}px`;
    },
    closeFileView() {
      this.dialogFileViewPrivateInfo.show = false;
    },
    imgItemSeeClick(item, index, imgList) {
      if (!imgList) {
        imgList = [item];
      }
      if (
        ["doc", "docx", "ppt", "pptx", "xlsx", "xls"].includes(
          item.extname.toLowerCase()
        )
      ) {
        this.dialogFileViewPrivateInfo = {
          show: true,
          type: "file",
          src: `https://view.officeapps.live.com/op/view.aspx?src=${
            item.fileUrl ? item.fileUrl : item.url
          }`,
        };
        console.log(`https://view.officeapps.live.com/op/view.aspx?src=${
            item.fileUrl ? item.fileUrl : item.url
          }`)
      } else if (["pdf"].includes(item.extname.toLowerCase())) {
        this.dialogFileViewPrivateInfo = {
          show: true,
          type: "file",
          src: `${this.webUrl}/pdf/web/viewer.html?file=${
            item.fileUrl ? item.fileUrl : item.url
          }`,
        };
      } else if (
        ["png", "jpg", "bmp", "jpeg", "gif"].includes(
          item.extname.toLowerCase()
        )
      ) {
        this.dialogFileViewPrivateInfo = {
          show: true,
          type: "img",
          src: item.fileUrl ? item.fileUrl : item.url,
          curIndex: index,
          imgList: imgList.map((item) => {
            return item.fileUrl ? item.fileUrl : item.url;
          }),
        };
      } else {
        this.msgWarn("该文件格式不支持预览！");
        return;
      }
    },
    downClick(item) {
        browerDownloadFile({
            fileUrl: item.url,
            fileName: item.name
        })
    },
    closeDialog() {
      this.$emit("studentHomeworkListParamsChange", { type: "close" });
    },
    correctClick(item) {
      if (item.homeworkFileStatus == 0) {
        this.msgWarn("学生作业还未提交！");
        return;
      }
      this.$router.push({
        path: "/home/teacherHomeworkDetail",
        query: {
          itemId: item.id,
          homeWorkId: item.homeWorkId,
          studentName: item.studentName,
          homeworkFileStatus: item.homeworkFileStatus,
        },
      });
    },
    init() {
      //this.getSubjectList();
      this.getHomeworkList();
    },
    getSubjectList() {
      this.subjectList = [
        {
          macroId: -1,
          name: "全部学科",
        },
      ];
      getSubjectList().then((res) => {
        this.subjectList = this.subjectList.concat(res.msg);
      });
    },
    timeChange() {
      if (this.selectTime) {
        this.homeworkParams.searchBeginDate = dayjs(this.selectTime[0]).format(
          "YYYY-MM-DD HH:mm"
        );
        this.homeworkParams.searchEndDate = dayjs(this.selectTime[1]).format(
          "YYYY-MM-DD HH:mm"
        );
      } else {
        this.homeworkParams.searchBeginDate = null;
        this.homeworkParams.searchEndDate = null;
      }
      this.homeworkParams.pageNo = 1
      this.getHomeworkList();
    },
    fileStatusChange() {
      this.homeworkParams.fileStatus =
        this.selectFileStatus == -1 ? null : this.selectFileStatus;
      this.homeworkParams.pageNo = 1
      this.getHomeworkList();
    },
    subjectChange() {
      this.homeworkParams.subjectId =
        this.selectSubjectId == -1 ? null : this.selectSubjectId;
      this.homeworkParams.pageNo = 1
      this.getHomeworkList();
    },
    formatTime(time) {
      if (time) {
        return dayjs(time).format("YYYY/MM/DD HH:MM");
      }
      return "暂无";
    },
    workItemClick(item, type) {
      this.studentHomeworkUploadParams = {
        itemId: item.id,
        homeWorkId: item.homeWorkId,
        show: true,
        type,
      };
    },
    seeHomeworkDetailClick(item) {
      this.step = 2;
      this.homeworkTitle = "";
      this.homeworkContent = "";
      this.teacherHomeworkList = [];
      this.studentUploadList = [];
      this.teacherCorrectList = [];
      this.studentUploadObj = {
        maxStep: 0,
        curStep: 0,
        imgUrlWidth: "",
        imgListWidth: 584,
        imgUlStyle: {
          left: "0px",
        },
      };
      this.teacherCorrectObj = {
        maxStep: 0,
        curStep: 0,
        imgUrlWidth: "",
        imgListWidth: 584,
        imgUlStyle: {
          left: "0px",
        },
      };
      this.getHomeworkDetail(item.id, item.homeWorkId);
    },
    currentChange(page) {
      this.homeworkParams.pageNo = page;
      this.getHomeworkList();
    },
    getHomeworkDetail(itemId, homeWorkId) {
      findItemHomeWork({
        itemId,
        homeWorkId,
      }).then((res) => {
        if (res.code == 0) {
          this.homeworkTitle = res.rows.teacherAssignHomeWork.homeworkTitle;
          this.homeworkContent = res.rows.teacherAssignHomeWork.homeworkContent;
          this.homeworkCreateTime = res.rows.teacherAssignHomeWork.createTime;
          this.teacherHomeworkList =
            res.rows.teacherAssignHomeWork.xmtHomeworkEntityList.map(
              (item) => {
                let index = item.name.lastIndexOf(".");
                item.extname = null
                if (index != -1) {
                  item.extname = item.name.substring(index + 1).toLowerCase();
                }
                return item
              }
            );
          this.studentUploadList =
            res.rows.studentInfoList[0].studentUplodeHomeWorkList.map((item) => {
                let index = item.name.lastIndexOf(".");
                item.extname = null
                if (index != -1) {
                  item.extname = item.name.substring(index + 1).toLowerCase();
                }
                return item
            });
          this.teacherCorrectList =
            res.rows.studentInfoList[0].teacherCorrectHomeWorkList.map( item => {
                let index = item.name.lastIndexOf(".");
                item.extname = null
                if (index != -1) {
                  item.extname = item.name.substring(index + 1).toLowerCase();
                }
                return item
            });
          this.$nextTick(() => {
            this.studentUploadObj.imgUrlWidth =
              this.$refs.imgUlStudent.getBoundingClientRect().width;
            if (
              this.studentUploadObj.imgUrlWidth >
              this.studentUploadObj.imgListWidth
            ) {
              this.studentUploadObj.maxStep = Math.ceil(
                (this.studentUploadObj.imgUrlWidth -
                  this.studentUploadObj.imgListWidth) /
                  120
              );
            }
            this.teacherCorrectObj.imgUrlWidth =
              this.$refs.imgUlCorrect.getBoundingClientRect().width;
            if (
              this.teacherCorrectObj.imgUrlWidth >
              this.teacherCorrectObj.imgListWidth
            ) {
              this.teacherCorrectObj.maxStep = Math.ceil(
                (this.teacherCorrectObj.imgUrlWidth -
                  this.teacherCorrectObj.imgListWidth) /
                  120
              );
            }
          });
        }
      });
    },
    getHomeworkList() {
      studentItemWorkPage(this.homeworkParams).then((res) => {
        if (res.code == 0) {
          let list = res.rows.rows;
          list.forEach((item) => {
            let tempTime = dayjs(item.beginTime).add(
              item.usedClassHour,
              "hour"
            );
            item.dealBeginTime = `${dayjs(item.beginTime).format(
              "YYYY-MM-DD"
            )} ${dayjs(item.beginTime).format("hh:mm")} - ${tempTime.format(
              "hh:mm"
            )}`;
            item.statusColor = this.statusColorList[item.homeworkFileStatus];
            if (item.homeworkFileStatus == 0) {
              item.homeworkFileStatusName = "待完成";
              item.optName = "上传作业";
            } else if (item.homeworkFileStatus == 1) {
              item.homeworkFileStatusName = "已上传";
              item.optName = "查看作业";
            } else if (item.homeworkFileStatus == 2) {
              item.homeworkFileStatusName = "已批改";
              item.optName = "批改详情";
            } else {
              item.homeworkFileStatusName = "未知";
              item.optName = "批改详情";
            }
          });
          this.homeworkData.total = res.rows.total;
          this.homeworkData.list = list;
          this.$nextTick(() => {
            if (
              document.querySelector(".list_body").offsetHeight <
              document.querySelector(".list_body").scrollHeight
            ) {
              this.padR10 = true;
            } else {
              this.padR10 = false;
            }
          });
        }
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.dialog_mask {
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgba($color: #000000, $alpha: 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  .dialog {
    background-color: #ffffff;
    width: 1000px;
    height: 600px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    .dialog_header {
      position: relative;
      .dialog_title {
        margin-top: 20px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        .dialog_title_sub {
          color: #444444;
          font-size: 12px;
        }
      }
      .icon_close {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
      }
    }
    .dialog_content {
      flex: 1;
      overflow: hidden;
      padding: 20px 20px;
      display: flex;
      flex-direction: column;
      .condition {
        display: flex;
        height: 50px;
        align-items: center;
        .condition_item {
          margin-right: 20px;
          /deep/ {
            .el-input__inner {
              border-radius: 100px;
            }
            .el-range-separator {
              width: 30px;
            }
          }
          .condition_select {
            width: 140px;
          }
        }
      }
      .list {
        margin-top: 10px;
        background-color: #f1f3f8;
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        .list_head {
          height: 50px;
          background-color: #f1f3f8;
          width: 100%;
          display: flex;
          > div {
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .item_h_title,
          .item_c_title,
          .item_time {
            flex: 1;
          }
          .item_status {
            width: 100px;
          }
          .item_hour {
            width: 100px;
          }
          .item_opt {
            width: 80px;
          }
        }
        .pad_r_10 {
          padding-right: 10px;
        }
        .list_body {
          flex: 1;
          overflow-y: auto;
          color: #666666;
          .list_item {
            margin-bottom: 6px;
            width: 100%;
            display: flex;
            background-color: #ffffff;
            > div {
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 16px 10px;
            }
            .item_h_title,
            .item_c_title,
            .item_time {
              flex: 1;
              line-height: 20px;
            }
            .item_status {
              width: 100px;
            }
            .item_hour {
              width: 100px;
            }
            .item_opt {
              width: 80px;
              span {
                color: #024df8;
                cursor: pointer;
              }
            }
          }
        }
      }
      .no_data {
        flex: 1;
        @include no_data;
      }
      .bottom {
        margin-top: 20px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        /deep/ .el-pagination button,
        .el-pagination span:not([class*="suffix"]) {
          padding: 0 6px;
        }
      }
    }
  }
  .dialog_homework_detail {
    width: 600px;
    overflow: hidden;
    padding: 0 10px 30px;
    .dialog_content {
      overflow-y: auto;
      padding: 10px;
    }
    .sub_title {
      line-height: 40px;
      font-weight: bold;
    }
    .h_info {
      line-height: 30px;
      .sub_title {
        margin-right: 10px;
      }
    }
    .f_item {
      display: flex;
      height: 40px;
      align-items: center;
      .f_name {
        flex: 1;
        line-height: 40px;
        text-align: center;
        @include ellipsis;
      }
      .f_opt {
        width: 120px;
        line-height: 40px;
        text-align: center;
        .btn {
          cursor: pointer;
          color: #024df8;
        }
        .btn_down {
          margin-left: 20px;
        }
      }
      &.f_body {
        .f_name {
          text-align: left;
        }
      }
    }
    .img_list {
      width: 100%;
      height: 120px;
      position: relative;
      overflow: hidden !important;
      .img_btn {
        width: 30px;
        height: 30px;
        border-radius: 30px;
        background-color: #ffffff;
        border: 1px solid #dddddd;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        line-height: 30px;
        cursor: pointer;
        z-index: 10;
        .iconfont {
          color: #aaaaaa;
        }
      }
      .img_btn_able {
        &:hover {
          .iconfont {
            color: #024df8;
          }
        }
      }
      .img_btn_disable {
        cursor: default;
        background-color: #dddddd;
      }
      .prev_img {
        left: 0px;
      }
      .next_img {
        right: 0px;
      }
      .img_ul {
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        .img_item {
          position: relative;
          margin: 0 10px;
          cursor: pointer;
          height: 120px;
          width: 120px;
          border: 1px solid #dddddd;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          &:nth-of-type(1) {
            margin-left: 0;
          }
          &:nth-last-of-type(1) {
            margin-right: 0;
          }
          .img_hover {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: none;
            z-index: 6;
            align-items: center;
            justify-content: center;
            background-color: rgba($color: #000000, $alpha: 0.7);
            .icon_down {
              margin-left: 10px;
              cursor: pointer;
              color: #ffffff;
            }
            .icon_preview {
              cursor: pointer;
              color: #ffffff;
              font-size: 14px;
            }
          }
          &:hover {
            .img_hover {
              display: flex;
            }
            .img_mask {
              display: none;
            }
          }
          img {
            width: 120px;
            height: 120px;
          }
          .img_mask {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 2;
            background-color: rgba($color: #000000, $alpha: 0.3);
          }
          .icon_new {
            font-size: 40px;
          }
        }
      }
    }
    .no_data {
      line-height: 40px;
      text-align: center;
    }
  }
}
</style>