<template>
  <message-bubble :isMine="isMine" :message="message">
    <div class="new-class-require-wrapper" @click="refundOrderClick">
        <div class="left">
          <span class="info" :title="`${customData.studentName}同学的退费申请`">{{customData.studentName}}同学的退费申请</span>
        </div>
        <div class="right">
          <span class="iconfont icon-dengpao"></span>
        </div>
        <span class="see" v-show="!isMine" :class="[btnClass]" @click.stop="refundOrderClick">查看</span>
      </div>
  </message-bubble>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import MessageBubble from "../message-bubble";
export default {
  name: "FileElement",
  props: {
    customData: {
      type: Object,
      required: true,
    },
    message: {
      type: Object,
      required: true,
    },
    isMine: {
      type: Boolean,
    },
  },
  components: {
    MessageBubble,
  },
  computed: {
    ...mapGetters("chatInfo", ["toAccount"]),
    btnClass() {
      let classString = "btn-see-";
      if (this.isMine) {
        classString += "message-send";
      } else {
        classString += "message-received";
      }
      return classString
    }
  },
  methods: {
    ...mapMutations('dialogCont', ['dialogRefundOrderPreviewInfoChange']),
    refundOrderClick(){
      this.dialogRefundOrderPreviewInfoChange({
        show: true,
        customData: this.customData,
        fromAccount: this.toAccount,
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.new-class-require-wrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  padding: 10px;
  &:hover {
    .iconfont {
      color: #024df8;
    }
  }
  .left {
    margin-left: 10px;
    height: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    > span{
      max-width: 140px;
      @include ellipsis;
    }
    .info {
      margin-bottom: 6px;
    }
  }
  .right {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    .iconfont {
      font-size: 22px;
    }
  }
  .see {
    cursor: pointer;
    color: #666666;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .btn-see-message-send{
    left: -60px;
  }
  .btn-see-message-received{
    right: -60px;
  }
}
</style>
