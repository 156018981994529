<template>
  <div class="chat-student-info" v-show="studentParams.show" @click.stop>
    <div v-show="step == 1" class="stu_detail_dialog">
      <div class="content_detail">
        <div class="row_1">
          <div class="row_1_top">
            <div class="row_1_top_c">
              <img
                class="item_img"
                :src="
                  stuDetail.photo
                    ? stuDetail.photo
                    : require('@/assets/image/chat/default_avatar.png')
                "
                alt=""
              />
              <span class="name_sex">
                <span class="stu_name" :title="stuDetail.name">{{
                  stuDetail.name
                }}</span>
                <span
                  class="iconfont icon-nan1 icon_sex icon_sex_man"
                  v-show="stuDetail.sex == 1"
                ></span>
                <span
                  class="iconfont icon-nv1 icon_sex icon_sex_women"
                  v-show="stuDetail.sex == 2"
                ></span>
              </span>
            </div>
          </div>
          <div class="row_1_detail">
            <div class="row_1_item">
              <span class="item_label">出生日期</span>
              <span
                class="item_value"
                :title="stuDetail.birthday ? stuDetail.birthday : '暂无'"
                >{{ stuDetail.birthday ? stuDetail.birthday : "暂无" }}</span
              >
            </div>
            <div class="h_line"></div>
            <div class="row_1_item">
              <span class="item_label">就读学校</span>
              <span
                class="item_value"
                :title="stuDetail.schoolName ? stuDetail.schoolName : '暂无'"
                >{{
                  stuDetail.schoolName ? stuDetail.schoolName : "暂无"
                }}</span
              >
            </div>
            <div class="h_line"></div>
            <div class="row_1_item">
              <span class="item_label">年级</span>
              <span class="item_value">{{
                stuDetail.sectionName ? stuDetail.sectionName : "暂无"
              }}</span>
            </div>
            <div class="h_line"></div>
            <div class="row_1_item">
              <span class="item_label">星座</span>
              <span class="item_value">{{
                stuDetail.constellationName
                  ? stuDetail.constellationName
                  : "暂无"
              }}</span>
            </div>
          </div>
        </div>
        <div class="row_2">
          <div class="sub_title">
            <img :src="require('@/assets/image/chat/nature.png')" alt="" />
            <span>孩子性格</span>
          </div>
          <div class="children_nature">
            {{ stuDetail.childNature ? stuDetail.childNature : "暂无" }}
          </div>
        </div>
        <div class="row_3">
          <div class="sub_title">
            <img :src="require('@/assets/image/chat/score.png')" alt="" />
            <span>学生成绩</span>
          </div>
          <div
            class="score_list"
            v-if="stuDetail.studentAcademicRecordList.length > 0"
          >
            <div
              class="score_item"
              v-for="(item, index) in stuDetail.studentAcademicRecordList"
              :key="index"
            >
              <span class="item_time">{{ item.examinationTime }}</span>
              <span class="item_type">{{
                getScoreType(item.examinationType)
              }}</span>
              <span class="item_score"
                >{{ item.subjectScore }} / {{ item.subjectAllScore }}</span
              >
              <span class="item_remarks" :title="item.remarks">{{
                item.remarks
              }}</span>
              <span class="item_opt">
                <span class="btn" @click="singlePaperPreviewClick(item)"
                  >预览</span
                >
              </span>
            </div>
          </div>
          <div class="no_data" v-else>暂无成绩数据</div>
        </div>
        <div class="row_4">
          <div class="sub_title">
            <img :src="require('@/assets/image/chat/course.png')" alt="" />
            <span>排课教务</span>
          </div>
          <div class="edu_course">
            {{
              stuDetail.teacherManagerName
                ? stuDetail.teacherManagerName
                : "暂无"
            }}
          </div>
        </div>
      </div>
    </div>
    <div v-show="step == 2" class="paper_preview_dialog">
      <div class="title">试卷预览</div>
      <span class="go_back" @click="step = 1">
        <span class="iconfont icon-zuojiantou"></span>
        <span>返回</span>
      </span>
      <div class="preview_content">
        <div class="c_row">
          <span class="c_label">考试时间：</span>
          <span class="c_value">{{ previewDetail.examinationTime }}</span>
        </div>
        <div class="c_row">
          <span class="c_label">考试类型：</span>
          <span class="c_value">{{
            getScoreType(previewDetail.examinationType)
          }}</span>
        </div>
        <div class="c_row">
          <span class="c_label">成绩/总分：</span>
          <span class="c_value"
            >{{ previewDetail.subjectScore }} /
            {{ previewDetail.subjectAllScore }}</span
          >
        </div>
        <div class="c_row">
          <span class="c_label">备注说明：</span>
          <span class="c_value">{{ previewDetail.remarks }}</span>
        </div>
        <div class="c_title">试卷：</div>
        <div class="img_list" v-show="previewDetail.imgList.length > 0">
          <div
            class="prev_img img_btn"
            :class="{
              img_btn_disable: curStep == 0,
              img_btn_able: curStep != 0,
            }"
            @click="moveImgListClick('prev')"
          >
            <span class="iconfont icon-zuojiantou"></span>
          </div>
          <div
            class="next_img img_btn"
            :class="{
              img_btn_disable: curStep == maxStep,
              img_btn_able: curStep != maxStep,
            }"
            @click="moveImgListClick('next')"
          >
            <span class="iconfont icon-youjiantou"></span>
          </div>
          <ul class="img_ul" ref="imgUl" :style="[imgUlStyle]">
            <li
              class="img_item img_item_true"
              v-for="(item, index) in previewDetail.imgList"
              :key="index"
            >
              <div class="img_hover">
                <span
                  class="iconfont icon-yulan1 icon_preview"
                  title="预览"
                  @click="imgItemSeeClick(item)"
                ></span>
              </div>
              <img :src="item" alt="" />
              <span class="img_mask"></span>
            </li>
          </ul>
        </div>
        <div class="c_bottom">
          <span class="btn" @click="closeStudentInfoClick">关闭</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getStudentDetail } from "@/api/chat";
export default {
  data() {
    return {
      step: 1,
      stuDetail: {
        studentAcademicRecordList: [],
      },
      previewDetail: {
        imgList: [],
      },
      maxStep: 0,
      curStep: 0,
      imgUrlWidth: "",
      imgListWidth: 584,
      imgUlStyle: {
        left: "0px",
      },
    };
  },
  props: ["studentParams"],
  inject: ["timChat"],
  watch: {
    "studentParams.show"(show) {
      console.log("this.studentParams", this.studentParams);
      if (show) {
        this.step = 1
        this.seeStudentDetailClick();
      }
    },
  },
  components: {},
  computed: {},
  mounted() {},
  methods: {
    closeStudentInfoClick() {
      this.$emit('studentParamsChange')
    },
    seeStudentDetailClick() {
      getStudentDetail({studentId: this.studentParams.studentId, teacherManagerId: null}).then((res) => {
        console.log(res);
        this.stuDetail = res.msg;
      });
    },
    getScoreType(examinationType) {
      switch (Number(examinationType)) {
        case 1:
          return "模拟考试";
        case 2:
          return "期中";
        case 3:
          return "期末";
        case 4:
          return "月考";
        case 5:
          return "随堂练";
      }
    },
    singlePaperPreviewClick(item) {
      this.step = 2;
      item.imgList = []
      if(item.testPapers){
        item.imgList = item.testPapers.split(",");
      }
      this.previewDetail = item;
      this.$nextTick(() => {
        this.imgUrlWidth = this.$refs.imgUl.getBoundingClientRect().width;
        console.log(this.imgUrlWidth);
        if (this.imgUrlWidth > this.imgListWidth) {
          this.maxStep = Math.ceil(
            (this.imgUrlWidth - this.imgListWidth) / 120
          );
        }
      });
      console.log(item);
    },
    moveImgListClick(type) {
      if (this.curStep != 0 && type == "prev") {
        this.curStep--;
      } else if (this.curStep != this.maxStep && type == "next") {
        this.curStep++;
      }
      this.imgUlStyle.left = `-${this.curStep * 140}px`;
    },
    imgItemSeeClick(item) {},
  },
};
</script>
<style lang='scss' scoped>
.chat-student-info {
  width: 600px;
  position: fixed;
  top: 30px;
  right: 2px;
  height: calc(100vh - 32px);
  z-index: 100;
  overflow-y: auto;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  box-shadow: 0px 0px 8px 0px rgba(7, 1, 1, 0.15);
  .content_detail {
    .row_1 {
      width: 100%;
      height: 220px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: url("../../../../../assets/image/chat/student_info_bg.png") 100%
        100% no-repeat;
      .row_1_top {
        margin-top: 30px;
        .row_1_top_c {
          display: flex;
          align-items: center;
          flex-direction: column;
          color: #ffffff;
          .item_img {
            width: 66px;
            height: 66px;
            border-radius: 66px;
          }
          .name_sex {
            margin-top: 10px;
            .stu_name {
              display: inline-block;
              max-width: 180px;
              @include ellipsis;
              margin-right: 6px;
            }
            .icon_sex_women {
              font-size: 14px;
              color: #e81123;
            }
            .icon_sex_man {
              font-size: 14px;
              color: #024df8;
            }
          }
        }
      }
      .row_1_detail {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 20px;
      }
      .h_line {
        height: 28px;
        width: 1px;
        background-color: #ffffff;
      }
      .row_1_item {
        flex: 1;
        color: #ffffff;
        width: 0;
        .item_label {
          width: 100%;
          display: inline-block;
          text-align: center;
          color: #dddddd;
        }
        .item_value {
          display: inline-block;
          text-align: center;
          width: 100%;
          padding: 0 2px;
          margin-top: 16px;
          @include ellipsis;
        }
      }
    }
    .sub_title {
      height: 40px;
      display: flex;
      align-items: center;
      img {
        width: 20px;
        height: 20px;
      }
      span {
        margin-left: 10px;
      }
    }
    .row_2 {
      padding: 20px;
      border: 1px solid #eeeeee;
      .children_nature {
        line-height: 20px;
        color: #666666;
      }
    }
    .row_3 {
      padding: 20px;
      border: 1px solid #eeeeee;
      .score_item {
        height: 40px;
        display: flex;
        color: #666666;
        align-items: center;
        > span {
          padding: 0 4px;
          width: 0;
          @include ellipsis;
        }
        .item_remarks {
          flex: 1;
          width: 0;
        }
        .item_time {
          width: 140px;
        }
        .item_type {
          width: 60px;
        }
        .item_score {
          width: 88px;
        }
        .item_opt {
          width: 60px;
          .btn {
            display: inline-block;
            font-size: 12px;
            cursor: pointer;
            padding: 4px 10px;
            background: #ffffff;
            border: 1px solid #dddddd;
            border-radius: 15px;
            &:hover {
              color: #024df8;
              border-color: #024df8;
            }
          }
        }
      }
      .no_data {
        line-height: 20px;
        color: #666666;
      }
    }
    .row_4 {
      padding: 20px;
      border: 1px solid #eeeeee;
      .edu_course {
        line-height: 20px;
        color: #666666;
      }
    }
  }
  .paper_preview_dialog {
    position: relative;
    .title {
      margin-top: 10px;
      line-height: 40px;
      font-size: 16px;
      font-weight: bolder;
      text-align: center;
    }
    .go_back {
      color: #024DF8;
      position: absolute;
      left: 10px;
      top: 10px;
      cursor: pointer;
      font-size: 14px;
    }
    .preview_content {
      padding: 20px;
      .c_row {
        margin-top: 20px;
        display: flex;
        padding: 4px 0;
        .c_label {
          line-height: 20px;
          color: #666666;
          margin-right: 10px;
          width: 76px;
        }
        .c_value {
          flex: 1;
          line-height: 20px;
          color: #222222;
        }
      }
    }
    .c_title {
      margin-top: 20px;
      line-height: 20px;
      color: #666666;
    }
    .img_list {
      margin-top: 20px;
      width: 100%;
      height: 120px;
      position: relative;
      overflow: hidden;
      .img_btn {
        width: 30px;
        height: 30px;
        border-radius: 30px;
        background-color: #ffffff;
        border: 1px solid #dddddd;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        line-height: 30px;
        cursor: pointer;
        z-index: 10;
        .iconfont {
          color: #aaaaaa;
        }
      }
      .img_btn_able {
        &:hover {
          .iconfont {
            color: #024df8;
          }
        }
      }
      .img_btn_disable {
        cursor: default;
        background-color: #dddddd;
      }
      .prev_img {
        left: 0px;
      }
      .next_img {
        right: 0px;
      }
      .img_ul {
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        .img_item {
          position: relative;
          margin: 0 10px;
          cursor: pointer;
          height: 120px;
          width: 120px;
          border: 1px solid #dddddd;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          &:nth-of-type(1) {
            margin-left: 0;
          }
          &:nth-last-of-type(1) {
            margin-right: 0;
          }
          .img_hover {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: none;
            z-index: 6;
            align-items: center;
            justify-content: center;
            background-color: rgba($color: #000000, $alpha: 0.7);
            .icon_del {
              margin-left: 10px;
              cursor: pointer;
              color: #ffffff;
            }
            .icon_preview {
              cursor: pointer;
              color: #ffffff;
              font-size: 14px;
            }
          }
          &:hover {
            .img_hover {
              display: flex;
            }
            .img_mask {
              display: none;
            }
          }
          img {
            width: 120px;
            height: 120px;
          }
          .img_mask {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 2;
            background-color: rgba($color: #000000, $alpha: 0.3);
          }
          .icon_new {
            font-size: 40px;
          }
        }
      }
    }
    .c_bottom {
      margin-top: 20px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      .btn {
        padding: 8px 18px;
        cursor: pointer;
        background-color: #024DF8;
        border-radius: 6px;
        color: #ffffff;
      }
    }
  }
}
</style>