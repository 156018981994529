<template>
  <div class="login">
    <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form">
      <h3 class="title">皓之学在线运营管理平台</h3>
      <el-form-item prop="username">
        <el-input v-model="loginForm.username" type="text" auto-complete="off" placeholder="账号">
          <svg class="icon username-icon" slot="prefix" aria-hidden="true">
              <use xlink:href="#icon-xingmingyonghumingnicheng"></use>
          </svg>
        </el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          v-model="loginForm.password"
          type="password"
          auto-complete="new-password"
          placeholder="密码"
          @keyup.enter.native="handleLogin"
        >
          <svg class="icon" slot="prefix" aria-hidden="true">
              <use xlink:href="#icon-mima1"></use>
          </svg>
        </el-input>
      </el-form-item>
      <el-form-item prop="code">
        <el-input
          v-model="loginForm.code"
          auto-complete="off"
          placeholder="验证码"
          style="width: 63%"
          @keyup.enter.native="handleLogin"
        >
          <svg class="icon" slot="prefix" aria-hidden="true">
              <use xlink:href="#icon-anquan"></use>
          </svg>
        </el-input>
        <div class="login-code">
          <img :src="codeUrl" @click="getCode" class="login-code-img"/>
        </div>
      </el-form-item>
      <el-checkbox v-model="loginForm.rememberme" style="margin:0px 0px 25px 0px;">记住密码</el-checkbox>
      <el-form-item style="width:100%;">
        <el-button
          size="medium"
          type="primary"
          style="width:100%;"
          @click.native.prevent="handleLogin"
        >
          <span>登 录</span>
        </el-button>
      </el-form-item>
    </el-form>
    <!--  底部  -->
    <div class="el-login-footer">
      <span>Copyright © 2019-2020 皓之学在线 All Rights Reserved.</span>

    </div>
  </div>
</template>

<script>
import { getCode, login} from "@/api/login"
import Cookies from "js-cookie";
import { Base64 } from 'js-base64'

export default {
  name: "Login",
  data() {
    return {
      codeUrl: "",
      loginForm: {
        username: "test2",
        password: "1",
        rememberme: false,
        code: "1",
        codeToken: ""
      },
      loginRules: {
        username: [
          { required: true, trigger: "blur", message: "用户名不能为空" }
        ],
        password: [
          { required: true, trigger: "blur", message: "密码不能为空" }
        ],
        code: [{ required: true, trigger: "blur", message: "验证码不能为空" }]
      },
      loading: false,
    };
  },
  created() {
    this.getCode();
    this.getCookie();
  },
  methods: {
    getCode() {
      getCode().then(res => {
        this.codeUrl = "data:image/gif;base64," + res.img;
        this.loginForm.codeToken = res.codeToken;
      });
    },
    getCookie() {
      const username = Cookies.get("username");
      const password = Cookies.get("password");
      const rememberme = Boolean(Cookies.get('rememberme'))
      this.loginForm = {
        username: username === undefined ? '' : username,
        password: password === undefined ? '' : Base64.decode(password),
        rememberme: rememberme === undefined ? false : Boolean(rememberme)
      };
    },
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          // this.loading = true
          if (this.loginForm.rememberme) {
            Cookies.set("username", this.loginForm.username, { expires: 30 })
            Cookies.set("password", Base64.encode(this.loginForm.password), { expires: 30 })
            Cookies.set("rememberme", this.loginForm.rememberme, { expires: 30 })
          } else {
            Cookies.remove("username")
            Cookies.remove("password")
            Cookies.remove('rememberme')
          }
          login({
            username: this.loginForm.username,
            password: this.loginForm.password,
            code: this.loginForm.code,
            codeToken: this.loginForm.codeToken
          }).then(res => {
            if(res.code == 0){
              const { token, sysChatId, userSig } = res
              Cookies.set('token', token)
              Cookies.set('sysChatId', sysChatId)
              Cookies.set('userSig', userSig)
              this.$router.push({
                path: '/index'
              })
            }else{
              this.msgError('登录失败，请仔细核查信息！')
              this.getCode();
            }
          })
        }
      });
    }
  }
};
</script>

<style rel="stylesheet/scss" lang="scss">
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-image: url("../assets/image/login-background.jpg");
  background-size: cover;
}
.title {
  margin: 0px auto 30px auto;
  text-align: center;
  color: #707070;
  font-weight: 900;
  font-size: 20px;
}

.login-form {
  border-radius: 6px;
  background: #ffffff;
  width: 400px;
  padding: 25px 25px 5px 25px;
  .el-input {
    height: 38px;
    input {
      height: 38px;
    }
  }
  .input-icon {
    height: 39px;
    width: 14px;
    margin-left: 2px;
  }
}
.login-tip {
  font-size: 13px;
  text-align: center;
  color: #bfbfbf;
}
.login-code {
  width: 33%;
  height: 38px;
  float: right;
  img {
    cursor: pointer;
    vertical-align: middle;
  }
}
.el-login-footer {
  height: 40px;
  line-height: 40px;
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #fff;
  font-family: Arial;
  font-size: 12px;
  letter-spacing: 1px;
}
.login-code-img {
  height: 38px;
  width: 100%;
}
</style>
