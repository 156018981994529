<template>
  <div class="dialog_mask" v-show="studentQuestionParams.show">
    <dialog-file-view-private
      :dialogFileViewPrivateInfo="dialogFileViewPrivateInfo"
      @closeFileView="closeFileView"
    ></dialog-file-view-private>
    <div class="dialog_question" v-show="step == 1">
      <div class="dialog_question_header">
        <div class="dialog_question_title">
          <span>问题列表</span>
        </div>
        <span
          class="add_btn"
          @click="addQuestionClick"
          v-if="timChat.roleType == 2"
          >新增问题</span
        >
        <span
          class="iconfont icon-error icon_close"
          @click="studentQuestionParamsChange"
        ></span>
      </div>
      <div class="dialog_question_content">
        <div class="question_list" v-show="questionInfo.list.length > 0">
          <div class="q_item q_item_head">
            <span class="q_item_content">问题内容</span>
            <span class="q_item_createTime">上传时间</span>
            <span class="q_item_opt">操作</span>
          </div>
          <div
            class="q_item"
            v-for="(item, index) in questionInfo.list"
            :key="index"
          >
            <span
              class="q_item_content"
              :title="item.questionContent ? item.questionContent : '暂无'"
              >{{ item.questionContent ? item.questionContent : "暂无" }}</span
            >
            <span class="q_item_createTime">{{ item.createTime }}</span>
            <span class="q_item_opt">
              <span
                class="btn btn_see"
                @click="questionEditOrSeeClick(item, false)"
                >查看</span
              >
            </span>
          </div>
        </div>
        <div class="no_data" v-show="questionInfo.list.length == 0">
          <img
            class="no_data_img"
            :src="require('@/assets/image/chat/no_data_1.jpg')"
            alt=""
          />
          <span class="txt">暂无问题</span>
        </div>
      </div>
      <div class="dialog_bottom">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="questionInfo.total"
          prev-text="上一页"
          next-text="下一页"
          size="small"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <div class="dialog_question" v-show="step == 2">
      <div class="dialog_question_header">
        <div class="dialog_question_title">
          <span>问题整理</span>
        </div>
        <span class="go_back" @click="step = 1">
          <span class="iconfont icon-zuojiantou"></span>
          <span>返回</span>
        </span>
        <span class="iconfont icon-error icon_close" @click="step = 1"></span>
      </div>
      <div class="dialog_question_content">
        <div class="preview_label">文字描述</div>
        <div>
          <el-input
            type="textarea"
            :rows="4"
            placeholder="简单描述一下本节课你想让老师讲的内容"
            v-model="questionDialog.questionContent"
            resize="none"
          >
          </el-input>
        </div>
        <div class="preview_label">添加试卷：</div>
        <div class="img_list">
          <div
            class="img_item img_item_true"
            v-for="(item, index) in questionDialog.imgList"
            :key="index"
          >
            <div class="img_hover">
              <span
                class="iconfont icon-yulan1 icon_preview"
                title="预览"
                @click="imgItemSeeClick(item, index)"
              ></span>
              <span
                class="iconfont icon-shanchu4 icon_del"
                title="删除"
                @click="imgItemDelClick(index)"
              ></span>
            </div>
            <img :src="item.fileUrl" alt="" />
          </div>
          <div class="img_item" @click="imgSelectClick">
            <span class="iconfont icon-danyehuaban icon_new"></span>
            <input
              type="file"
              ref="uploadImg"
              @change="imgSelectChange"
              style="display: none"
              accept="image/png, image/jpeg, image/gif, image/jpg"
            />
          </div>
        </div>
        <div class="preview_label">文件上传：</div>
        <div>
          <div class="file_list">
            <div
              class="file_item"
              v-for="(item, index) in questionDialog.fileList"
              :key="index"
            >
              <span class="file_item_left">
                <span class="file_icon" :class="[item.eatname]">
                  <template
                    v-if="
                      item.extname == 'jpg' ||
                      item.extname == 'jpeg' ||
                      item.extname == 'png' ||
                      item.extname == 'bmp'
                    "
                  >
                    <svg-icon icon-class="img_2"></svg-icon>
                  </template>
                  <template
                    v-if="item.extname == 'doc' || item.extname == 'docx'"
                  >
                    <svg-icon icon-class="word"></svg-icon>
                  </template>
                  <template
                    v-if="item.extname == 'ppt' || item.extname == 'pptx'"
                  >
                    <svg-icon icon-class="ppt"></svg-icon>
                  </template>
                  <template v-if="item.extname == 'pdf'">
                    <svg-icon icon-class="pdf"></svg-icon>
                  </template>
                  <template
                    v-if="item.extname == 'xlsx' || item.extname == 'xls'"
                  >
                    <svg-icon icon-class="excel"></svg-icon>
                  </template>
                  <template v-if="item.extname == 'txt'">
                    <svg-icon icon-class="txt"></svg-icon>
                  </template>
                </span>
                <span class="file_name" :title="item.fileName">{{
                  item.fileName
                }}</span>
              </span>
              <span class="file_item_opt">
                <span
                  class="btn btn_del"
                  v-show="timChat.roleType == 1"
                  @click="fileItemDelClick(index)"
                  >删除</span
                >
                <span class="btn btn_see" @click="imgItemSeeClick(item)"
                  >查看</span
                >
                <span
                  class="btn btn_down"
                  v-show="timChat.roleType == 2"
                  @click="downClick(item)"
                  >下载</span
                >
              </span>
            </div>
          </div>
          <div class="file_upload_tip">
            <span class="file_upload_btn" @click="fileUploadClick">
              <span class="iconfont icon-danyehuaban icon_new"></span>
              <span>上传文件</span>
              <input
                type="file"
                ref="uploadFile"
                @change="fileSelectChange"
                style="display: none"
                accept=".doc,.docx,.pdf,.ppt,.pptx,.xls,.xlsx"
              />
            </span>
            <span class="size_tip">DOC、DOCX、PDF、格式 文件大小不超过4M</span>
          </div>
        </div>
      </div>
      <div class="dialog_bottom">
        <span @click="confirmAddQuestionClick" class="btn btn_publish"
          >确定</span
        >
        <span @click="step = 1" class="btn btn_cancel">取消</span>
      </div>
    </div>
    <div class="dialog_question dialog_preview" v-show="step == 3">
      <div class="dialog_question_header">
        <div class="dialog_question_title">
          <span>问题整理</span>
        </div>
        <span class="go_back" @click="step = 1">
          <span class="iconfont icon-zuojiantou"></span>
          <span>返回</span>
        </span>
        <span class="iconfont icon-error icon_close" @click="step = 1"></span>
      </div>
      <div class="dialog_question_content">
        <div class="preview_label">描述内容：</div>
        <div class="preview_value">
          {{
            questionDialog.questionContent
              ? questionDialog.questionContent
              : "暂无"
          }}
        </div>
        <div class="preview_label">上传试卷：</div>
        <div class="img_list" v-show="questionDialog.imgList.length > 0">
          <div
            class="prev_img img_btn"
            :class="{
              img_btn_disable: curStep == 0,
              img_btn_able: curStep != 0,
            }"
            @click="moveImgListClick('prev')"
          >
            <span class="iconfont icon-zuojiantou"></span>
          </div>
          <div
            class="next_img img_btn"
            :class="{
              img_btn_disable: curStep == maxStep,
              img_btn_able: curStep != maxStep,
            }"
            @click="moveImgListClick('next')"
          >
            <span class="iconfont icon-youjiantou"></span>
          </div>
          <ul class="img_ul" ref="imgUl" :style="[imgUlStyle]">
            <li
              class="img_item img_item_true"
              v-for="(item, index) in questionDialog.imgList"
              :key="index"
            >
              <div class="img_hover">
                <span
                  class="iconfont icon-yulan1 icon_preview"
                  title="预览"
                  @click="imgItemSeeClick(item, index)"
                ></span>
              </div>
              <img :src="item.fileUrl" alt="" />
              <span class="img_mask"></span>
            </li>
          </ul>
        </div>
        <div class="no_data" v-show="questionDialog.imgList.length == 0">
          <span>暂无数据</span>
        </div>
        <div class="preview_label">上传文件：</div>
        <div>
          <div class="file_list">
            <div
              class="file_item"
              v-for="(item, index) in questionDialog.fileList"
              :key="index"
            >
              <span class="file_item_left">
                <span class="file_icon" :class="[item.eatname]">
                  <template
                    v-if="
                      item.extname == 'jpg' ||
                      item.extname == 'jpeg' ||
                      item.extname == 'png' ||
                      item.extname == 'bmp'
                    "
                  >
                    <svg-icon icon-class="img_2"></svg-icon>
                  </template>
                  <template
                    v-if="item.extname == 'doc' || item.extname == 'docx'"
                  >
                    <svg-icon icon-class="word"></svg-icon>
                  </template>
                  <template
                    v-if="item.extname == 'ppt' || item.extname == 'pptx'"
                  >
                    <svg-icon icon-class="ppt"></svg-icon>
                  </template>
                  <template v-if="item.extname == 'pdf'">
                    <svg-icon icon-class="pdf"></svg-icon>
                  </template>
                  <template
                    v-if="item.extname == 'xlsx' || item.extname == 'xls'"
                  >
                    <svg-icon icon-class="excel"></svg-icon>
                  </template>
                  <template v-if="item.extname == 'txt'">
                    <svg-icon icon-class="txt"></svg-icon>
                  </template>
                </span>
                <span class="file_name" :title="item.fileName">{{
                  item.fileName
                }}</span>
              </span>
              <span class="file_item_opt">
                <span class="btn btn_see" @click="imgItemSeeClick(item)"
                  >查看</span
                >
                <span class="btn btn_down" @click="downClick(item)">下载</span>
              </span>
            </div>
            <div class="no_data" v-show="questionDialog.fileList.length == 0">
              <span>暂无数据</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dialogFileViewPrivate from "../dialog-file-view-private.vue"
import * as dayjs from "dayjs";
import {
  studentQuestionPage,
  getDeleteQuestionById,
  getQuestionById,
  saveOrUpdateQuestion,
  fileUpload,
} from "@/api/chat";
export default {
  data() {
    return {
      questionDialog: {
        id: null,
        isCanEdit: false,
        imgList: [],
        fileList: [],
        questionContent: "",
      },
      dialogFileViewPrivateInfo: {
        show: false,
        type: "img",
        src: "",
      },
      step: 1,
      questionList: [],
      listParams: {
        pageNo: 1,
        pageSize: 10,
      },
      questionInfo: {
        list: [],
        total: 0,
      },
      createUserId: "", //学生或家长id
      teacherId: "",
      maxStep: 0,
      curStep: 0,
      imgUrlWidth: "",
      imgListWidth: 584,
      imgUlStyle: {
        left: "0px",
      },
    };
  },
  inject: ["timChat"],
  props: ["studentQuestionParams"],
  watch: {
    "studentQuestionParams.show"(show) {
      if (show) {
        this.createUserId = this.studentQuestionParams.studentId;
        this.teacherId = this.studentQuestionParams.teacherId;
        this.step = 1;
        this.getQuestionList();
      }
    },
  },
  components: { dialogFileViewPrivate },
  computed: {
    roleType() {
      return this.timChat.roleType;
    },
  },
  mounted() {},
  methods: {
    getQuestionList() {
      studentQuestionPage(
        {
          createUserId: this.createUserId,
          teacherId: this.teacherId,
          ...this.listParams,
        },
        this.timChat.roleType
      ).then((res) => {
        let list = res.rows.rows;
        list.forEach((item) => {
          item.createTime = dayjs(item.createTime).format(
            "YYYY/MM/DD HH:mm:ss"
          );
        });
        this.questionInfo.list = list;
        this.questionInfo.total = res.rows.total;
      });
    },
    questionDelClick(item) {
      getDeleteQuestionById(item.id).then((res) => {
        if (res.flag == "success") {
          this.msgSuccess("删除成功！");
        }
        this.getQuestionList();
      });
    },
    questionEditOrSeeClick(item, edit) {
      this.questionDialog.id = item.id;
      if (edit) {
        this.step = 2;
      } else {
        this.step = 3;
        this.maxStep = 0;
        this.curStep = 0;
        this.imgUrlWidth = "";
        this.imgListWidth = 584;
        this.imgUlStyle = {
          left: "0px",
        };
      }
      getQuestionById({ id: this.questionDialog.id }).then(
        (res) => {
          if (res.code == 0) {
            const { questionContent, xmtStudentQuestionMaterialList } = res.rows;
            this.questionDialog.questionContent = questionContent;
            this.questionDialog.imgList = [];
            this.questionDialog.fileList = [];
            xmtStudentQuestionMaterialList.forEach((item) => {
              let index = item.fileName.lastIndexOf(".");
              let extname = item.fileName.substring(index + 1);
              if (item.fileType == 1) {
                this.questionDialog.imgList.push({
                  fileName: item.fileName,
                  fileType: 1,
                  fileUrl: item.fileUrl,
                  extname,
                });
              } else if (item.fileType == 2) {
                this.questionDialog.fileList.push({
                  fileName: item.fileName,
                  fileUrl: item.fileUrl,
                  fileType: 2,
                  extname,
                });
              }
            });
            if (this.step == 3) {
              this.$nextTick(() => {
                this.imgUrlWidth =
                  this.$refs.imgUl.getBoundingClientRect().width;
                console.log(this.imgUrlWidth);
                if (this.imgUrlWidth > this.imgListWidth) {
                  this.maxStep = Math.ceil(
                    (this.imgUrlWidth - this.imgListWidth) / 120
                  );
                }
              });
            }
          }
        }
      );
    },
    handleCurrentChange(page) {
      this.listParams.pageNo = page;
      this.getQuestionList();
    },
    studentQuestionParamsChange() {
      this.$emit("studentQuestionParamsChange");
    },
    closeFileView() {
      this.dialogFileViewPrivateInfo.show = false;
    },
    confirmAddQuestionClick() {
      let arr = [];
      if(!this.questionDialog.questionContent && this.questionDialog.imgList.length == 0 && !this.questionDialog.fileList.length){
        this.msgWarn('内容不能为空！')
        return
      }
      if(this.questionDialog.questionContent.length > 1000){
        this.msgWarn('文字描述不能超过1000个字符！请重新输入')
        return
      }
      this.questionDialog.imgList.forEach((item) => {
        arr.push({
          fileName: item.fileName,
          fileType: 1,
          fileUrl: item.fileUrl,
        });
      });
      this.questionDialog.fileList.forEach((item) => {
        arr.push({
          fileName: item.fileName,
          fileType: 2,
          fileUrl: item.fileUrl,
        });
      });
      if(this.saveFlag){
        return
      }
      this.saveFlag = true
      saveOrUpdateQuestion({
        id: this.questionDialog.id,
        createUserId: this.createUserId,
        teacherId: this.teacherId,
        questionContent: this.questionDialog.questionContent,
        xmtStudentQuestionMaterialList: arr,
      }).then((res) => {
        this.saveFlag = false
        if (res.flag == "success") {
          this.msgSuccess("问题添加成功！");
          this.step = 1;
          this.getQuestionList();
        }
      }).catch( err => {
        this.saveFlag = false
      });
    },
    imgItemDelClick(index) {
      this.questionDialog.imgList.splice(index, 1);
    },
    fileItemDelClick(index) {
      this.questionDialog.fileList.splice(index, 1);
    },
    fileUploadClick() {
      this.$refs.uploadFile.value = "";
      this.$refs.uploadFile.click();
    },
    imgItemSeeClick(item, index) {
      if (
        ["doc", "docx", "ppt", "pptx", "xlsx", "xls"].includes(item.extname.toLowerCase())
      ) {
        this.dialogFileViewPrivateInfo = {
          show: true,
          type: "file",
          src: `https://view.officeapps.live.com/op/view.aspx?src=${
            item.fileUrl ? item.fileUrl : item.url
          }`,
        };
      } else if (["pdf"].includes(item.extname.toLowerCase())) {
        this.dialogFileViewPrivateInfo = {
          show: true,
          type: "file",
          src: `${this.webUrl}/pdf/web/viewer.html?file=${
            item.fileUrl ? item.fileUrl : item.url
          }`,
        };
      } else if (["png", "jpg", "bmp", "jpeg", "gif"].includes(item.extname.toLowerCase())) {
        this.dialogFileViewPrivateInfo = {
          show: true,
          type: "img",
          src: item.fileUrl ? item.fileUrl : item.url,
          curIndex: index,
          imgList: this.questionDialog.imgList.map((item) => {
            return item.fileUrl ? item.fileUrl : item.url;
          }),
        };
      } else {
        this.msgWarn("该文件格式不支持预览！");
        return;
      }
    },
    downClick(item) {
    },
    imgSelectClick() {
      this.$refs.uploadImg.value = "";
      this.$refs.uploadImg.click();
    },
    imgSelectChange() {
      if (
        !/\.(png|jpg|gif|jpeg|webp)$/.test(this.$refs.uploadImg.files[0].name)
      ) {
        this.msgWarn("只能是图片格式的文件！");
        return;
      }
      let formData = new FormData();
      formData.append("file", this.$refs.uploadImg.files[0]);
      fileUpload(formData).then((uploadRes) => {
        if (uploadRes[0].url) {
          let index = uploadRes[0].originalName.lastIndexOf(".");
          let extname = uploadRes[0].originalName.substring(index + 1);
          this.questionDialog.imgList.push({
            fileUrl: uploadRes[0].url,
            fileName: uploadRes[0].originalName,
            fileType: 1, //1 图片  2 文件
            extname,
          });
        }
      });
    },
    fileSelectChange() {
      if (this.$refs.uploadFile.files[0].size / 1024 / 1024 > 4) {
        this.msgWarn("文件的大小不能超过4M！");
        return;
      }
      let formData = new FormData();
      formData.append("file", this.$refs.uploadFile.files[0]);
      fileUpload(formData).then((uploadRes) => {
        if (uploadRes[0].url) {
          let index = uploadRes[0].originalName.lastIndexOf(".");
          let extname = uploadRes[0].originalName.substring(index + 1);
          this.questionDialog.fileList.push({
            fileUrl: uploadRes[0].url,
            fileName: uploadRes[0].originalName,
            fileType: 1, //1 图片  2 文件
            extname,
          });
        }
      });
    },
    addQuestionClick() {
      this.step = 2;
      this.questionDialog = {
        id: null,
        isCanEdit: false,
        imgList: [],
        fileList: [],
        questionContent: "",
      };
    },
    moveImgListClick(type) {
      if (this.curStep != 0 && type == "prev") {
        this.curStep--;
      } else if (this.curStep != this.maxStep && type == "next") {
        this.curStep++;
      }
      this.imgUlStyle.left = `-${this.curStep * 140}px`;
    },
  },
};
</script>
<style lang='scss' scoped>
.dialog_mask {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: fixed;
  background-color: rgba($color: #000000, $alpha: 0.5);
  z-index: 1000;
  .dialog_question {
    max-height: 80%;
    min-height: 500px;
    width: 600px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    .dialog_question_header {
      position: relative;
      .dialog_question_title {
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
      }
      .icon_close {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
      }
      .add_btn {
        position: absolute;
        top: 18px;
        right: 120px;
        color: #024df8;
        cursor: pointer;
      }
      .go_back {
        color: #024df8;
        position: absolute;
        left: 18px;
        top: 14px;
        cursor: pointer;
        font-size: 14px;
      }
    }
    .dialog_question_content {
      margin: 0 10px 10px;
      padding: 0 10px 10px;
      flex: 1;
      overflow-y: auto;
      .question_list {
        .q_item {
          display: flex;
          height: 40px;
          align-items: center;
          border-bottom: 1px solid #eeeeee;
          .q_item_content {
            flex: 1;
            @include ellipsis;
          }
          .q_item_createTime {
            width: 160px;
            text-align: center;
          }
          .q_item_opt {
            width: 140px;
            text-align: center;
            .btn {
              cursor: pointer;
            }
            .btn_edit {
              color: #024df8;
            }
            .btn_see {
              color: #024df8;
            }
            .btn_del {
              color: #024df8;
              margin-left: 20px;
            }
          }
        }
      }
      .preview_label {
        line-height: 40px;
      }
      .img_list {
        width: 100%;
        min-height: 120px;
        overflow: auto;
        .img_item {
          position: relative;
          margin: 0 0 10px 10px;
          float: left;
          cursor: pointer;
          height: 100px;
          width: 100px;
          border: 1px solid #dddddd;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          .img_hover {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: none;
            z-index: 6;
            align-items: center;
            justify-content: center;
            background-color: rgba($color: #000000, $alpha: 0.7);
            .icon_del {
              margin-left: 10px;
              cursor: pointer;
              color: #ffffff;
            }
            .icon_preview {
              cursor: pointer;
              color: #ffffff;
              font-size: 14px;
            }
          }
          &:hover {
            .img_hover {
              display: flex;
            }
          }
          img {
            width: 100px;
            height: 100px;
          }
          .icon_new {
            font-size: 40px;
          }
        }
      }
      .file_list {
        overflow-y: auto;
        min-height: 40px;
        .file_item {
          display: flex;
          height: 30px;
          align-items: center;
          .file_item_left {
            flex: 1;
            align-items: center;
            display: flex;
            overflow: hidden;
            .file_icon {
              width: 20px;
              height: 20px;
            }
            .file_name {
              flex: 1;
              padding: 0 10px;
              @include ellipsis;
            }
          }
          .file_item_opt {
            width: 160px;
            text-align: center;
            .btn {
              margin: 0 10px;
              color: #024df8;
              cursor: pointer;
            }
          }
        }
      }
      .file_upload_tip {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .file_upload_btn {
          cursor: pointer;
          margin: 10px 0;
        }
        .icon_new {
          font-weight: 700;
          color: #024df8;
        }
        .size_tip {
          margin-top: 6px;
          font-size: 12px;
          color: #aaaaaa;
        }
      }
      .no_data {
        @include no_data;
      }
    }
    .dialog_bottom {
      height: 40px;
      text-align: center;
      line-height: 40px;
      margin-bottom: 20px;
      .btn {
        cursor: pointer;
        border-radius: 6px;
        padding: 6px 16px;
      }
      .btn_publish {
        background-color: #024df8;
        color: #ffffff;
      }
      .btn_cancel {
        margin-left: 40px;
        border: 1px solid #dddddd;
        color: #666666;
      }
    }
  }
  .dialog_preview {
    .preview_value {
      line-height: 24px;
      text-indent: 20px;
    }
    .img_list {
      margin-top: 20px;
      width: 100%;
      height: 120px;
      position: relative;
      overflow: hidden !important;
      .img_btn {
        width: 30px;
        height: 30px;
        border-radius: 30px;
        background-color: #ffffff;
        border: 1px solid #dddddd;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        line-height: 30px;
        cursor: pointer;
        z-index: 10;
        .iconfont {
          color: #aaaaaa;
        }
      }
      .img_btn_able {
        &:hover {
          .iconfont {
            color: #024df8;
          }
        }
      }
      .img_btn_disable {
        cursor: default;
        background-color: #dddddd;
      }
      .prev_img {
        left: 0px;
      }
      .next_img {
        right: 0px;
      }
      .img_ul {
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        .img_item {
          position: relative;
          margin: 0 10px;
          cursor: pointer;
          height: 120px;
          width: 120px;
          border: 1px solid #dddddd;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          &:nth-of-type(1) {
            margin-left: 0;
          }
          &:nth-last-of-type(1) {
            margin-right: 0;
          }
          .img_hover {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: none;
            z-index: 6;
            align-items: center;
            justify-content: center;
            background-color: rgba($color: #000000, $alpha: 0.7);
            .icon_del {
              margin-left: 10px;
              cursor: pointer;
              color: #ffffff;
            }
            .icon_preview {
              cursor: pointer;
              color: #ffffff;
              font-size: 14px;
            }
          }
          &:hover {
            .img_hover {
              display: flex;
            }
            .img_mask {
              display: none;
            }
          }
          img {
            width: 120px;
            height: 120px;
          }
          .img_mask {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 2;
            background-color: rgba($color: #000000, $alpha: 0.3);
          }
          .icon_new {
            font-size: 40px;
          }
        }
      }
    }
    .no_data {
      line-height: 40px;
      text-align: center;
    }
  }
}
</style>