
<template>
  <div>
    <div class="main sys-index">
      <div class="app_left">
        <div class="logo">
          <!-- <img :src="logo" alt /> -->
        </div>
        <div class="menu">
          <el-menu
            @select="handleMnenuSelect"
            :default-active="$route.path"
            background-color="#545c64"
            text-color="#fff"
            active-text-color="#1890ff"
            :unique-opened="true"
          >
            <template v-for="(item, index) in menuList">
              <menu-item :item="item" :key="index"></menu-item>
            </template>
          </el-menu>
        </div>
      </div>
      <div class="app_right">
        <div class="header">
          <div class="header-left">
            <!--<span @click="isCollapse = false">展开</span>
            <span @click="isCollapse = true">关闭</span>-->
          </div>
          <div class="header-right">
            <div class="userinfo">
              <!-- <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-user" />
              </svg> -->
              <el-upload
                style="width: 35px; height: 35px"
                :action="fileUpload"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
                accept="image/jpeg, image/png/"
              >
                <img
                  v-if="userInfo.photo"
                  :src="userInfo.photo"
                  style="width: 35px; height: 35px; border-radius: 50%"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <span style="margin-left: 15px">欢迎您，</span>
              <span :title="userInfo.username">{{ userInfo.username }}</span>
              <span :title="userInfo.name">({{ userInfo.name }})</span>
            </div>
            <div class="modifiy-passord" @click="modifyPwDialogVisible = true">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-mima" />
              </svg>
              <span>修改密码</span>
            </div>
            <div class="logout" @click="logoutClick">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-tuichudenglu1" />
              </svg>
              <span>退出登录</span>
            </div>
          </div>
        </div>
        <div class="content">
          <div class="breadcrumb">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item>首页</el-breadcrumb-item>
              <el-breadcrumb-item
                v-for="(item, index) in breadList"
                :key="index"
                >{{ item }}</el-breadcrumb-item
              >
            </el-breadcrumb>
          </div>
          <div class="content-view">
            <router-view :key="isRouterShow" />
          </div>
        </div>
        <div class="footer">
          <div>Copyright © 2020 皓之学在线运营管理平台 All Rights Reserved</div>
          <div>Version 1.0.0</div>
        </div>
      </div>
      <el-dialog
        title="修改密码"
        :visible.sync="modifyPwDialogVisible"
        width="440px"
        center
        custom-class="md-pw-dialog"
      >
        <div class="dialog-content md-pw-content">
          <el-form
            :model="mdPwRuleForm"
            status-icon
            :rules="mdPwRules"
            ref="mdPwRules"
            label-width="100px"
          >
            <el-form-item label="原密码" prop="nowPass">
              <el-input v-model="mdPwRuleForm.nowPass" size="small"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="pass">
              <el-input
                type="password"
                v-model="mdPwRuleForm.pass"
                autocomplete="off"
                size="small"
              ></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="checkPass">
              <el-input
                type="password"
                v-model="mdPwRuleForm.checkPass"
                autocomplete="off"
                size="small"
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button @click="modifyPwDialogVisible = false" size="small"
            >取 消</el-button
          >
          <el-button
            type="primary"
            @click="confirmModifyPass('mdPwRules')"
            size="small"
            >确 定</el-button
          >
        </span>
      </el-dialog>
      <dialog-conversation v-if="isSDKReady"></dialog-conversation>
      <context-menu></context-menu>
      <image-previewer></image-previewer>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import tim from "@/tim";
import { fileUpload } from "@/api/public";
import Cookies from "js-cookie";
import {
  systemMenuUser,
  systemUserList,
  systemUserIn,
  systemUserPerms,
  updatePhoto,
} from "@/api/system/systemManage";
import logoImg from "@/assets/image/logo.png";
import { logout, modifyPassword } from "@/api/login";
import dialogConversation from "@/page/teachBusiness/expertTeachBusiness/chat/dialog/dialog-conversation";
import contextMenu from "@/page/teachBusiness/expertTeachBusiness/chat/common/context-menu.vue";
import imagePreviewer from "@/page/teachBusiness/expertTeachBusiness/chat/message/image-previewer";
export default {
  data() {
    let validateNowPass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    let validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.mdPwRuleForm.checkPass !== "") {
          this.$refs.mdPwRules.validateField("checkPass");
        }
        callback();
      }
    };
    let validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.mdPwRuleForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      mdPwRuleForm: {
        pass: "",
        checkPass: "",
        nowPass: "",
      },
      mdPwRules: {
        pass: [
          { validator: validatePass, trigger: "blur" },
          { required: true, message: "密码不能为空", trigger: "change" },
        ],
        checkPass: [
          { validator: validatePass2, trigger: "blur" },
          { required: true, message: "确认密码不能为空", trigger: "change" },
        ],
        nowPass: [
          { required: true, message: "原密码不能为空", trigger: "change" },
        ],
      },
      listParams: {
        pageNumber: 1,
        pageSize: 10,
        sortOrder: "asc",
        username: "",
      },
      userInfo: {
        username: "",
        orgName: "",
        name: "",
        userId: "",
        photo: "",
      },
      fileUpload,
      logo: logoImg,
      isCollapse: false,
      menuList: [],
      modifyPwDialogVisible: false,
      menuIdObj: {},
      uniqueOpened: true,
      isRouterShow: true,
    };
  },
  provide() {
    return {
      reload: this.reload,
      timChat: this,
    };
  },
  computed: {
    ...mapState("chatInfo", ["breadList", "isSDKReady"]),
  },
  watch: {
  },
  components: {
    imagePreviewer,
    contextMenu,
    "menu-item": {
      name: "menu-item",
      props: ["item"],
      computed: {
        ...mapGetters("chatInfo", [
          "totalUnreadCount",
          "teacherManageUnreadCount",
          "studentManageUnreadCount",
        ]),
      },
      template: `
        <div>
          <el-submenu :index="item.url ? item.url : String(item.menuId)" v-if="item.list.length > 0" :key="item.menuId">
            <template slot="title">
              <i class=""></i>
              <span slot="title">{{item.name}}</span>
            </template>
            <template v-for="(subItem) in item.list">
              <menu-item :item="subItem"></menu-item>
            </template>
          </el-submenu>
          <el-menu-item :index="item.url ? item.url : String(item.menuId)" v-else :key="item.menuId">
            <i class=""></i>
            <span slot="title">
              {{item.name}}
              <span v-if="item.url == '/teachBusiness/expertTeachBusiness/messageCenter' && totalUnreadCount > 0" class="unread_msg">{{totalUnreadCount > 99 ? '99+' : totalUnreadCount }}</span>
              <span v-if="item.url == '/teachBusiness/expertTeachBusiness/teacherManage' && teacherManageUnreadCount > 0" class="unread_msg">{{teacherManageUnreadCount > 99 ? '99+' : teacherManageUnreadCount }}</span>
              <span v-if="item.url == '/teachBusiness/expertTeachBusiness/studentManage' && studentManageUnreadCount > 0" class="unread_msg">{{studentManageUnreadCount > 99 ? '99+' : studentManageUnreadCount }}</span>
            </span>
          </el-menu-item>
        </div>
      `,
    },
    dialogConversation,
  },
  methods: {
    ...mapActions("chatInfo", ["getBlacklist"]),
    ...mapMutations("chatInfo", [
      "updateConversationList",
      "GET_USER_INFO",
      "toggleIsSDKReady",
      "updateCurrentUserProfile",
      "showMessage",
      "pushCurrentMessageList",
      "deleteGroupMemberList",
      "reset",
      "hasChatMenuChange",
    ]),
    loginTim() {
      const userID = Cookies.get("sysChatId");
      const userSig = Cookies.get("userSig");
      this.removeBingChatEvent();
      this.bingChatEvent();
      if (userID && userSig) {
        this.tim.login({ userID, userSig }).then((res) => {
          if (res.code == 0) {
            console.log("im login success!");
          }
        });
      }
    },
    removeBingChatEvent() {
      // 登录成功后会触发 SDK_READY 事件，该事件触发后，可正常使用 SDK 接口
      this.tim.off(this.TIM.EVENT.SDK_READY, this.onReadyStateUpdate, this);
      // SDK NOT READT
      this.tim.off(this.TIM.EVENT.SDK_NOT_READY, this.onReadyStateUpdate, this);
      // 被踢出
      this.tim.off(this.TIM.EVENT.KICKED_OUT, this.onKickOut);
      // SDK内部出错
      this.tim.off(this.TIM.EVENT.ERROR, this.onError);
      // 收到新消息
      this.tim.off(this.TIM.EVENT.MESSAGE_RECEIVED, this.onReceiveMessage);
      // 会话列表更新
      this.tim.off(
        this.TIM.EVENT.CONVERSATION_LIST_UPDATED,
        this.onUpdateConversationList
      );
      // 群组列表更新
      this.tim.off(this.TIM.EVENT.GROUP_LIST_UPDATED, this.onUpdateGroupList);
      // 网络监测
      this.tim.off(this.TIM.EVENT.NET_STATE_CHANGE, this.onNetStateChange);
      // 已读回执
      this.tim.off(
        this.TIM.EVENT.MESSAGE_READ_BY_PEER,
        this.onMessageReadByPeer
      );
    },
    bingChatEvent() {
      // 登录成功后会触发 SDK_READY 事件，该事件触发后，可正常使用 SDK 接口
      this.tim.on(this.TIM.EVENT.SDK_READY, this.onReadyStateUpdate, this);
      // SDK NOT READT
      this.tim.on(this.TIM.EVENT.SDK_NOT_READY, this.onReadyStateUpdate, this);
      // 被踢出
      this.tim.on(this.TIM.EVENT.KICKED_OUT, this.onKickOut);
      // SDK内部出错
      this.tim.on(this.TIM.EVENT.ERROR, this.onError);
      // 收到新消息
      this.tim.on(this.TIM.EVENT.MESSAGE_RECEIVED, this.onReceiveMessage);
      // 会话列表更新
      this.tim.on(
        this.TIM.EVENT.CONVERSATION_LIST_UPDATED,
        this.onUpdateConversationList
      );
      // 群组列表更新
      this.tim.on(this.TIM.EVENT.GROUP_LIST_UPDATED, this.onUpdateGroupList);
      // 网络监测
      this.tim.on(this.TIM.EVENT.NET_STATE_CHANGE, this.onNetStateChange);
      // 已读回执
      this.tim.on(
        this.TIM.EVENT.MESSAGE_READ_BY_PEER,
        this.onMessageReadByPeer
      );
    },
    onReadyStateUpdate({ name }) {
      console.log("onReadyStateUpdate", name);
      const isSDKReady = name === this.TIM.EVENT.SDK_READY ? true : false;
      this.toggleIsSDKReady(isSDKReady);
      if (isSDKReady) {
        this.tim
          .getMyProfile()
          .then(({ data }) => {
            this.updateCurrentUserProfile(data);
          })
          .catch((error) => {
            this.showMessage({
              type: "error",
              message: error.message,
            });
          });
        this.getBlacklist();
      }
    },
    onKickOut(event) {
      console.log("onKickOut", event);
      this.msgError(
        `${this.kickedOutReason(event.data.type)}被踢出，请重新登录。`
      );
      this.reset();
      setTimeout(() => {
        this.$router.push("/login");
      }, 2000);
    },
    onError({ data }) {
      this.msgError(data, message);
      console.log("onError", data);
    },
    onReceiveMessage({ data: messageList }) {
      console.log("messageList", messageList);
      this.pushCurrentMessageList(messageList);
      this.handleQuitGroupTip(messageList);
    },
    onUpdateConversationList(event) {
      console.log("onUpdateConversationList", event.data);
      this.updateConversationList(event.data);
    },
    onUpdateGroupList(event) {
      console.log("onUpdateGroupList", event.data);
      this.updateGroupList(event.data);
    },
    onNetStateChange(event) {
      let state = event.data.state;
      switch (state) {
        case this.TIM.TYPES.NET_STATE_CONNECTED:
          this.msgSuccess("已接入网络！");
          break;
        case this.TIM.TYPES.NET_STATE_CONNECTING:
          this.msgWarn("当前网络不稳定！");
          break;
        case this.TIM.TYPES.NET_STATE_DISCONNECTED:
          this.msgError("当前网络不可用!");
          break;
        default:
          break;
      }
    },
    onMessageReadByPeer() {},
    /**
     * 收到有群成员退群/被踢出的groupTip时，需要将相关群成员从当前会话的群成员列表中移除
     * @param {Message[]} messageList
     */
    handleQuitGroupTip(messageList) {
      console.log("handleQuitGroupTip", messageList);
      // 筛选出当前会话的退群/被踢群的 groupTip
      const groupTips = messageList.filter((message) => {
        return (
          this.currentConversation.conversationID === message.conversationID &&
          message.type === this.TIM.TYPES.MSG_GRP_TIP &&
          (message.payload.operationType === this.TIM.TYPES.GRP_TIP_MBR_QUIT ||
            message.payload.operationType ===
              this.TIM.TYPES.GRP_TIP_MBR_KICKED_OUT)
        );
      });
      // 清理当前会话的群成员列表
      if (groupTips.length > 0) {
        groupTips.forEach((groupTip) => {
          if (
            Array.isArray(groupTip.payload.userIDList) ||
            groupTip.payload.userIDList.length > 0
          ) {
            this.deleteGroupMemberList(groupTip.payload.userIDList);
          }
        });
      }
    },
    kickedOutReason(type) {
      switch (type) {
        case this.TIM.TYPES.KICKED_OUT_MULT_ACCOUNT:
          return "由于多实例登录";
        case this.TIM.TYPES.KICKED_OUT_MULT_DEVICE:
          return "由于多设备登录";
        case this.TIM.TYPES.KICKED_OUT_USERSIG_EXPIRED:
          return "由于 userSig 过期";
        default:
          return "";
      }
    },
    reload() {
      this.isRouterShow = !this.isRouterShow;
    },
    logoutClick() {
      this.$confirm("真的确定要退出登录吗, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.logout();
        })
        .catch(() => {});
    },
    formatOldMenu(item) {
      let { url, menuId } = item
      switch (url) {
        // 兼容之前旧系统的菜单
        case "base/user/list.html":
          url = "/system/systemManage/userManage";
          break;
        case "base/role/list.html":
          url = "/system/systemManage/roleManage";
          break;
        case "base/menu/list.html":
          url = "/system/systemManage/menuManage";
          break;
        case "base/org/list.html":
          url = "/system/systemManage/orgManage";
          break;
        case "base/macro/list.html":
          url = "/system/systemManage/macroManage";
          break;
        case "base/courseEntry/list.html":
          url = "/system/systemManage/courseManage";
          break;
        case "base/area/list.html":
          url = "/system/systemManage/areaManage";
          break;
        case "/views/informationManage/list.html":
          url = "/system/appManage/informationManage";
          break;
        case "/views/tagsManage/list.html":
          url = "/system/appManage/tagsManage";
          break;
        case "/views/inline/list.html":
          url = "/system/appManage/inlineManage";
          break;
        case "/views/banner/list.html":
          url = "/system/appManage/bannerManage";
          break;
        case "/views/article/list.html":
          url = "/system/appManage/articleManage";
          break;
        case "/views/teacher/teacher_cash_config.html":
          url = "/system/appManage/teacherCashManage";
          break;
        case "/views/publicSource/list.html":
          url = "/system/publicSource/sourceManage";
          break;
        // 暂未开发的功能
        case "swagger-ui.html":
        case "base/quartz/list.html":
        case "base/log/list.html":
        case "base/generator/list.html":
        case "druid/index.html":
        case "/views/live/list.html":
          url = "&&_" + menuId;
          break;
      }
      return url;
    },
    getSystemMenuUser() {
      systemMenuUser().then((res) => {
        if (res.code == 0) {
          this.menuList = res.menuList;
          let hasChatMenu = false;
          let nameList = [];
          let getMenuIdList = (list) => {
            for (let i = 0, len = list.length; i < len; i++) {
              list[i].url = this.formatOldMenu(list[i]);
              const { name, url, parentId } = list[i];
              if (parentId == 0) {
                nameList = [];
              }
              nameList.push(name);
              if (url) {
                this.menuIdObj[url] = JSON.parse(JSON.stringify(nameList));
                nameList.pop();
                if (i == len - 1) {
                  nameList.pop();
                }
              } else if (list[i].list.length > 0) {
                getMenuIdList(list[i].list);
              }
            }
          };
          getMenuIdList(this.menuList);

          for (let key in this.menuIdObj) {
            if (key.indexOf("messageCenter") > -1) {
              hasChatMenu = true;
            }
          }
          this.hasChatMenuChange(hasChatMenu);
        }
      });
    },
    getSystemUserList(listParams) {
      systemUserList(listParams).then((res) => {});
    },
    getSystemUserIn() {
      systemUserIn().then((res) => {
        if (res.code == 0) {
          console.log(res, "角色。。。");
          this.userInfo.name = res.user.name;
          this.userInfo.orgName = res.user.orgName;
          this.userInfo.username = res.user.username;
          this.userInfo.userId = res.user.userId;
          this.userInfo.photo = res.user.photo;
        }
      });
    },
    getSystemUserPerms() {
      systemUserPerms().then((res) => {});
    },
    confirmModifyPass(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          modifyPassword({
            pswd: this.mdPwRuleForm.nowPass,
            newPswd: this.mdPwRuleForm.pass,
          }).then((res) => {
            if (res.code == 0) {
              this.msgSuccess("密码修改成功！");
            } else {
              this.msgError(res.msg);
            }
          });
          this.modifyPwDialogVisible = false;
        } else {
          console.log("error submit!!");
        }
      });
    },
    beforeAvatarUpload(file) {
      console.log("file", file);
      let types = ["image/jpeg", "image/jpg", "image/png"];
      const isImage = types.includes(file.type);
      if (!isImage) {
        this.$message.error("上传图片只能是 JPG、JPEG、PNG 格式!");
        return false;
      } else {
      }
    },
    handleAvatarSuccess(res, file) {
      updatePhoto({
        userId: this.userInfo.userId,
        photo: res[0].url,
      }).then((res) => {
        if (res.code == 0) {
          this.msgSuccess("操作成功！");
          this.getSystemUserIn();
        }
      });
      this.userInfo.photo = res[0].url;
    },
    logout() {
      logout().then((res) => {
        if (res.code == 0) {
          this.msgSuccess("退出登录成功！");
          this.$router.push({
            path: "/login",
          });
        } else {
          this.msgError("退出登录失败！");
        }
      });
    },
    handleMnenuSelect(index, indexPath) {
      if (!index.includes("&&")) {
        this.$router.push({
          path: index,
        });
      }
    },
  },
  created() {},
  beforeDestroy() {
    this.tim
      .logout()
      .then((_) => {
        this.reset();
        this.removeBingChatEvent();
      })
      .catch((err) => {
        console.error("logout err: ", err);
      });
  },
  mounted() {
    this.tim = tim;
    this.removeBingChatEvent();
    this.reset();
    this.loginTim();
    this.getSystemMenuUser();
    this.getSystemUserList(this.listParams);
    this.getSystemUserIn();
    this.getSystemUserPerms();
  },
};
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.main {
  width: 100vw;
  height: 100vh;
  display: flex;
  .app_left {
    width: 260px;
    /* min-width: 20px; */
    height: 100vh;
    background-color: #545c64;
    .el-menu {
      border-right: none;
      height: calc(100vh - 50px);
      overflow: auto;
    }
    .logo {
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #2b2f3a;
      img {
        width: 50%;
      }
    }
  }
  .app_right {
    flex: 1;
    height: 100vh;
    display: flex;
    flex-direction: column;
    width: 0;
  }
  .header {
    height: 50px;
    overflow: hidden;
    position: relative;
    background: #fff;
    -webkit-box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
    box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
    font-size: 14px;
    display: flex;
    background-color: #2b2f3a;
    color: #ffffff;
    .header-left {
      width: 200px;
    }
    .header-right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      .userinfo {
        height: 50px;
        line-height: 50px;
        max-width: 260px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: flex;
        align-items: center;
      }
      .modifiy-passord {
        cursor: pointer;
        height: 50px;
        line-height: 50px;
        margin: 0 10px;
      }
      .logout {
        cursor: pointer;
        height: 50px;
        line-height: 50px;
        margin: 0 10px;
      }
      .modifiy-passord:hover,
      .logout:hover {
        color: #409eff;
      }
    }
  }
  .logout-content {
    height: 60px;
    line-height: 60px;
  }
  .el-dialog--center .el-dialog__body {
    padding: 16px 25px 0 !important;
  }
  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .breadcrumb {
      height: 40px;
      display: flex;
      align-items: center;
      padding: 0 0 0 20px;
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 3px 0 rgba(0, 0, 0, 0.04);
    }
    .content-view {
      flex: 1;
      padding: 0 10px;
      overflow: hidden;
    }
  }
  .footer {
    height: 40px;
    border-top: 1px solid #f1f1f1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    padding: 0 20px;
    box-sizing: border-box;
  }
  /deep/ .unread_msg {
    background-color: #f56c6c;
    border-radius: 20px;
    padding: 0 4px;
    display: inline-block;
    min-width: 18px;
    height: 18px;
    text-align: center;
    line-height: 18px;
    font-size: 12px;
    color: #ffffff;
    margin-left: 10px;
  }
}
</style>