<template>
  <div class="dialog_mask" v-show="dialogRefundOrderPreviewInfo.show">
    <div class="dialog">
      <div class="dialog_header">
        <div class="dialog_title">
          <span>退费详情</span>
        </div>
        <span
          class="iconfont icon-error icon_close"
          @click="closeDialog"
        ></span>
      </div>
      <div class="dialog_content">
        <div class="student_info">
          <span class="s_label">姓名：</span>
          <span>{{ name }}</span>
          <span class="s_label mL20">性别：</span>
          <span>{{ sex == 1 ? "男" : sex == 2 ? "女" : "未知" }}</span>
          <span class="s_label mL20">年级：</span>
          <span>{{ sectionName ? sectionName : "未知" }}</span>
          <span class="s_label mL20">出生日期：</span>
          <span>{{ birthday ? birthday : "未知" }}</span>
          <span class="s_label mL20">星座：</span>
          <span>{{ constellationName ? constellationName : "未知" }}</span>
        </div>
        <div class="order_list">
          <div
            class="order_item"
            v-for="(item, index) in orderInfoList"
            :key="index"
          >
            <div class="sub_title">订单信息{{ index + 1 }}</div>
            <div class="info_i">
              <span>
                <span>产品名称：</span>
                <span>{{ item.productName }}</span>
              </span>
              <span>
                <span>总课时：</span>
                <span>{{ item.classHour }}课时</span>
              </span>
            </div>
            <div class="info_i">
              <span>
                <span>总金额：</span>
                <span>{{ item.totalPrice }}元</span>
              </span>
              <span>
                <span>下单时间：</span>
                <span>{{ item.createTime }}</span>
              </span>
            </div>
            <div class="info_i">
              <span>
                <span>订单编号：</span>
                <span>{{ item.orderNo }}</span>
              </span>
              <span></span>
            </div>
          </div>
        </div>
        <div class="input_info">
          <div class="sub_title">退款记录</div>
          <div class="row_0">
            <span>总计金额：{{ totalPrice }}</span>
            <span class="mL20"
              >实际消耗金额：{{ saveParams.realityUsePrice }}</span
            >
            <span class="mL20">应退金额：{{ saveParams.refundAmount }}</span>
          </div>
          <div class="row_1">
            <span>实际退款金额：</span>
            <span class="value">
              <el-input
                size="small"
                v-model="saveParams.realityRefundAmount"
                placeholder="请输入实际退款金额"
              ></el-input>
            </span>
          </div>
          <div class="row_1">
            <span class="label">备注：</span>
            <span class="value">
              <el-input
                size="small"
                v-model="saveParams.backReason"
                placeholder="请输入备注信息"
              ></el-input>
            </span>
          </div>
          <div class="sub_title">退费账户</div>
          <div class="row_1">
            <span class="label">收款姓名：</span>
            <span class="value">
              <el-input
                size="small"
                v-model="saveParams.bankUserName"
                placeholder="请输入收款姓名"
              ></el-input>
            </span>
          </div>
          <div class="row_1">
            <span class="label">银行账号：</span>
            <span class="value">
              <el-input
                size="small"
                v-model="saveParams.bankAccount"
                placeholder="请输入银行账号"
              ></el-input>
            </span>
          </div>
          <div class="row_1">
            <span class="label">开户支行：</span>
            <span class="value">
              <el-input
                size="small"
                v-model="saveParams.bankAddress"
                placeholder="请输入开户支行"
              ></el-input>
            </span>
          </div>
          <div class="row_1">
            <span class="label">联系方式：</span>
            <span class="value">
              <el-input
                size="small"
                v-model="saveParams.bankUserPhone"
                placeholder="请输入联系方式"
              ></el-input>
            </span>
          </div>
        </div>
      </div>
      <div class="dialog_bottom">
        <span class="btn btn_save" @click="saveClick">提交审核</span>
        <!-- <span class="btn btn_cancel" @click="reSendClick">重新发送给家长</span> -->
      </div>
    </div>
  </div>
</template>
<script>
import TIM from "tim-js-sdk";
import {
  studentRefundSave,
  refundOrderInfos,
  checkRefundOrders,
  checkRefundInfo,
} from "@/api/chat";
import { mapMutations, mapState } from "vuex";
export default {
  data() {
    return {
      name: "",
      sectionName: "",
      birthday: "",
      sex: "",
      constellationName: "",
      orderInfoList: [],
      saveParams: {
        backReason: "",
        bankAccount: "",
        bankAddress: "",
        bankUserName: "",
        bankUserPhone: "",
        orderIds: "",
        realityRefundAmount: "",
        realityUsePrice: "",
        refundAmount: "",
        studentId: "",
      },
      totalPrice: 0,
    };
  },
  inject: ["timChat"],
  watch: {
    "dialogRefundOrderPreviewInfo.show"(show) {
      if (show) {
        console.log(this.dialogRefundOrderPreviewInfo);
        this.init();
      }
    },
  },
  components: {},
  computed: {
    ...mapState("dialogCont", ["dialogRefundOrderPreviewInfo"]),
  },
  mounted() {},
  methods: {
    ...mapMutations("dialogCont", ["dialogRefundOrderPreviewInfoChange"]),
    ...mapMutations("chatInfo", ["pushCurrentMessageList"]),
    closeDialog() {
      this.dialogRefundOrderPreviewInfoChange({
        show: false,
      });
    },
    reSendClick() {
      const { studentId, backReason, bankAccount, bankAddress, bankUserName, bankUserPhone, realityRefundAmount, orderIds } = this.saveParams
      let data = {
            studentId,
            studentName: this.name,
            orderIdList: orderIds.split(','),  //产品订单列表
            backReason,  //备注
            bankAccount, //收款账号
            bankAddress, //开户银行
            bankUserName, //收款姓名
            bankUserPhone,//联系方式
            realityRefundAmount, //实际退款金额
          }
      const message = this.timChat.tim.createCustomMessage({
        to: this.dialogRefundOrderPreviewInfo.fromAccount,
        conversationType: TIM.TYPES.CONV_C2C,
        payload: {
          data: "refund-order",
          description: JSON.stringify(data),
          extension: "",
        },
      });
      this.pushCurrentMessageList(message);
      this.timChat.tim
        .sendMessage(message)
        .then(() => {
          this.msgSuccess("退费申请发送成功！");
        })
        .catch((error) => {
          this.msgError(error.message);
        });
      this.dialogRefundOrderPreviewInfoChange({
        show: false,
      });
    },
    async saveClick() {
      if (typeof this.saveParams.bankAccount == 'string' && !this.saveParams.bankAccount.trim()) {
        this.msgWarn("退款账户不能为空！");
        return;
      }
      if (typeof this.saveParams.bankAddress == 'string' && !this.saveParams.bankAddress.trim()) {
        this.msgWarn("开户支行不能为空！");
        return;
      }
      if (
        typeof this.saveParams.bankUserName == 'string' &&
        !this.saveParams.bankUserName.trim()
      ) {
        this.msgWarn("收款姓名不能为空！");
        return;
      }
      if (
        typeof this.saveParams.bankUserPhone == 'string' &&
        !this.saveParams.bankUserPhone.trim()
      ) {
        this.msgWarn("联系方式不能为空！");
        return;
      }
      if (
        typeof this.saveParams.realityRefundAmount == 'string' &&
        !this.saveParams.realityRefundAmount.trim()
      ) {
        this.msgWarn("实际退款金额不能为空！");
        return;
      }
      let { rows: flag} = await checkRefundOrders({
          orderIds: this.saveParams.orderIds,
          studentId: this.saveParams.studentId,
        })
      ;
      if (flag) {
        studentRefundSave(this.saveParams).then((res) => {
          if (res.code == 0) {
            this.msgWarn("提交成功！");
            this.dialogRefundOrderPreviewInfoChange({
              show: false,
            });
          }
        });
      } else {
        this.msgWarn("此订单已经过期，不支持处理！");
      }
    },
    init() {
      const {
        studentId,
        orderIdList,
        backReason,
        bankAccount,
        bankAddress,
        bankUserName,
        bankUserPhone,
        realityRefundAmount,
      } = this.dialogRefundOrderPreviewInfo.customData;
      this.saveParams = {
        backReason,
        bankAccount,
        bankAddress,
        bankUserName,
        bankUserPhone,
        orderIds: orderIdList.join(","),
        realityRefundAmount,
        realityUsePrice: "",
        refundAmount: "",
        studentId,
      };
      checkRefundInfo(studentId).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.name = res.rows.name;
          this.sectionName = res.rows.sectionName;
          this.birthday = res.rows.birthday;
          this.sex = res.rows.sex;
          this.constellationName = res.rows.constellationName;
        }
      });
      refundOrderInfos(orderIdList).then((res) => {
        if (res.code == 0) {
          let useTotalPrice = 0,
            countUsedClassHour = 0;
          this.orderInfoList = res.rows;
          this.orderInfoList.forEach((item) => {
            this.totalPrice += Number(item.totalPrice);
            if (item.xmtStudentRefundInfoChildList) {
              item.xmtStudentRefundInfoChildList.forEach((subItem) => {
                countUsedClassHour = subItem.countUsedClassHour
                  ? subItem.countUsedClassHour
                  : 0;
                useTotalPrice +=
                  Number(subItem.classSinglePrice) * countUsedClassHour;
              });
            }
          });
          this.saveParams.realityUsePrice = useTotalPrice;
          this.saveParams.refundAmount = this.totalPrice - useTotalPrice;
        }
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.dialog_mask {
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: rgba($color: #000000, $alpha: 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  .dialog {
    background-color: #ffffff;
    width: 680px;
    height: 600px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    padding: 10px 20px 20px;
    .dialog_header {
      position: relative;
      .dialog_title {
        margin-top: 20px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        .dialog_title_sub {
          color: #444444;
          font-size: 12px;
        }
      }
      .icon_close {
        position: absolute;
        right: 10px;
        top: 10px;
        cursor: pointer;
      }
    }
    .dialog_content {
      flex: 1;
      overflow-y: auto;
      padding: 20px 20px;
      .student_info {
        line-height: 40px;
        .s_label {
          font-weight: 600;
        }
      }
      .info_i {
        display: flex;
        > span {
          flex: 1;
          padding-right: 10px;
          line-height: 26px;
        }
      }
      .mL20 {
        margin-left: 20px;
      }
      .sub_title {
        line-height: 50px;
        font-weight: 600;
      }
      .row_0 {
        line-height: 50px;
      }
      .row_1 {
        display: flex;
        height: 50px;
        align-items: center;
        .value {
          flex: 1;
        }
      }
    }
    .dialog_bottom {
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      .btn {
        cursor: pointer;
        border-radius: 4px;
        padding: 6px 16px;
      }
      .btn_save {
        background-color: #024df8;
        color: #ffffff;
      }
      .btn_cancel {
        margin-left: 40px;
        color: #bfbfbf;
        border: 1px solid #bfbfbf;
      }
    }
  }
}
</style>