export const dialogCont = {
    namespaced: true,
    state: () => ({
        dialogNewClassSeeInfo: {
            show: false,
            id: null,
        },
        dialogRefundOrderPreviewInfo: {
            show: false,
            fromAccount: '',
            customData: {},
        },
        dialogLearnReportInfo: {
            show: false,
            fromAccount: '',
            customData: {}
        },
        dialogLearnReportDetail: {
            show: false,
            fromAccount: '',
            customData: {}
        },
        dialogFileViewInfo: {
            show: false,
            url: '',
        },
        teacherParams:{
            id:""
        }
    }),
    mutations: {
        // dialogSetTeacher(state,val){
        //     state.teacherParams=val;
        // },
        dialogNewClassSeeChange(state, val) {
            state.dialogNewClassSeeInfo = val
        },
        dialogRefundOrderPreviewInfoChange(state, dialogRefundOrderPreviewInfo) {
            state.dialogRefundOrderPreviewInfo = { ...state.dialogRefundOrderPreviewInfo, ...dialogRefundOrderPreviewInfo}
        },
        dialogLearnReportInfoChange(state, dialogLearnReportInfo){
            state.dialogLearnReportInfo = { ...state.dialogLearnReportInfo, ...dialogLearnReportInfo}
        },
        dialogLearnReportDetailChange(state, dialogLearnReportDetail){
            state.dialogLearnReportDetail = { ...state.dialogLearnReportDetail, ...dialogLearnReportDetail}
        },
        dialogFileViewInfoChange(state, dialogFileViewInfo){
            state.dialogFileViewInfo = {
                ...state.dialogFileViewInfo, ...dialogFileViewInfo
            }
        },
    },
}
