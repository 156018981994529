<template>
  <div class="group-member-list-wrapper">
    <div class="header">
      <span class="member-count text-ellipsis">群成员：{{currentConversation.groupProfile.memberCount}}</span>
    </div>
    <div class="scroll-content">
      <div class="group-member-list">
        <div v-for="member in members" :key="member.userID">
          <popover placement="right" :key="member.userID">
            <group-member-info :member="member" />
            <div slot="reference" class="group-member" @click="currentMemberID = member.userID">
              <avatar :title=getGroupMemberAvatarText(member.role) :src="member.avatar" />
              <div class="member-name text-ellipsis">
                <span v-if="member.nameCard" :title=member.nameCard>{{ member.nameCard }}</span>
                <span v-else-if="member.nick" :title=member.nick>{{ member.nick }}</span>
                <span v-else :title=member.userID>{{ member.userID }}</span>
              </div>
            </div>
          </popover>
        </div>
        <!-- <div class="add-group_member">
          <div class="avatar" @click="addGroupMemberClick">
            <span class="iconfont icon-danyehuaban icon_add"></span>
          </div>  
        </div> -->
      </div>
    </div>
    <div class="more">
      <el-button v-if="showLoadMore" type="text" @click="loadMore">查看更多</el-button>
    </div>
  </div>
</template>

<script>
import { Popover } from 'element-ui'
import { mapActions, mapState } from 'vuex'
import AddGroupMember from './add-group-member.vue'
import GroupMemberInfo from './group-member-info.vue'
import avatar from '../../avatar'
export default {
  data() {
    return {
      addGroupMemberVisible: false,
      currentMemberID: '',
      count: 30 // 显示的群成员数量
    }
  },
  props: ['groupProfile'],
  components: {
    Popover,
    AddGroupMember,
    GroupMemberInfo,
    avatar,
  },
  computed: {
    ...mapState('chatInfo', {
      currentConversation: state => state.currentConversation,
      currentMemberList: state => state.currentMemberList
    }),
    showLoadMore() {
      return this.members.length < this.groupProfile.memberCount
    },
    members() {
      return this.currentMemberList.slice(0, this.count)
    }
  },
  methods: {
    ...mapActions('chatInfo', ['getGroupMemberList']),
    addGroupMemberClick() {
      console.log('addGroupMemberClick')
    },
    getGroupMemberAvatarText(role) {
      switch (role) {
        case 'Owner':
          return '群主'
        case 'Admin':
          return '管理员'
        default:
          return '群成员'
      }
    },
    loadMore() {
      this.getGroupMemberList(this.groupProfile.groupID).then(() => {
        this.count += 30
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.group-member-list-wrapper {
  .header {
    height: 50px;
    padding: 10px 16px 10px 20px;
    border-bottom: 1px solid #e7e7e7;
    .member-count {
      display: inline-block;
      max-width: 130px;
      line-height: 30px;
      font-size: 14px;
      vertical-align: bottom;
    }
      
    .btn-add-member {
      width: 30px;
      height: 30px;
      font-size: 28px;
      text-align: center;
      line-height: 32px;
      cursor: pointer;
      float: right;
      &:hover{
        color: #5cadff;
      }
    }
  }
    
    
  .scroll-content {
    max-height: 250px;
    overflow-y: scroll;
    padding: 10px 15px 10px 15px;
    width: 100%;
    .group-member-list {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      width: 100%;
    }
      
    .group-member {
      width: 60px;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      color: #000000;
      cursor: pointer;
      padding: 10px 0 0 0;
      .avatar {
        width: 40px;
        height: 40px;
      }
        
      .member-name {
        font-size: 12px;
        width: 50px;
        text-align: center;
        margin-top: 4px;
      }
      .text-ellipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
        
    }
    .add-group_member {
      width: 60px;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      color: #000000;
      cursor: pointer;
      padding: 10px 0 0 0;
      .avatar {
        width: 40px;
        height: 40px;
        border-radius: 6px;
        border: 1px solid #DDDDDD;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 18px;
      }
      .icon_add {
        font-size: 26px;
        color: #AAAAAA;
      }
    }
  }
    
  .more {
    padding: 0 20px;
    border-bottom: 1px solid #e7e7e7;
  }
    
}
  

</style>
