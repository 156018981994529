<template>
  <div class="dialog_mask" v-show="dialogLearnReportDetail.show">
    <dialog-file-view-private
      :dialogFileViewPrivateInfo="dialogFileViewPrivateInfo"
      @closeFileView="closeFileView"
    ></dialog-file-view-private>
    <div class="dialog">
      <div class="title">课堂反馈</div>
      <span
        class="iconfont icon-error icon_close"
        @click="closeDialogClick"
      ></span>
      <div class="dialog_content">
        <div class="base_info">
          <img :src="photo" alt="" />
          <div class="base_left">
            <div>
              <span class="user_name">{{ studentName }}</span>
              <span class="user_section">【{{ sectionName }}】</span>
            </div>
            <div class="label_item">
              <span class="subInfo_label">性 别：</span>
              <span class="subInfo_value">{{
                sex == 1 ? "男" : sex == 2 ? "女" : "未知"
              }}</span>
              <span class="subInfo_label">出生年月：</span>
              <span class="subInfo_value">{{
                birthday
              }}</span>
              <span class="subInfo_label">星座：</span>
              <span class="subInfo_value">{{
                constellationName ? constellationName : "未知"
              }}</span>
            </div>
          </div>
        </div>
        <div class="sub_title">
          <span class="v_line"></span>
          <span class="sub_title_txt">课程信息</span>
        </div>
        <div class="course_info">
          <div class="label_item">
            <span class="subInfo_label"> 上课时间：</span>
            <span class="subInfo_value">{{ beginTime }}</span>
          </div>
          <div class="label_item">
            <span class="subInfo_label"> 科目：</span>
            <span class="subInfo_value">{{ subjectName }}</span>
          </div>
          <div class="label_item">
            <span class="subInfo_label"> 授课教师：</span>
            <span class="subInfo_value">{{ teacherName }}</span>
          </div>
        </div>
        <div class="sub_title">
          <span class="v_line"></span>
          <span class="sub_title_txt">反馈内容</span>
        </div>
        <div class="label_item">
          <span class="subInfo_label"> 主要知识点：</span>
          <span class="subInfo_value"
            ><span
              v-show="selectKnoewledgeList.length > 0"
              v-for="(item, index) in selectKnoewledgeList"
              :key="index"
              >{{ item }}</span
            >
            <span v-show="selectKnoewledgeList.length == 0"
              >暂未选择知识点</span
            ></span
          >
        </div>
        <div class="label_item">
          <span class="subInfo_label"> 上课内容及学生相关问题：</span>
          <span class="subInfo_value">{{
            teaDetailedContent ? teaDetailedContent : "暂无备注"
          }}</span>
        </div>
        <div class="label_item row_2">
          <div class="subInfo_label">
            图片备注：<span v-show="teaDetailedPictureList.length == 0"
              >暂无图片备注</span
            >
          </div>
          <div
            class="img_list img_list_move"
            v-show="teaDetailedPictureList.length > 0"
          >
            <div
              class="prev_img img_btn"
              :class="{
                img_btn_disable: curStep == 0,
                img_btn_able: curStep != 0,
              }"
              @click="moveImgListClick('prev')"
            >
              <span class="iconfont icon-zuojiantou"></span>
            </div>
            <div
              class="next_img img_btn"
              :class="{
                img_btn_disable: curStep == maxStep,
                img_btn_able: curStep != maxStep,
              }"
              @click="moveImgListClick('next')"
            >
              <span class="iconfont icon-youjiantou"></span>
            </div>
            <div ref="imgUl" class="img_ul" :style="[imgUlStyle]">
              <div
                class="img_item img_item_true"
                v-for="(item, index) in teaDetailedPictureList"
                :key="index"
              >
                <div class="img_hover">
                  <span
                    class="iconfont icon-yulan1 icon_preview"
                    title="预览"
                    @click="imgItemSeeClick(item, index)"
                  ></span>
                </div>
                <img :src="item.url" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="sub_title">
          <span class="v_line"></span>
          <span class="sub_title_txt">课堂评价</span>
        </div>
        <div class="course_evaluate">
          <div class="course_evaluate_item">
            <div class="evaluate_title">1. 学生在本堂课听课的认真程度？</div>
            <div class="evaluate_select">{{teaEarnestObj.name}}</div>
            <div class="evaluate_process">
              <el-progress
                :text-inside="true"
                :stroke-width="18"
                :percentage="teaEarnestObj.progress"
                status="exception"
              ></el-progress>
            </div>
          </div>
          <div class="course_evaluate_item">
            <div class="evaluate_title">2. 上次课作业的完成程度？</div>
            <div class="evaluate_select">{{teaFinishObj.name}}</div>
            <div class="evaluate_process">
              <el-progress
                :text-inside="true"
                :stroke-width="18"
                :percentage="teaFinishObj.progress"
                status="exception"
              ></el-progress>
            </div>
          </div>
          <div class="course_evaluate_item">
            <div class="evaluate_title">3. 学生与老师的互动效果？</div>
            <div class="evaluate_select">{{teaInteractionObj.name}}</div>
            <div class="evaluate_process">
              <el-progress
                :text-inside="true"
                :stroke-width="18"
                :percentage="teaInteractionObj.progress"
                status="exception"
              ></el-progress>
            </div>
          </div>
          <div class="course_evaluate_item">
            <div class="evaluate_title">4. 学生对本堂课内容的掌握程度？</div>
            <div class="evaluate_select">{{teaUnderstandObj.name}}</div>
            <div class="evaluate_process">
              <el-progress
                :text-inside="true"
                :stroke-width="18"
                :percentage="teaUnderstandObj.progress"
                status="exception"
              ></el-progress>
            </div>
          </div>
        </div>
        <div class="sub_title">
          <span class="v_line"></span>
          <span class="sub_title_txt">学生作业</span>
        </div>
        <div class="row_3" v-for="(superItem, superIndex) in list" :key="superIndex">
          <div class="c_row">
            <span class="c_row_title">作业标题：</span>
            <span class="c_row_value">{{ superItem.homeworkTitle ? superItem.homeworkTitle : "暂无标题" }}</span>
          </div>
          <div class="c_row">
            <span class="c_row_title">作业内容：</span>
            <span class="c_row_value">{{ superItem.homeworkContent ? superItem.homeworkContent : "暂无内容" }}</span>
          </div>
          <div class="homework_item homework_select">
            <div class="c_row">
              <span class="c_row_title">
                作业文件列表：<span v-show="superItem.xmtHomeworkEntityList.length == 0"
                >暂无上传文件</span
              >
              </span>
              
            </div>
            <div class="homework_item_value">
              <div class="file_list" v-show="superItem.xmtHomeworkEntityList.length > 0">
                <span
                  v-for="(item, index) in superItem.xmtHomeworkEntityList"
                  :key="index"
                  class="file_item"
                  @click="imgItemSeeClick(item, undefined)"
                >
                  <span class="file_icon" :class="[item.eatname]">
                    <template
                      v-if="
                        item.extname == 'jpg' ||
                        item.extname == 'jpeg' ||
                        item.extname == 'png' ||
                        item.extname == 'bmp'
                      "
                    >
                      <svg-icon icon-class="img_2"></svg-icon>
                    </template>
                    <template
                      v-else-if="
                        item.extname == 'doc' || item.extname == 'docx'
                      "
                    >
                      <svg-icon icon-class="word"></svg-icon>
                    </template>
                    <template
                      v-else-if="
                        item.extname == 'ppt' || item.extname == 'pptx'
                      "
                    >
                      <svg-icon icon-class="ppt"></svg-icon>
                    </template>
                    <template v-else-if="item.extname == 'pdf'">
                      <svg-icon icon-class="pdf"></svg-icon>
                    </template>
                    <template
                      v-else-if="
                        item.extname == 'xlsx' || item.extname == 'xls'
                      "
                    >
                      <svg-icon icon-class="excel"></svg-icon>
                    </template>
                    <template v-else-if="item.extname == 'txt'">
                      <svg-icon icon-class="txt"></svg-icon>
                    </template>
                    <template v-else>
                      <svg-icon
                        icon-class="unknown"
                        class-name="unknown"
                      ></svg-icon>
                    </template>
                  </span>
                  <span class="file_name" :title="item.name">{{ item.name }}</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="no_homework" v-show="list.length == 0">
          暂无布置作业
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
import dialogFileViewPrivate from "../dialog-file-view-private.vue";
const teaFinishList = [
  {
    id: 192,
    name: "上次课未留作业",
  },
  {
    id: 191,
    name: "完成的太少，没有主动去完成老师布置的作业",
  },
  {
    id: 190,
    name: "部分完成，个别难度大的题目等待老师讲解",
  },
  {
    id: 189,
    name: "无论题目难度大小，全部完成",
  },
];
const teaEarnestList = [
  {
    id: 205,
    name: "走神情况严重",
  },
  {
    id: 206,
    name: "注意力集中时间较短",
  },
  {
    id: 207,
    name: "正常水平",
  },
  {
    id: 208,
    name: "能够跟上老师的节奏",
  },
  {
    id: 209,
    name: "全神贯注",
  },
];
const teaUnderstandList = [
  {
    id: 213,
    name: "记忆层面，只记住知识点",
  },
  {
    id: 214,
    name: "理解层面，可以熟练做题",
  },
  {
    id: 215,
    name: "完全掌握，能够举一反三",
  },
];
const teaInteractionList = [
  {
    id: 217,
    name: "交流少，不主动，不顺畅",
  },
  {
    id: 218,
    name: "只被动回答老师的提问",
  },
  {
    id: 219,
    name: "善于思考，积极回答",
  },
  {
    id: 220,
    name: "思维清晰，主动提问",
  },
];
import { getLearnReportInfo } from "@/api/chat";
import {
  productItemWorkList as homeworkList,
} from "@/api/administration/expertTeachBusiness/teachBusiness.js";
import * as dayjs from "dayjs";
export default {
  data() {
    return {
      studentName: "",
      sectionName: "",
      sex: "",
      birthday: "",
      constellationName: "",
      user: {
        itemId: "",
        homeWorkCounts: 0,
      },
      id: "",
      itemId: "",
      teaFinishList,
      teaEarnestList,
      teaUnderstandList,
      teaInteractionList,
      teaContent: "",
      teaDetailedContent: "",
      teaFinishId: "",
      teaEarnestId: "",
      teaUnderstandId: "",
      teaInteractionId: "", //
      homeworkTitle: "",
      homeworkContent: "",
      teaDetailedPictureList: [], // teaDetailedPicture保存值
      xmtHomeworkEntityList: [],
      selectKnoewledgeList: [],
      dialogFileViewPrivateInfo: {
        show: false,
        type: "img",
        src: "",
      },
      curStep: 0,
      maxStep: 0,
      imgListWidth: 614,
      imgUlStyle: {
        left: "0px",
      },
      beginTime: "",
      teacherName: "",
      photo: "",
      usedClassHour: "",
      title: "",
      subjectName: "",
      teaFinishObj: {
        name: '未选择',
        progress: 0,
      },
      teaEarnestObj: {
        name: '未选择',
        progress: 0,
      },
      teaUnderstandObj: {
        name: '未选择',
        progress: 0,
      },
      teaInteractionObj: {
        name: '未选择',
        progress: 0,
      },
      list: [], // 作业列表
    };
  },
  watch: {
    "dialogLearnReportDetail.show"(show) {
      console.log("show: ", show);
      if (show) {
        this.getFeedbackDetail();
        this.getHomeWorkList()
      }
    },
  },
  components: {
    dialogFileViewPrivate,
  },
  computed: {
    ...mapState("dialogCont", ["dialogLearnReportDetail"]),
  },
  inject: ["timChat"],
  mounted() {},
  methods: {
    ...mapMutations("dialogCont", ["dialogLearnReportDetailChange"]),
    getHomeWorkList() {
      homeworkList({
        pageSize: 100,
        pageNumber: 1,
        itemId: this.dialogLearnReportDetail.customData.id,
      }).then((res) => {
        this.list = [];
        if (res.rows instanceof Array) {
          let list = res.rows;
          list.forEach((val) => {
            val.formatTime = dayjs(val.createTime).format("YYYY/MM/DD");
            val.xmtHomeworkEntityList.forEach((item) => {
              let index = item.name.lastIndexOf(".");
              let extname = item.name.substring(index + 1);
              item.extname = extname;
            });
          });
          this.list = list;
        }
      });
    },
    closeDialogClick() {
      this.dialogLearnReportDetailChange({
        show: false,
      });
    },
    imgItemSeeClick(item, index) {
      if (
        ["doc", "docx", "ppt", "pptx", "xlsx", "xls"].includes(
          item.extname.toLowerCase()
        )
      ) {
        this.dialogFileViewPrivateInfo = {
          show: true,
          type: "file",
          src: `https://view.officeapps.live.com/op/view.aspx?src=${
            item.fileUrl ? item.fileUrl : item.url
          }`,
        };
      } else if (["pdf"].includes(item.extname.toLowerCase())) {
        this.dialogFileViewPrivateInfo = {
          show: true,
          type: "file",
          src: `${this.webUrl}/pdf/web/viewer.html?file=${
            item.fileUrl ? item.fileUrl : item.url
          }`,
        };
      } else if (
        ["png", "jpg", "bmp", "jpeg", "gif"].includes(
          item.extname.toLowerCase()
        )
      ) {
        this.dialogFileViewPrivateInfo = {
          show: true,
          type: "img",
          src: item.fileUrl ? item.fileUrl : item.url,
          curIndex: index ? index : 0,
          imgList:
            index != undefined
              ? this.teaDetailedPictureList.map((item) => {
                  return item.fileUrl ? item.fileUrl : item.url;
                })
              : [item.fileUrl ? item.fileUrl : item.url],
        };
      } else {
        this.msgWarn("该文件格式不支持预览！");
        return;
      }
    },
    closeFileView() {
      this.dialogFileViewPrivateInfo.show = false;
    },
    moveImgListClick(type) {
      if (this.curStep != 0 && type == "prev") {
        this.curStep--;
      } else if (this.curStep != this.maxStep && type == "next") {
        this.curStep++;
      }
      this.imgUlStyle.left = `-${this.curStep * 110}px`;
    },
    getFeedbackDetail() {
      getLearnReportInfo(this.dialogLearnReportDetail.customData.id
      ).then((res) => {
        if (typeof res.msg == "string") {
          return;
        }
        const {
          id,
          teaContent,
          teaDetailedContent,
          teaEarnestId,
          teaFinishId,
          teaInteractionId,
          teaUnderstandId,
          ixmtProductItemWorkEntity,
          teaDetailedPictureList,
          sectionName,
          studentName,
          sex,
          birthday,
          constellationName,
          itemId,
          subjectName,
          mainGroupId,
          status,
          beginTime,
          teacherName,
          photo,
          usedClassHour,
          title,
        } = res.rows;
        this.mainGroupId = mainGroupId;
        this.status = status;
        this.chatParams = {
          sectionName,
          studentName,
          itemId,
          subjectName,
        };
        this.id = id;
        this.studentName = studentName;
        this.sectionName = sectionName;
        this.sex = sex;
        this.birthday = birthday ? dayjs(birthday).format('YYYY-MM-DD HH:mm:ss') : '未知';
        this.constellationName = constellationName;
        this.teaContent = teaContent;
        this.teaDetailedContent = teaDetailedContent;
        this.teaEarnestId = teaEarnestId;
        this.teaFinishId = teaFinishId;
        this.teaInteractionId = teaInteractionId;
        this.teaUnderstandId = teaUnderstandId;
        if(this.teaEarnestId){
          for(let i = 0; i < this.teaEarnestList.length; i++){
            if(this.teaEarnestId == this.teaEarnestList[i].id){
              this.teaEarnestObj = {
                name: this.teaEarnestList[i].name,
                progress: parseInt((i + 1) / this.teaEarnestList.length * 100)
              }
              break
            }
          }
        }
        if(this.teaFinishId){
          for(let i = 0; i < this.teaFinishList.length; i++){
            if(this.teaFinishId == this.teaFinishList[i].id){
              this.teaFinishObj = {
                name: this.teaFinishList[i].name,
                progress: parseInt((i + 1) / this.teaFinishList.length * 100)
              }
              break
            }
          }
        }
        if(this.teaInteractionId){
          for(let i = 0; i < this.teaInteractionList.length; i++){
            if(this.teaInteractionId == this.teaInteractionList[i].id){
              this.teaInteractionObj = {
                name: this.teaInteractionList[i].name,
                progress: parseInt((i + 1) / this.teaInteractionList.length * 100)
              }
              break
            }
          }
        }
        if(this.teaUnderstandId){
          for(let i = 0; i < this.teaUnderstandList.length; i++){
            if(this.teaUnderstandId == this.teaUnderstandList[i].id){
              this.teaUnderstandObj = {
                name: this.teaUnderstandList[i].name,
                progress: parseInt((i + 1) / this.teaUnderstandList.length * 100)
              }
              break
            }
          }
        }
        this.beginTime = beginTime ? beginTime : '暂无数据'
        this.teacherName = teacherName
        this.photo = photo ? photo
          : "https://rcmtoss.oss-cn-beijing.aliyuncs.com/default/studentDefault.png";
        this.subjectName = subjectName;
        this.usedClassHour = usedClassHour
        this.title = title
        this.teaDetailedPictureList = teaDetailedPictureList.map((item) => {
          let index = item.name.lastIndexOf(".");
          item.extname = item.name.substring(index + 1).toLocaleLowerCase();
          return item;
        });
        if (teaContent) {
          this.selectKnoewledgeList = teaContent.split(";");
        } else {
          this.selectKnoewledgeList = [];
        }
        this.$nextTick(() => {
          let width = this.$refs.imgUl.getBoundingClientRect().width;
          if (width > this.imgListWidth) {
            this.maxStep = Math.ceil((width - this.imgListWidth) / 120);
          }
        });
      });
    },
  },
};
</script>
<style lang='scss' scoped>
.dialog_mask {
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  position: fixed;
  background-color: rgba($color: #000000, $alpha: 0.5);
  z-index: $max-index - 1000;
  .no_data {
    @include no_data;
  }
  .dialog {
    width: 660px;
    padding: 16px 20px 20px;
    height: 600px;
    border: 1px solid #e0e0e0;
    overflow-y: auto;
    position: absolute;
    background-color: #ffffff;
    border-radius: 6px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    z-index: 20;
    .title {
      line-height: 30px;
      font-size: 16px;
      font-weight: bolder;
      text-align: center;
    }
    .icon_close {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
    .dialog_content {
      flex: 1;
      overflow-y: auto;
      color: #222222;
      .img_list {
        width: 100%;
        min-height: 120px;
        overflow: auto;
        .img_item {
          position: relative;
          margin: 10px 10px 10px 0px;
          float: left;
          cursor: pointer;
          height: 100px;
          width: 100px;
          border: 1px solid #dddddd;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          .img_hover {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: none;
            z-index: 6;
            align-items: center;
            justify-content: center;
            background-color: rgba($color: #000000, $alpha: 0.7);
            .icon_del {
              margin-left: 10px;
              cursor: pointer;
              color: #ffffff;
            }
            .icon_preview {
              cursor: pointer;
              color: #ffffff;
              font-size: 14px;
            }
          }
          &:hover {
            .img_hover {
              display: flex;
            }
          }
          img {
            width: 100px;
            height: 100px;
          }
          .icon_new {
            font-size: 40px;
          }
        }
      }
      .img_list_move {
        position: relative;
        overflow: hidden;
        .img_ul {
          display: flex;
          position: absolute;
          left: 0;
          top: 0;
        }
        .img_btn {
          width: 30px;
          height: 30px;
          border: 1px solid #dddddd;
          border-radius: 30px;
          background-color: #ffffff;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          text-align: center;
          line-height: 30px;
          cursor: pointer;
          z-index: 10;
          .iconfont {
            color: #aaaaaa;
          }
        }
        .img_btn_able {
          &:hover {
            .iconfont {
              color: #024df8;
            }
          }
        }
        .img_btn_disable {
          cursor: default;
          background-color: #dddddd;
        }
        .prev_img {
          left: 0px;
        }
        .next_img {
          right: 0px;
        }
      }
      .base_info {
        background-color: #ffffff;
        color: #222222;
        display: flex;
        padding: 10px 0;
        img {
          width: 80px;
          border-radius: 80px;
          margin: 0 20px 0 0;
        }
        .base_left {
          display: flex;
          flex-direction: column;
          justify-content: center;
          line-height: 30px;
        }
      }
      .label_item {
        line-height: 30px;
        .user_name {
          font-size: 18px;
        }
        .subInfo_label {
          color: #aaaaaa;
        }
        .subInfo_value {
          margin: 0 40px 0 6px;
        }
      }
      .sub_title {
        height: 50px;
        display: flex;
        align-items: center;
        .v_line {
          background: #eb5d57;
          width: 4px;
          height: 20px;
          display: inline-block;
          margin-right: 10px;
        }
        .sub_title_txt {
          font-size: 16px;
          font-weight: 600;
        }
      }
      .course_evaluate {
        background-color: #ffffff;
        .course_evaluate_item {
          .evaluate_title {
            line-height: 40px;
            font-size: 16px;
            font-weight: 400;
          }
          .evaluate_select{
            line-height: 30px;
            color: #aaaaaa;
          }
          .evaluate_process{
            height: 40px;
            padding-top: 10px;
            padding-right: 40px;
          }
        }
      }
      .row_3 {
        border: 1px solid #aaaaaa;
        border-radius: 6px;
        margin: 10px 10px 10px 0;
        padding: 10px;
      }
      .c_row {
        line-height: 28px;
        .c_row_title{
           color: #aaaaaa;
        }
      }
      .homework_item_value{
        margin-top: 10px;
      }
      .homework_select {
        .file_list {
          overflow: hidden;
          .file_item {
            float: left;
            width: calc(50% - 28px);
            padding: 4px 10px;
            line-height: 32px;
            word-break: keep-all;
            box-shadow: 0px 0px 10px 0px rgba(34, 34, 34, 0.15);
            margin-right: 28px;
            margin-bottom: 10px;
            cursor: pointer;
            .file_icon {
              height: 16px;
              margin-right: 10px;
              float: left;
            }
            .unknown {
              font-size: 20px;
              position: relative;
              top: 14px;
            }
            .file_name {
              color: #222222;
              max-width: 216px;
              @include ellipsis;
              float: left;
            }
            .file_del {
              font-size: 14px;
              margin-left: 20px;
              cursor: pointer;
              color: #666666;
            }
          }
        }
      }
      .no_homework{
        color: #aaaaaa;
        line-height: 30px;
      }
    }
  }
}
</style>