<template>
  <message-bubble :isMine="isMine" :message="message">
    <div class="new-class-require-wrapper" @click="learnReportClick">
        <div class="left">
        <div class="m_title">
          {{ customData.title }}
        </div>
        <div class="m_row">
          <span>学段学科：</span>
          <span>{{ customData.classTitle }}</span>
        </div>
        <div class="m_row">
          <span>开始时间：</span>
          <span>{{ customData.beginTime }}</span>
        </div>
        <div class="m_row">
          <span>结束时间：</span>
          <span>{{ customData.stopTime }}</span>
        </div>
      </div>
        <span class="see" v-show="!isMine" :class="[btnClass]" @click.stop="learnReportClick">查看</span>
      </div>
  </message-bubble>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import MessageBubble from "../message-bubble";
export default {
  name: "FileElement",
  props: {
    customData: {
      type: Object,
      required: true,
    },
    message: {
      type: Object,
      required: true,
    },
    isMine: {
      type: Boolean,
    },
  },
  components: {
    MessageBubble,
  },
  computed: {
    ...mapGetters("chatInfo", ["toAccount"]),
    btnClass() {
      let classString = "btn-see-";
      if (this.isMine) {
        classString += "message-send";
      } else {
        classString += "message-received";
      }
      return classString
    }
  },
  methods: {
    ...mapMutations('dialogCont', ['dialogLearnReportInfoChange']),
    learnReportClick(){
      console.log(this.customData)
      this.dialogLearnReportInfoChange({
        show: true,
        customData: this.customData,
        fromAccount: this.toAccount,
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.new-class-require-wrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  padding: 10px;
  &:hover {
    .iconfont {
      color: #024df8;
    }
  }
  .left {
    margin-left: 10px;
    display: flex;
    padding: 0 10px 10px 10px;
    flex-direction: column;
    justify-content: center;
    .m_title {
      text-align: center;
      line-height: 40px;
      font-weight: 600;
      font-size: 16px;
    }
    .m_row {
      line-height: 26px;
      span:nth-of-type(2) {
        color: #409eff;
      }
    }
  }
  .see {
    cursor: pointer;
    color: #666666;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .btn-see-message-send{
    left: -60px;
  }
  .btn-see-message-received{
    right: -60px;
  }
}
</style>
