
<template>
  <div
    class="x_dialog"
    draggable="false"
    ondragstart="return false;"
    ondrop="return false;"
    v-show="uploadListParams.show"
  >
    <div class="x_dialog_header">
      <span class="dialog-title">上传状态</span>
      <span
        class="dialog_close iconfont icon-guanbi3"
        title="关闭"
        @click="uploadListParamsChange({ type: 'close' })"
      ></span>
    </div>
    <div class="x_dialog_content">
      <div class="list">
        <div class="list_header">
          <div class="file_name_c">文件名称</div>
          <div class="file_status">大小/进度</div>
          <div class="file_opt">操作</div>
        </div>
        <div class="list_body">
          <div
            class="list_item_c"
            v-for="(item, index) in uploadListParams.list"
            :key="index"
          >
            <div class="list_item">
              <div class="file_name_c" :title="item.fileName">
                <span class="file_icon" :class="[item.eatname]">
                  <template
                    v-if="
                      item.extname == 'jpg' ||
                      item.extname == 'jpeg' ||
                      item.extname == 'png' ||
                      item.extname == 'bmp'
                    "
                  >
                    <svg-icon icon-class="img_2"></svg-icon>
                  </template>
                  <template
                    v-else-if="item.extname == 'doc' || item.extname == 'docx'"
                  >
                    <svg-icon icon-class="word"></svg-icon>
                  </template>
                  <template
                    v-else-if="item.extname == 'ppt' || item.extname == 'pptx'"
                  >
                    <svg-icon icon-class="ppt"></svg-icon>
                  </template>
                  <template v-else-if="item.extname == 'pdf'">
                    <svg-icon icon-class="pdf"></svg-icon>
                  </template>
                  <template
                    v-else-if="item.extname == 'xlsx' || item.extname == 'xls'"
                  >
                    <svg-icon icon-class="excel"></svg-icon>
                  </template>
                  <template v-else-if="item.extname == 'txt'">
                    <svg-icon icon-class="txt"></svg-icon>
                  </template>
                  <template v-else>
                    <svg-icon icon-class="unknown" class-name="unknown"></svg-icon>
                  </template>
                </span>
                <span class="file_name">{{ item.fileName }}</span>
              </div>
              <div class="file_status">
                <span
                  >{{ item.size }}({{
                    item.fileProcess == "处理中"
                      ? item.fileProcess
                      : `${item.fileProcess}%`
                  }})</span
                >
              </div>
              <div class="file_opt">
                <span
                  title="删除"
                  class="file_del iconfont icon-shanchu2"
                  @click="
                    uploadListParamsChange({ type: 'delete', item, index })
                  "
                ></span>
              </div>
            </div>
            <div class="file_process" v-show="item.showProcess" :style="{width: `${(item.fileProcess == '处理中' ? 100 : item.fileProcess)}%`, backgroundColor: '#67C23A'}"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: ["uploadListParams"],
  components: {},
  computed: {},
  watch: {
    "uploadListParams.show"(show) {
      console.log("uploadListParams.show: ", show);
    },
  },
  mounted() {},
  methods: {
    uploadListParamsChange(data) {
      this.$emit("uploadListParamsChange", data);
    },
  },
};
</script>
<style lang="scss" scoped>
.x_dialog {
  position: fixed;
  width: 560px;
  height: 426px;
  display: flex;
  z-index: 999999999;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  flex-direction: column;
  background-color: #ffffff;
  color: #222222;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.38);
  .x_dialog_header {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-color: #222222;
    color: #ffffff;
    .dialog-title {
      font-weight: bolder;
      font-size: 16px;
    }
    .dialog_close {
      font-size: 20px;
      position: absolute;
      right: 20px;
      top: 10px;
      cursor: pointer;
    }
  }
  .x_dialog_content {
    flex: 1;
    overflow: hidden;
    display: flex;
    height: 100%;
    padding-bottom: 20px;
    .list {
      display: flex;
      flex-direction: column;
      width: 100%;
      .list_header {
        height: 36px;
        display: flex;
        align-items: center;
        padding: 0 20px;
        border-bottom: 1px solid #E5E5E5;
        .file_name_c {
          flex: 1;
        }
        .file_status {
          width: 120px;
          text-align: center;
        }
        .file_opt {
          width: 80px;
          text-align: center;
        }
      }
      .list_body {
        flex: 1;
        overflow-y: auto;
        .list_item_c {
            border-bottom: 1px solid #E5E5E5;
            padding-top: 2px;
          .list_item {
            padding: 0 20px;
            height: 30px;
            display: flex;
            align-items: center;
            .file_name_c {
              flex: 1;
              display: flex;
              .file_icon {
                width: 30px;
                font-size: 16px;
              }
              .unknown {
                  font-size: 20px;
              }
              .file_name {
                flex: 1;
                width: 0;
                @include ellipsis;
                line-height: 26px;
              }
            }
            .file_status {
              width: 120px;
              text-align: center;
            }
            .file_opt {
              width: 80px;
              text-align: center;
              .file_del {
                cursor: pointer;
                color: #e81123;
              }
            }
          }
          .file_process {
              height: 2px;
              background-color: #ffffff;
          }
        }
      }
    }
  }
}
</style>
