<template>
  <div
    class="conversation_item_container"
    :class="{
      choose:
        conversation.conversationID === currentConversation.conversationID,
    }"
    @click="selectConversation"
    @contextmenu.prevent.stop="conversationMenuClick"
  >
    <div class="warp">
      <avatar-group v-if="conversation.type === 'GROUP'" :groupID="conversation.groupProfile.groupID" :memberCount="conversation.groupProfile.memberCount" :type="conversation.type"/>
      <avatar v-else :src="avatar" :type="conversation.type"/>
      
      <div class="content">
        <div class="row-1">
          <div class="name">
            <div class="text_ellipsis con_name">
              <span
                :title="getNick"
                v-if="conversation.type === TIM.TYPES.CONV_C2C"
                >{{
                  getNick
                }}
              </span>
              <span
                :title="getNick
                "
                v-else-if="conversation.type === TIM.TYPES.CONV_GROUP"
                >{{
                  getNick
                }}
              </span>
              <span v-else-if="conversation.type === TIM.TYPES.CONV_SYSTEM"
                >系统通知
              </span>
            </div>
            <span class="main_group" v-show="conversation.isMainGroup == 1">(主群)</span>
          </div>
          <div class="unread_count">
            <span class="badge" v-if="showUnreadCount">
              {{
                conversation.unreadCount > 99 ? "99+" : conversation.unreadCount
              }}
            </span>
          </div>
        </div>
        <div class="row-2">
          <div class="summary">
            <div v-if="conversation.lastMessage" class="text_ellipsis">
              <span class="remind" v-if="hasMessageAtMe">{{
                messageAtMeText
              }}</span>
              <span
                class="text"
                :title="conversation.lastMessage.messageForShow"
              >
                {{ messageForShow }}
              </span>
            </div>
          </div>
          <div class="date">
            {{ date }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import { isToday, getDate, getTime } from "@/utils/date";
import avatar from '../avatar.vue'
import avatarGroup from '../avatar-group'
export default {
  name: "conversation-item",
  props: {
    conversation: Object,
    dialogCon: {
      default: false,
    }
  },
  data() {
    return {
      popoverVisible: false,
      showMessageAtMe_text: "",
    };
  },
  inject: ['timChat'],
  components: {
    avatar,
    avatarGroup,
  },
  computed: {
    getNick(){
      const { type, selfNick, userProfile, groupProfile } = this.conversation
      if(type === this.TIM.TYPES.CONV_C2C){
        const { nick, userID } = userProfile
        if(userID.includes('_tea_') || userID.includes('_par_')){
          return selfNick ? selfNick : ''
        }else{
          return nick ? nick : userID
        }
      }else if(type === this.TIM.TYPES.CONV_GROUP){
        const { name, groupID } = groupProfile
        return name ? name : groupID
      }
    },
    hasMessageAtMe() {
      return (
        this.currentConversation.conversationID !==
          this.conversation.conversationID &&
        this.conversation.groupAtInfoList &&
        this.conversation.groupAtInfoList.length > 0
      );
    },
    messageAtMeText() {
      let text = "";
      if (this.conversation.groupAtInfoList.length > 0) {
        this.conversation.groupAtInfoList.forEach((item) => {
          if (item.atTypeArray[0] === this.TIM.TYPES.CONV_AT_ME) {
            text.indexOf("[@所有人]") !== -1
              ? (text = "[@所有人][有人@我]")
              : (text = "[有人@我]");
          }
          if (item.atTypeArray[0] === this.TIM.TYPES.CONV_AT_ALL) {
            text.indexOf("[有人@我]") !== -1
              ? (text = "[有人@我][@所有人]")
              : (text = "[@所有人]");
          }
          if (item.atTypeArray[0] === this.TIM.TYPES.CONV_AT_ALL_AT_ME) {
            text = "[@所有人][有人@我]";
          }
        });
      }
      return text;
    },
    showUnreadCount() {
      if (this.isHidden) {
        return this.conversation.unreadCount > 0;
      }
      // 是否显示未读计数。当前会话和未读计数为0的会话，不显示。
      return (
        this.currentConversation.conversationID !==
          this.conversation.conversationID && this.conversation.unreadCount > 0
      );
    },
    date() {
      if (
        !this.conversation.lastMessage ||
        !this.conversation.lastMessage.lastTime
      ) {
        return "";
      }
      const date = new Date(this.conversation.lastMessage.lastTime * 1000);
      if (isToday(date)) {
        return getTime(date);
      }
      return getDate(date);
    },
    avatar: function () {
      switch (this.conversation.type) {
        case "GROUP":
          return this.conversation.groupProfile.avatar;
        case "C2C":
          return this.conversation.userProfile.avatar;
        default:
          return "";
      }
    },
    conversationName: function () {
      if (this.conversation.type === this.TIM.TYPES.CONV_C2C) {
        return (
          this.conversation.userProfile.nick ||
          this.conversation.userProfile.userID
        );
      }
      if (this.conversation.type === this.TIM.TYPES.CONV_GROUP) {
        return (
          this.conversation.groupProfile.name ||
          this.conversation.groupProfile.groupID
        );
      }
      if (this.conversation.type === this.TIM.TYPES.CONV_SYSTEM) {
        return "系统通知";
      }
      return "";
    },
    showGrayBadge() {
      if (this.conversation.type !== this.TIM.TYPES.CONV_GROUP) {
        return false;
      }
      return (
        this.conversation.groupProfile.selfInfo.messageRemindType ===
        "AcceptNotNotify"
      );
    },
    messageForShow() {
      if (this.conversation.lastMessage.isRevoked) {
        if (
          this.conversation.lastMessage.fromAccount ===
          this.currentUserProfile.userID
        ) {
          return "你撤回了一条消息";
        }
        if (this.conversation.type === this.TIM.TYPES.CONV_C2C) {
          return "对方撤回了一条消息";
        }
        return `${this.conversation.lastMessage.fromAccount}撤回了一条消息`;
      }
      if(this.conversation.lastMessage.type == 'TIMCustomElem'){
        if(this.conversation.lastMessage.payload.data == 'product_item_learn_report'){
          return '[课堂反馈消息]'
        }
        if(this.conversation.lastMessage.payload.data == 'grab-orders-remind'){
          return '[抢单消息]'
        }
      }
      return this.conversation.lastMessage.messageForShow;
    },
    ...mapState('chatInfo', ['currentConversation', 'currentUserProfile', 'isHidden']),
  },
  mounted() {},
  methods: {
    ...mapActions('chatInfo', ['checkoutConversation']),
    ...mapMutations('chatInfo', ['resetCurrentConversation', 'chatShotConversationShowChange', 'chatBoxShowChange']),
    conversationMenuClick(e) {
      if(this.conversation.conversationID.includes('_zs_')){
        return
      }
      let menuList = [{
        label: '删除',
        click: () => {
          this.deleteConversation()
        }
      }]
      let data = {
        x: e.x,
        y: e.y,
        menuList,
      };
      this.$bus.$emit("menu-open", data);
    },
    selectConversation() {
      if((this.conversation.selfType == 'teacher' || this.conversation.selfType == 'parent') && !this.conversation.selfNick){
        this.msgWarn('请等会话信息加载完成后再进行会话选择!')
        return
      }
      if(this.dialogCon){
        this.chatShotConversationShowChange(true)
        this.chatBoxShowChange({
          show: false,
        })
      }
      this.checkoutConversation(this.conversation.conversationID)
    },
    deleteConversation() {
      // 停止冒泡，避免和点击会话的事件冲突
      //event.stopPropagation();
      this.timChat.tim
        .deleteConversation(this.conversation.conversationID)
        .then(() => {
          this.msgSuccess(`会话【${this.conversationName}】删除成功!`)
          this.popoverVisible = false;
          this.resetCurrentConversation()
        })
        .catch((error) => {
          this.msgError(`会话【${this.conversationName}】删除失败!, error=${error.message}`)
          this.popoverVisible = false;
        });
    },
    showContextMenu() {
      this.popoverVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.conversation_item_container {
  padding: 15px 20px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: 0.2s;
  border-bottom: 1px solid #f1f1f1;
  // &:first-child
  // padding-top 30px
  &:hover {
    background-color: #F0F0F0;
  }

  .warp {
    display: flex;
  }

  .avatar {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    flex-shrink: 0;
  }

  .content {
    flex: 1;
    height: 40px;
    overflow: hidden;

    .row-1 {
      display: flex;
      line-height: 21px;
      .text_ellipsis {
        overflow: hidden;
        @include ellipsis;
      }
      .name {
        color: #666666;
        flex: 1;
        display: flex;
        justify-content: flex-start;
        min-width: 0px;
        .con_name{
          max-width: calc(100% - 42px);
        }
        .main_group {
          width: 42px;
          color: #024df8;
        }  
      }
      .unread_count {
        padding-left: 10px;
        flex-shrink: 0;
        color: #76828c;
        font-size: 12px;

        .badge {
          vertical-align: bottom;
          background-color: #f35f5f;
          border-radius: 10px;
          color: #FFF;
          display: inline-block;
          font-size: 12px;
          height: 18px;
          max-width: 40px;
          line-height: 18px;
          padding: 0 6px;
          text-align: center;
          white-space: nowrap;
        }
      }
    }

    .row-2 {
      display: flex;
      font-size: 12px;
      padding-top: 3px;
      .text_ellipsis {
        overflow: hidden;
        @include ellipsis;
      }
      .summary {
        flex: 1;
        overflow: hidden;
        min-width: 0px;
        color: #a5b5c1;

        .remind {
          color: #f35f5f;
        }
      }

      .date {
        padding-left: 10px;
        flex-shrink: 0;
        text-align: right;
        color: #76828c;
      }
    }
  }
}

.choose {
  background-color: #F0F0F0;
}

.context-menu-button {
  padding: 10px;
  border: 2px solid #2d8cf0;
  border-radius: 8px;
}
</style>
