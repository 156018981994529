import Vue from "vue";
import Vuex from "vuex";


import { chatInfo} from './modules/chatInfo'
import { dialogCont} from './modules/dialogCont'

Vue.use(Vuex);

export default new Vuex.Store({
  strict: false,
  modules: {
    chatInfo,
    dialogCont,
  },
});