<template>
  <div class="avatar" :class="shape === 'circle' ? 'shape-circle' : ''">
    <img :src="avatarSrc" />
  </div>
</template>

<script>
import systemAvatar from "@/assets/image/chat/system.png";
import userAvatar from "@/assets/image/chat/default_avatar.png";
import groupAvatar from "@/assets/image/chat/default_avatar_group.png";
export default {
  props: {
    src: String,
    type: {
      type: String,
      default: "C2C",
    },
    shape: {
      type: String,
      default: "circle",
    },
  },
  computed: {
    avatarSrc: function () {
      let src = this.src;
      if (/^(https:|http:|\/\/)/.test(src)) {
        return src;
      } else {
        return this.defaultSrc;
      }
    },
    defaultSrc: function () {
      switch (this.type) {
        case "C2C":
          // 个人头像
          return userAvatar;
        case "GROUP":
          // 群默认头像
          return groupAvatar;
        case this.TIM.TYPES.CONV_SYSTEM:
          return systemAvatar;
        default:
          // 默认头像
          return groupAvatar;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar {
  background-color: #a5b5c1;
  text-align: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    outline: 1px solid #fff;
  }
}

.shape-circle {
  border-radius: 6px;
}
</style>
