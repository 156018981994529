<template>
  <div class="dialog_mask" v-show="dialogNewClassSeeInfo.show">
    <div class="dialog" v-show="step == 1">
      <div class="title">新课需求</div>
      <span
        class="iconfont icon-error icon_close"
        @click="closeDialogClick"
      ></span>
      <div class="dialog_content">
        <div class="part part_1">
          <div class="sub_title">
            <span>学生信息：</span>
          </div>
          <div class="student_info">
            <span class="info_item">
              <span class="label">姓名：</span>
              <span class="value">{{ userInfo.name }}</span>
            </span>
            <span class="info_item">
              <span class="label">性别：</span>
              <span class="value">{{
                userInfo.sex == 1 ? "男" : userInfo.sex == 2 ? "女" : "未知"
              }}</span>
            </span>
            <span class="info_item">
              <span class="label">年级：</span>
              <span class="value">{{
                userInfo.sectionName ? userInfo.sectionName : "未知"
              }}</span>
            </span>
            <span class="info_item">
              <span class="label">出生日期：</span>
              <span class="value">{{ formatBirthday(userInfo.birthday) }}</span>
            </span>
            <span class="info_item">
              <span class="label">星座：</span>
              <span class="value">{{
                userInfo.constellationName ? userInfo.constellationName : "未知"
              }}</span>
            </span>
          </div>
        </div>
        <div class="part part_2">
          <div class="sub_title">
            <span>想要试听的科目：</span>
            <span class="subject_name">{{ subjectName }}</span>
          </div>
        </div>
        <div class="part part_3">
          <div class="sub_title">
            <span>该科目对老师的要求：</span>
          </div>
          <div class="require_item">
            <span class="require_q"
              >你是比较偏向男老师还是女老师上课教学？</span
            >
            <span class="require_a">{{ requireObj.sexNeedName }}</span>
          </div>
          <div class="require_item">
            <span class="require_q">你希望讲师的年龄段在哪个范围？</span>
            <span class="require_a">{{ requireObj.ageNeedName }}</span>
          </div>
          <div class="require_item">
            <span class="require_q">对教师授课的风格比较偏向哪种？</span>
            <span class="require_a">{{ requireObj.styleNeedName }}</span>
          </div>
          <div class="require_item">
            <span class="require_q"
              >喜欢老师的讲课节奏快一点，还是细致一点？</span
            >
            <span class="require_a">{{ requireObj.speedNeedName }}</span>
          </div>
        </div>
        <div class="part part_3">
          <div class="sub_title">
            <span>该科目的成绩：</span>
          </div>
          <div class="score_list">
            <div class="item_row item_head">
              <div>考试日期</div>
              <div>考试类型</div>
              <div>考试成绩/总分</div>
              <div>备注</div>
              <div class="opt">
                <span>操作</span>
              </div>
            </div>
            <div
              class="item_row"
              v-for="(item, index) in scoreList"
              :key="index"
            >
              <div>
                {{ formatBirthday(item.examinationTime) }}
              </div>
              <div>{{ getScoreType(item.examinationType) }}</div>
              <div>
                {{ item.subjectScore ? item.subjectScore : "暂无" }} /
                {{ item.subjectAllScore ? item.subjectAllScore : "暂无" }}
              </div>
              <div>{{ item.remarks ? item.remarks : "暂无" }}</div>
              <div class="opt">
                <span class="btn" @click="previewClick(item)">预览</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <span class="btn" @click="transpondClick">转发</span>
      </div>
    </div>
    <div class="dialog dialog_preview" v-show="step == 2">
      <div class="title">试卷预览</div>
      <span class="go_back" @click="step = 1">
        <span class="iconfont icon-zuojiantou"></span>
        <span>返回</span>
      </span>
      <span class="iconfont icon-error icon_close" @click="step = 1"></span>
      <div class="preview_content">
        <div class="c_row">
          <span class="c_label">考试时间：</span>
          <span class="c_value">{{
            formatBirthday(previewDetail.examinationTime)
          }}</span>
        </div>
        <div class="c_row">
          <span class="c_label">考试类型：</span>
          <span class="c_value">{{
            getScoreType(previewDetail.examinationType)
          }}</span>
        </div>
        <div class="c_row">
          <span class="c_label">成绩/总分：</span>
          <span class="c_value"
            >{{
              previewDetail.subjectScore ? previewDetail.subjectScore : "暂无"
            }}
            /
            {{
              previewDetail.subjectAllScore
                ? previewDetail.subjectAllScore
                : "暂无"
            }}</span
          >
        </div>
        <div class="c_row">
          <span class="c_label">备注说明：</span>
          <span class="c_value">{{
            previewDetail.remarks ? previewDetail.remarks : "暂无"
          }}</span>
        </div>
        <div class="c_title">试卷：</div>
        <div class="img_list" v-show="previewDetail.imgList.length > 0">
          <div
            class="prev_img img_btn"
            :class="{
              img_btn_disable: curStep == 0,
              img_btn_able: curStep != 0,
            }"
            @click="moveImgListClick('prev')"
          >
            <span class="iconfont icon-zuojiantou"></span>
          </div>
          <div
            class="next_img img_btn"
            :class="{
              img_btn_disable: curStep == maxStep,
              img_btn_able: curStep != maxStep,
            }"
            @click="moveImgListClick('next')"
          >
            <span class="iconfont icon-youjiantou"></span>
          </div>
          <ul class="img_ul" ref="imgUl" :style="[imgUlStyle]">
            <li
              class="img_item img_item_true"
              v-for="(item, index) in previewDetail.imgList"
              :key="index"
            >
              <div class="img_hover">
                <span
                  class="iconfont icon-yulan1 icon_preview"
                  title="预览"
                  @click="imgItemSeeClick(item)"
                ></span>
              </div>
              <img :src="item" alt="" />
              <span class="img_mask"></span>
            </li>
          </ul>
        </div>
        <div class="no_data" v-show="previewDetail.imgList.length == 0">
          <img
            class="no_data_img"
            :src="require('@/assets/image/chat/no_data_1.jpg')"
            alt=""
          />
          <span class="txt">暂无试卷</span>
        </div>
        <div class="c_bottom">
          <span class="btn" @click="step = 1">关闭</span>
        </div>
      </div>
    </div>
    <div class="dialog dialog_transpond" v-show="step == 3">
      <div class="title">选择转发的老师</div>
      <span class="iconfont icon-error icon_close" @click="step = 1"></span>
      <div class="dialog_content teacher_select_area">
        <div
          class="teacher_select_item"
          v-for="item in teacherList"
          :key="item.id"
          :class="{ active: item.teaChatId == selectTeacherChatId }"
          @click="teacherItemClick(item)"
        >
          <img class="teacher_photo" :src="item.photo" alt="" />
          <span class="teacher_name">{{ item.name }}</span>
          <span class="teacher_select_btn"
            ><el-radio v-model="selectTeacherChatId" :label="item.teaChatId"
              >选择</el-radio
            ></span
          >
        </div>
      </div>
      <div class="transpond_bottom">
        <span class="btn" @click="confirmTranspond">确定</span>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getNewCourseNeedsById,
  concernRelationship,
} from "@/api/chat";
import { mapMutations, mapState, mapActions } from "vuex";
import * as dayjs from "dayjs";
export default {
  data() {
    return {
      step: 1, // 1,显示list   2,显示学生个人的信息   3,个人信息中单个试卷的预览
      subjectList: [],
      subjectId: null,
      //subjectName: null,
      userInfo: {
        name: null,
        birthday: null,
        constellationName: null,
        sex: null,
        sectionName: null,
      },
      scoreList: [],
      previewDetail: {
        imgList: [],
      },
      maxStep: 0,
      curStep: 0,
      imgUrlWidth: "",
      imgListWidth: 460,
      imgUlStyle: {
        left: "0px",
      },
      requireObj: {
        sexNeedName: "暂未填写",
        speedNeedName: "暂未填写",
        ageNeedName: "暂未填写",
        styleNeedName: "暂未填写",
      },
      subjectName: null,
      teacherList: [],
      selectTeacherChatId: null,
    };
  },
  watch: {
    "dialogNewClassSeeInfo.show"(show) {
      if (show) {
        this.step = 1;
        this.getNewCourseNeedsById();
      }
    },
  },
  components: {},
  computed: {
    ...mapState("dialogCont", ["dialogNewClassSeeInfo"]),
  },
  inject: ["timChat"],
  mounted() {},
  methods: {
    ...mapMutations("dialogCont", ["dialogNewClassSeeChange"]),
    ...mapActions("chatInfo", ["checkoutConversation"]),
    ...mapMutations("chatInfo", ["showWinChange"]),
    imgItemSeeClick(item) {},
    formatBirthday(birthday) {
      if (birthday) {
        return dayjs(birthday).format("YYYY-MM-DD");
      }
      return "暂无";
    },
    closeDialogClick() {
      this.dialogNewClassSeeChange({
        show: false,
      });
    },
    getNewCourseNeedsById() {
      this.requireObj = {
        sexNeedName: "暂未填写",
        speedNeedName: "暂未填写",
        ageNeedName: "暂未填写",
        styleNeedName: "暂未填写",
      };
      getNewCourseNeedsById({ id: this.dialogNewClassSeeInfo.id }).then(
        (res) => {
          console.log(res);
          const { iXmtStudentEntity, xmtStudentSubjectRelation } = res.rows;
          this.userInfo = iXmtStudentEntity;
          if (xmtStudentSubjectRelation) {
            this.subjectId = xmtStudentSubjectRelation.subjectId;
            const { xmtStudentAcademicRecordList } = xmtStudentSubjectRelation;
            this.scoreList = xmtStudentAcademicRecordList;
          }
          const { sexNeedId, speedNeedName, ageNeedName, styleNeedName } =
            xmtStudentSubjectRelation;
          if (sexNeedId) {
            this.requireObj.sexNeedName = sexNeedId == 1 ? "男" : "女";
          }
          if (speedNeedName) {
            this.requireObj.speedNeedName = speedNeedName;
          }
          if (ageNeedName) {
            this.requireObj.ageNeedName = ageNeedName;
          }
          if (styleNeedName) {
            this.requireObj.styleNeedName = styleNeedName;
          }
          this.subjectName = xmtStudentSubjectRelation.subjectName;
        }
      );
    },
    getScoreType(examinationType) {
      switch (Number(examinationType)) {
        case 1:
          return "模拟考试";
        case 2:
          return "期中";
        case 3:
          return "期末";
        case 4:
          return "月考";
        case 5:
          return "随堂练";
        default:
          return "暂无";
      }
    },
    transpondClick() {
      this.step = 3;
      this.selectTeacherChatId = null;
      concernRelationship({
        concernType: null,
      }).then((res) => {
        if (res.code == 0) {
          this.teacherList = res.rows;
        }
      });
    },
    teacherItemClick(item) {
      this.selectTeacherChatId = item.teaChatId;
    },
    confirmTranspond() {
      if (!this.selectTeacherChatId) {
        this.msgWarn("请选择将要转发的老师！");
        return;
      }
      let id = this.dialogNewClassSeeInfo.id
      this.dialogNewClassSeeChange({
        show: false,
      });
      setTimeout(() => {
        this.$bus.$emit("new-class-need", {
          id,
          subjectName: this.subjectName,
          studentName: this.userInfo.name,
        });
      }, 800);
      const conversationID = `C2C${this.selectTeacherChatId}`;
      this.showWinChange("mainChat");
      this.checkoutConversation(conversationID);
    },
    previewClick(item) {
      this.step = 2;
      item.imgList = item.testPapers ? item.testPapers.split(",") : [];
      this.previewDetail = item;
      console.log(this.previewDetail);
      this.$nextTick(() => {
        this.imgUrlWidth = this.$refs.imgUl.getBoundingClientRect().width;
        console.log(this.imgUrlWidth);
        if (this.imgUrlWidth > this.imgListWidth) {
          this.maxStep = Math.ceil(
            (this.imgUrlWidth - this.imgListWidth) / 120
          );
        }
      });
    },
    moveImgListClick(type) {
      if (this.curStep != 0 && type == "prev") {
        this.curStep--;
      } else if (this.curStep != this.maxStep && type == "next") {
        this.curStep++;
      }
      this.imgUlStyle.left = `-${this.curStep * 140}px`;
    },
  },
};
</script>
<style lang='scss' scoped>
.dialog_mask {
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  position: fixed;
  background-color: rgba($color: #000000, $alpha: 0.5);
  z-index: $max-index - 1000;
  font-size: 14px;
  .no_data {
    @include no_data;
  }
  .dialog {
    width: 660px;
    padding: 16px 20px 20px;
    min-height: 500px;
    max-height: 80%;
    border: 1px solid #e0e0e0;
    overflow-y: auto;
    position: absolute;
    background-color: #ffffff;
    border-radius: 6px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    z-index: 20;
    .title {
      line-height: 30px;
      font-size: 16px;
      font-weight: bolder;
      text-align: center;
    }
    .icon_close {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
    .teacher_select_area {
      max-height: 400px;
      overflow-y: auto;
      .teacher_select_item {
        display: flex;
        padding: 10px;
        align-items: center;
        border-bottom: 1px solid #eeeeee;
        &:hover {
          background-color: #dddddd;
        }
        &.active {
          background-color: #dddddd;
        }
        .teacher_photo {
          width: 30px;
          height: 30px;
          border-radius: 6px;
          margin: 0 20px;
        }
        .teacher_name {
          flex: 1;
          @include ellipsis;
        }
        .teacher_select_btn {
          width: 80px;
          text-align: center;
        }
      }
    }
    .transpond_bottom {
      height: 60px;
      text-align: center;
      line-height: 60px;
      .btn {
        background-color: #024df8;
        color: #ffffff;
        padding: 8px 16px;
        cursor: pointer;
        border-radius: 6px;
      }
    }
    .dialog_content {
      flex: 1;
      overflow-y: auto;
      color: #222222;
      .part {
        .require_item {
          line-height: 30px;
          text-indent: 20px;
          .require_a {
            margin-left: 10px;
          }
        }
        .sub_title {
          height: 40px;
          line-height: 40px;
          font-weight: bolder;
          .subject_name {
            font-weight: 500;
          }
        }
        .student_info {
          line-height: 40px;
          .info_item {
          }
          .label {
            color: #aaaaaa;
            word-break: keep-all;
          }
          .value {
            margin-left: 2px;
            margin-right: 10px;
            word-break: keep-all;
          }
        }
        .score_list {
          .item_row {
            height: 40px;
            border-bottom: 1px dashed #dddddd;
            align-items: center;
            justify-content: center;
            display: flex;
            > div {
              flex: 1;
              text-align: center;
              width: 0;
              @include ellipsis;
              padding: 0 2px;
            }
            .opt {
              .btn {
                cursor: pointer;
                color: #024df8;
              }
            }
          }
        }
      }
    }
    .bottom {
      height: 60px;
      text-align: center;
      line-height: 60px;
      .btn {
        background-color: #024df8;
        color: #ffffff;
        padding: 8px 16px;
        cursor: pointer;
        border-radius: 6px;
      }
    }
  }
  .dialog_preview {
    position: relative;
    .title {
      line-height: 30px;
      font-size: 16px;
      font-weight: bolder;
      text-align: center;
    }
    .go_back {
      position: absolute;
      left: 10px;
      top: 10px;
      cursor: pointer;
      font-size: 14px;
    }
    .preview_content {
      padding: 20px;
      .c_row {
        margin-top: 20px;
        display: flex;
        padding: 4px 0;
        .c_label {
          line-height: 20px;
          color: #666666;
          margin-right: 10px;
          width: 76px;
        }
        .c_value {
          flex: 1;
          line-height: 20px;
          color: #222222;
        }
      }
    }
    .c_title {
      margin-top: 20px;
      line-height: 20px;
      color: #666666;
    }
    .img_list {
      margin-top: 20px;
      width: 100%;
      height: 120px;
      position: relative;
      overflow: hidden;
      .img_btn {
        width: 30px;
        height: 30px;
        border-radius: 30px;
        background-color: #ffffff;
        border: 1px solid #dddddd;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
        line-height: 30px;
        cursor: pointer;
        z-index: 10;
        .iconfont {
          color: #aaaaaa;
        }
      }
      .img_btn_able {
        &:hover {
          .iconfont {
            color: #024df8;
          }
        }
      }
      .img_btn_disable {
        cursor: default;
        background-color: #dddddd;
      }
      .prev_img {
        left: 0px;
      }
      .next_img {
        right: 0px;
      }
      .img_ul {
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        .img_item {
          position: relative;
          margin: 0 10px;
          cursor: pointer;
          height: 120px;
          width: 120px;
          border: 1px solid #dddddd;
          display: flex;
          justify-content: center;
          align-items: center;
          overflow: hidden;
          &:nth-of-type(1) {
            margin-left: 0;
          }
          &:nth-last-of-type(1) {
            margin-right: 0;
          }
          .img_hover {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: none;
            z-index: 6;
            align-items: center;
            justify-content: center;
            background-color: rgba($color: #000000, $alpha: 0.7);
            .icon_del {
              margin-left: 10px;
              cursor: pointer;
              color: #ffffff;
            }
            .icon_preview {
              cursor: pointer;
              color: #ffffff;
              font-size: 14px;
            }
          }
          &:hover {
            .img_hover {
              display: flex;
            }
            .img_mask {
              display: none;
            }
          }
          img {
            width: 120px;
            height: 120px;
          }
          .img_mask {
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 2;
            background-color: rgba($color: #000000, $alpha: 0.3);
          }
          .icon_new {
            font-size: 40px;
          }
        }
      }
    }
    .c_bottom {
      margin-top: 20px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      .btn {
        padding: 8px 18px;
        cursor: pointer;
        background-color: #024df8;
        border-radius: 6px;
        color: #ffffff;
      }
    }
  }
}
</style>