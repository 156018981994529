<template>
<message-bubble :isMine="isMine" :message="message">
  <div class="sound-element-wrapper" :title="playing ? '单击暂停' : '单击播放'" @click.stop="handleSoundClick">
    <img v-show="!isMine && !playing" class="sound-img" :src="require('@/assets/image/chat/sound_left_d.png')" alt="">
    <img v-show="!isMine && playing" class="sound-img" :src="require('@/assets/image/chat/sound_left_a.png')" alt="">
    <span v-show="isMine && playing">播放中</span>
    <i class="iconfont icon-voice"></i>
    {{ second + '"' }}
    <span v-show="!isMine && playing">播放中</span>
    <img v-show="isMine && !playing" class="sound-img" :src="require('@/assets/image/chat/sound_right_d.png')" alt="">
    <img v-show="isMine && playing" class="sound-img" :src="require('@/assets/image/chat/sound_right_d.png')" alt="">
    <audio :src="url" ref="audioEle" class="audio" @pause="handlePause" @play="handlePlay"></audio>
  </div>
</message-bubble>
</template>

<script>
import MessageBubble from '../message-bubble'
export default {
  name: 'SoundElement',
  props: {
    payload: {
      type: Object,
      required: true
    },
    message: {
      type: Object,
      required: true
    },
    isMine: {
      type: Boolean
    }
  },
  components: {
    MessageBubble
  },
  data() {
    return {
      playing: false,
    }
  },
  computed: {
    url() {
      return this.payload.url
    },
    size() {
      return this.payload.size
    },
    second() {
      return this.payload.second
    }
  },
  methods: {
    handlePause(){
      console.log('handlePause')
      this.playing = false
    },
    handlePlay() {
      console.log('handlePlay')
      this.playing = true
    },
    handleSoundClick(){
      if(this.playing){
        this.$refs.audioEle.pause()
      }else{
        this.$refs.audioEle.play()
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.sound-element-wrapper {
  padding: 0px 10px;
  cursor: pointer;
}
.sound-img {
  width: 16px;
}
.audio {
  display: none;
}
</style>
