<template>
  <message-bubble :isMine="isMine" :message="message">
    <template v-slot:default="slotProps">
      <video
        :src="payload.videoUrl"
        controls
        class="video"
        controlslist="nodownload"
        disablePictureInPicture
        @error="videoError"
      ></video>
      <el-progress
        v-if="showProgressBar"
        :percentage="percentage"
        :color="(percentage) => (percentage === 100 ? '#67c23a' : '#409eff')"
      />
      <el-progress
        v-if="slotProps.downData.status == 1"
        :percentage="slotProps.downData.process"
        :color="(percentage) => (percentage === 100 ? '#67c23a' : '#409eff')"
      />
    </template>
  </message-bubble>
</template>

<script>
import MessageBubble from "../message-bubble";
import { Progress } from "element-ui";
export default {
  name: "VideoElement",
  data() {
    return {
    };
  },
  components: {
    MessageBubble,
    ElProgress: Progress,
  },
  props: {
    payload: {
      type: Object,
      required: true,
    },
    message: {
      type: Object,
      required: true,
    },
    isMine: {
      type: Boolean,
    },
  },
  computed: {
    showProgressBar() {
      return this.message.status === "unSend";
    },
    percentage() {
      return Math.floor((this.$parent.message.progress || 0) * 100);
    },
  },
  methods: {
    videoError(e) {
      this.msgError(`视频出错，错误原因：${e.target.error.message}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.video {
  outline: none;
  width: 100%;
  max-height: 300px;
}
</style>
